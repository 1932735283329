import ImageItem from '@components/common/ImageItem';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const Photo: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" color="primary">
          Meta info
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle2" color="#686868">
          Photo
        </Typography>
      </Grid>
      <Grid item width="100%">
        <ImageItem {...fieldRequiredProps(form, validationSchema, 'photo')} label={'Photo'} withUnlink={false} />
      </Grid>
    </>
  );
};

export default Photo;
