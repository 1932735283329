import EditTimerSourceModal from '@components/modals/EditTimerSourceModal';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import HistoryProperty from '@components/side-card/history/HistoryProperty';
import useMoreMenu from '@components/useMoreMenu';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { setSettings } from '@store/settingsSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import tabStyle from '@components/side-card/tab.module.css';

const TimerGeneralTab = (props) => {
  const { item } = props;
  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const dispatch = useDispatch();

  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);

  const hasAlarms = (alerts = getPropertyByKey('alarmsAlerts').value) => {
    return alerts.some((item) => {
      if (Object.values(item.condition).length) {
        if (item.condition.value && item.condition.operator) {
          return true;
        }
      }
      return false;
    });
  };

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const valueProperty = () => getPropertyByKey('valueValue');

  return (
    <>
      {isShowHistoryProperty && <HistoryProperty id={idProperty} object={props.item} />}
      <List>
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditTimerSourceModal({
                  object: obj,
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
              disabled: false,
            },
          ]}
        />
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>{hasAlarms() && <NotificationsIcon />}</ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{valueProperty().value}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openColumnMenu(e, {
                  ...props.item,
                  item: item,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TimerGeneralTab;
