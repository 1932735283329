import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const Name: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item>
    <CustomInput {...fieldRequiredProps(form, validationSchema, 'name')} label="Name" clearFieldIcon={true} />
  </Grid>
);

export default Name;
