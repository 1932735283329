import { gql } from '@src/__generated__';

export const SUBSCRIBE_MONITOR_ITEMS = gql(/* GraphQL */ `
    subscription listenMonitoringItemsList($objectId: UUID!, $tags: [String]) {
        Objects(filterA: { parentObjects: [$objectId], tags: $tags }) {
            event
            relatedNodeId
            relatedNode {
                __typename
                ... on ObjectsToObject {
                    id
                    object2 {
                        id
                        name
                        hardLinked
                        objectProperties {
                            id
                            key
                            type
                            typeId
                            linkedPropertyId
                            updatedAt
                            userByBy {
                                login
                                mName
                            }
                            groupName
                            property
                            value
                        }
                    }
                }
                ... on ObjectProperty {
                    objectId
                    id
                    key
                    type
                    typeId
                    linkedPropertyId
                    updatedAt
                    userByBy {
                        login
                        mName
                    }
                    groupName
                    property
                    value
                }
            }
        }
    }
`);