import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ObjectProperty } from '@src/__generated__/graphql';

import { containerClasses, ITabGeneralObjectStatisticProps } from '../../index';
import st from '../../style.module.css';

interface IProps {
  onChange: (id: string, value: string) => Promise<void>;
  item: ITabGeneralObjectStatisticProps['item'];
  settingsLinkedOnly: ObjectProperty;
}

const LinkingItem = ({ onChange, settingsLinkedOnly, item }: IProps) => {
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const handleCopy = useHandleCopy();

  return (
    <>
      <MoreMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              EditDynamicProperty({
                handleSave: onChange,
                property: settingsLinkedOnly,
                id: obj.propId,
                value: obj.propValue,
              }).catch(() => {});
            },
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: JSON.stringify(obj.propValue),
                message: 'Value copied',
              });
            },
          },
        ]}
      />
      <ListItem classes={containerClasses} className={st.listItem}>
        <ListItemIcon>
          <TuneIcon />
        </ListItemIcon>
        <ListItemText
          primary={
            <Typography variant="body1">Linking: {settingsLinkedOnly?.value ? 'Linked only' : 'All'}</Typography>
          }
        />
        <ListItemSecondaryAction className={st.itemToHideOrShow}>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              openMoreMenu(e, {
                ...item,
                propKey: settingsLinkedOnly?.key,
                propValue: settingsLinkedOnly?.value,
                propId: settingsLinkedOnly?.id,
              });
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default LinkingItem;
