import ConditionModal from '@components/modals/ConditionModal';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ListItemButton, ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { GetSchemaQuery, ObjectProperty } from '@src/__generated__/graphql';
import cloneDeep from 'lodash.clonedeep';

import { containerClasses } from '../../index';
import st from '../../style.module.css';

interface IProps {
  disabled: boolean;
  onChange: (id: string, value: any) => Promise<void>;
  settingsFilter: ObjectProperty;
  schemaProperties?: GetSchemaQuery['schema']['schemaProperties'];
}

const ConditionsItem = ({ onChange, settingsFilter, disabled, schemaProperties = [] }: IProps) => {
  const getSchemaProperties = () => {
    return (
      schemaProperties?.map((prop) => ({
        value: `${prop?.groupName}/${prop.property}`,
        title: `${prop?.groupName}/${prop?.description || prop.property}`,
        ...prop,
      })) ?? []
    );
  };

  const handleDelete = (conditionIndex: number) => {
    const oldValues = cloneDeep(settingsFilter?.value.conditions);

    oldValues.splice(conditionIndex, 1);

    const patch = {
      ...settingsFilter.value,
      filtering: Boolean(oldValues.length),
      conditions: [...oldValues],
    };

    void onChange(settingsFilter.id as string, patch).then(() => {});
  };

  return (
    <>
      {settingsFilter?.value?.conditions?.map(
        (condition: { property: string; operator: string; value: string }, index: number) => {
          return (
            <ListItem disabled={disabled} classes={containerClasses} className={st.listItemWithTools} key={index}>
              <ListItemIcon />
              <ListItemText
                primary={
                  <Typography variant="body1">{`${condition.property} ${condition.operator} ${condition.value}`}</Typography>
                }
              />
              <ListItemSecondaryAction className={st.itemToHideOrShow}>
                <IconButton
                  size="small"
                  disabled={disabled}
                  onClick={() => {
                    ConditionModal({
                      condition,
                      isEdit: true,
                      conditionIndex: index,
                      conditionProperty: settingsFilter,
                      properties: getSchemaProperties(),
                      schemaRawProperties: schemaProperties,
                    }).catch(() => {});
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  disabled={disabled}
                  size="small"
                  onClick={() => {
                    handleDelete(index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        }
      )}
      <ListItemButton
        className={st.listItem}
        disabled={disabled}
        onClick={() => {
          ConditionModal({
            isEdit: false,
            conditionProperty: settingsFilter,
            properties: getSchemaProperties(),
            schemaRawProperties: schemaProperties,
          }).catch(() => {});
        }}
      >
        <ListItemIcon>
          <AddIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">Add condition</Typography>} />
      </ListItemButton>
    </>
  );
};

export default ConditionsItem;
