import CommonModal from '@components/CommonModal';
import Subtitle from '@components/Subtitle';
import { msg } from '@constants/messages';
import Grid from '@mui/material/Grid';
import { create } from 'react-modal-promise';

import { FastField } from 'formik';
import Language from './components/Language';
import Logo from './components/Logo';
import Title from './components/Title';
import useForm from './hooks/useForm';

interface IProps {
  onResolve: () => void;
  isOpen: boolean;
}

const SettingsModal = ({ isOpen, onResolve }: IProps) => {
  const { formik, isLoading } = useForm({ onResolve });

  return (
    <>
      <CommonModal
        isForm={true}
        loading={isLoading}
        formik={formik}
        modalOpen={isOpen}
        title={msg.settingsModal.settings}
        handleClose={onResolve}
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Subtitle>{msg.settingsModal.general}</Subtitle>
          </Grid>
          <FastField name="lang" placeholder="Language" component={Language} />
          <FastField name="programAppTitle" placeholder="title" component={Title} />
          <FastField name="logo" placeholder="logo" component={Logo} />
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(SettingsModal);
