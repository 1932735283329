import LabelIcon from '@components/icons/labelIcon';
import st from '@components/side-card/advanced-button/style.module.css';
import ExtensionIcon from '@mui/icons-material/Extension';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type IObjectListItemProps = {
  dataObject: any;
  openColumnMenu: any;
  item: any;
  loading: boolean;
};

const ObjectListItem = ({ dataObject, openColumnMenu, item, loading }: IObjectListItemProps) => {
  return (
    <ListItem
      classes={{
        container: st.listItemWrapper,
      }}
    >
      <ListItemIcon>
        <ExtensionIcon>
          <LabelIcon></LabelIcon>
        </ExtensionIcon>
      </ListItemIcon>
      <ListItemText
        primary={
          <Typography variant="body1">Object: {!loading ? dataObject?.object?.name || 'n/a' : 'Loading...'}</Typography>
        }
      />
      <ListItemSecondaryAction className={st.itemToHideOrShow}>
        <IconButton
          edge="end"
          aria-label="more"
          onClick={(e) => {
            openColumnMenu(e, {
              ...item,
              item,
            });
          }}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ObjectListItem;
