import CloudOffIcon from '@mui/icons-material/CloudOff';
import CloudQueueIcon from '@mui/icons-material/CloudQueue';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';

import { EActivityStatuses } from '../../constants';
import st from './style.module.css';

const activityIcons = {
  [EActivityStatuses.NO_RESPONSE]: <CloudOffIcon fontSize="inherit" />,
  [EActivityStatuses.UNKNOWN]: null,
  [EActivityStatuses.ACTIVE]: <CloudQueueIcon fontSize="inherit" />,
};

interface IProps {
  statusesActivity?: ObjectProperty;
}

const ActivityStatus = ({ statusesActivity }: IProps) => {
  const theme = useTheme();
  const status = statusesActivity.value || statusesActivity;

  if (!activityIcons[status]) return null;

  return (
    <span className={st.icon} style={{ color: status === EActivityStatuses.NO_RESPONSE ? theme.palette.red : theme.palette.gray1 }}>
      {activityIcons[status]}
    </span>
  );
};

export default ActivityStatus;
