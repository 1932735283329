export enum EBatteryStatuses {
  FULL = 'FULL',
  LOW = 'LOW',
  DC = 'DC',
  UNKNOWN = 'UNKNOWN',
}

export enum EActivityStatuses {
  ACTIVE = 'ACTIVE',
  NO_RESPONSE = 'NO_RESPONSE',
  UNKNOWN = 'UNKNOWN',
}

export enum EAlarmStatuses {
  OFF = 'OFF',
  ON = 'ON',
  TRIGGERED = 'TRIGGERED',
}

export enum EDataStatuses {
  SET = 'SET',
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  ERROR = 'ERROR',
}

export enum EGpsStatuses {
  FIXED = 'FIXED',
  NOT_FIXED = 'NOT_FIXED',
  OFF = 'OFF',
}
