import { highlightSelectedStyle } from '@constants/constants';
import { Box } from '@mui/material';
import useMedia from '@utils/useMedia';
import { useSelector } from 'react-redux';
import WidgetEditControls from '../../WidgetEditControls';
import st from './style.module.css';

interface ObjectProperty {
  key: string;
  value: string | number | null;
}

interface WLineProps {
  objectProperties: ObjectProperty[];
  selected: boolean;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const W_Line = (props: WLineProps) => {
  const { objectProperties, selected, ...widgetEditControlsProps } = props;

  const getPropValue = (prop: string): string | number | null =>
    objectProperties.find((obj) => obj.key === prop)?.value;

  const { getImageById } = useMedia();
  const color = getPropValue('settingsColor') as string | undefined;
  const leftEnd = getPropValue('settingsLeftEnd') as string | undefined;
  const rightEnd = getPropValue('settingsRightEnd') as string | undefined;
  const orientation = getPropValue('settingsOrientation') as string | undefined;
  const lineWidth = getPropValue('settingsLineWidth') as number | undefined;
  const imageUid = getPropValue('settingsCoverimage') as string | undefined;

  const isEditMode = useSelector((state: any) => state.settings.isEditMode);

  const lineStyle = {
    height: `${lineWidth === 1 ? '1px' : lineWidth === 2 ? '2px' : lineWidth === 4 ? '4px' : '6px'}`,
    left: `${orientation === 'horizontally' ? '50%' : '15px'}`,
    backgroundColor: color || 'transparent',
    transform: `${orientation === 'horizontally' ? 'translate(-50%, -50%)' : 'rotate(90deg)'}`,
  };

  return (
    <div
      className={st.wrapper}
      style={{
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
      }}
    >
      <Box
        className={st.arrowWrapper}
        sx={{
          ...lineStyle,
          '&:before': {
            display: rightEnd === 'arrow' ? 'block' : 'none',
            top: `${lineWidth === 1 || lineWidth === 4 ? '-2px' : '-1px'}`,
            right: `${lineWidth === 1 || lineWidth === 2 ? '7px' : '8px'}`,
            width: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            height: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            borderTop: `${lineWidth || 1}px solid ${color || 'transparent'}`,
            borderRight: `${lineWidth || 1}px solid ${color || 'transparent'}`,
          },
          '&:after': {
            display: leftEnd === 'arrow' ? 'block' : 'none',
            top: `${lineWidth === 1 || lineWidth === 4 ? '-2px' : '-1px'}`,
            left: `${lineWidth === 1 ? '-3px' : lineWidth === 2 ? '-5px' : '-8px'}`,
            width: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            height: `${lineWidth === 1 || lineWidth === 2 ? '15px' : '24px'}`,
            borderTop: `${lineWidth || 1}px solid ${color || 'transparent'}`,
            borderRight: `${lineWidth || 1}px solid ${color || 'transparent'}`,
          },
        }}
      />
      {imageUid && <img className={st.backgroundImage} src={getImageById(imageUid)} alt="icon" />}

      <WidgetEditControls {...widgetEditControlsProps} />
    </div>
  );
};

export default W_Line;
