import { msg } from '@constants/messages';
import * as yup from 'yup';

export const LANGUAGE_LIST = [
  {
    title: msg.settingsModal.langEnglish,
    value: 'en',
    id: 'en',
  },
  {
    title: msg.settingsModal.langRussian,
    value: 'ru',
    id: 'ru',
  },
];

export interface IFormValues {
  programAppTitle: string;
  lang: string;
  logo: string | Blob | null;
}

export const validationSchema = yup.object({
  programAppTitle: yup.string().trim(),
  lang: yup.string().trim(),
});
