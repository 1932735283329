export const castToType = (value, type) => {
  switch (type) {
    case 'number':
      return Number(value);
    case 'bool':
      // convert 'null' to null as a vice versa of checkNullToString
      if (value === 'null') {
        return null;
      }
      return Boolean(value);
    default:
      if (value === null) {
        return null;
      } else {
        return String(value);
      }
  }
};

/**
 * checkNullToString: @mui doesn't work with null. So convert null to 'null'
 * @param value
 * @param type
 * @returns {*|string}
 */
export const checkNullToString = (value, type) => {
  if (type === 'bool') {
    if (value === null || value === '') {
      return 'null';
    }
  }
  return value;
};

export const formatValue = (value) => {
  if (value === null) return 'n/a';

  if (typeof value === 'object') return JSON.stringify(value);

  return value;
};
