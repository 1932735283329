import { gql } from '../__generated__';

export const DASHBOARDS_QUERY = gql(/* GraphQL */ `
  query getDashboards {
    dashboards: objects(
      filter: { schemaTags: { contains: ["application", "board", "dashboard"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);
