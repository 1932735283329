import { DEFAULT_DATACHART_COLOR_VALUES, validationSchema } from '@components/create-widget/DataChartColor/constants';
import { useAddWidget } from '@components/create-widget/useAddWidget';
import { ObjectProperty } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { WIDGETS_ENUM } from '@utils/widgetTypes';
import { useMemo } from 'react';

const useForm = ({ submit, name, group, isEdit, widget }) => {
  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group,
    widgetType: WIDGETS_ENUM.DATACHART_COLOR,
    cb: submit,
  });

  const objectPropertiesMap = useMemo(
    () => getPropertiesMap(widget?.objectProperties as ObjectProperty[]),
    [widget?.objectProperties]
  );

  const getPrevValues = () => {
    const defaultValues = {
      ...DEFAULT_DATACHART_COLOR_VALUES,
    };
    if (widget?.objectProperties) {
      for (const property in DEFAULT_DATACHART_COLOR_VALUES) {
        defaultValues[property] = objectPropertiesMap[property]?.value;
      }
    }
    return defaultValues;
  };

  const formik = {
    initialValues: {
      description: isEdit ? widget.description : '',
      name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name: nameLocal, description }) => {
      if (isEdit) {
        updateWidget({
          values: properties,
          defaultValues: { ...getPrevValues() },
          id: widget.id,
          name: nameLocal,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name: nameLocal,
          description,
          cb: submit,
        });
      }
    },
  };
  return { formik, isLoading };
};

export default useForm;
