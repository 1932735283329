import { useQuery } from '@apollo/client';
import CustomSelect from '@components/CustomSelect';
import { GET_DEVICES_LINKED } from '@modules/devices/api/getDevicesLinked';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC, useMemo } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const defaultErrorText = {
  error: true,
  helperText: 'Failed to load devices',
};

const Name: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { data, loading, error } = useQuery(GET_DEVICES_LINKED, {
    variables: { widgetId: form?.values?.widgetId },
    fetchPolicy: 'network-only',
  });
  const devicesList = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      data?.objects?.map((item) => {
        return { value: item.id, title: item.name };
      }),
    [data]
  );

  const isLogicError =
    devicesList?.length === 0 ? { helperText: 'First create an object', disabled: true, error: true } : {};

  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'name')}
        {...(error ? defaultErrorText : null)}
        label="Name"
        list={devicesList}
        {...isLogicError}
        loading={loading}
      />
    </Grid>
  );
};

export default Name;
