import { phoneRegExp } from '@constants/regExps';
import * as yup from 'yup';

export interface IFormValues {
  name: string;
  email: string;
  phone: string;
  secondID: string;
  editorsGroup: string;
  usersGroup: string;
  readersGroup: string;
  photo: string | Blob | null;
  icon: string | Blob | null;
  description: string;
}

export const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
  email: yup.string().trim().email('Enter a valid email'),
  phone: yup.string().matches(phoneRegExp, 'Phone number is not valid'),
});
