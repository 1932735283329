import { createSvgIcon } from '@mui/material';

const fIcon = createSvgIcon(
  <path
    d="M15.6 5.29005C14.5 5.19005 13.53 6.00005 13.43 7.11005L13.18 10.0001H16V12.0001H13L12.56 17.0701C12.37 19.2701 10.43 20.9001 8.23004 20.7001C6.92004 20.5901 5.82004 19.8601 5.17004 18.8301L6.67004 17.3301C6.91004 18.0701 7.57004 18.6401 8.40004 18.7101C9.50004 18.8101 10.47 18.0001 10.57 16.8901L11 12.0001H8.00004V10.0001H11.17L11.44 6.93005C11.63 4.73005 13.57 3.10005 15.77 3.30005C17.08 3.41005 18.18 4.14005 18.83 5.17005L17.33 6.67005C17.09 5.93005 16.43 5.36005 15.6 5.29005Z"
    fill="currentColor"
  />,
  'fIcon'
);

export default fIcon;
