import { Object as ObjectType } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { useMemo } from 'react';

import ActivityStatus from './components/ActivityStatus';
import AlarmStatus from './components/AlarmStatus';
import BatteryStatus from './components/BatteryStatus';
import DataStatus from './components/DataStatus';
import EmulationStatus from './components/EmulationStatus';
import GpsStatus from './components/GpsStatus';
import st from './style.module.css';

interface IProps {
  item: ObjectType;
}

const HeaderStatuses = ({ item }: IProps) => {
  const objectPropertiesMap = useMemo(() => getPropertiesMap(item.objectProperties), [item.objectProperties]);

  return (
    <span className={st.container}>
      <BatteryStatus
        statusesBatteryType={objectPropertiesMap?.statusesBatteryType}
        statusesBatteryLevel={objectPropertiesMap?.statusesBatteryLevel}
      />
      <ActivityStatus statusesActivity={objectPropertiesMap?.statusesActivity} />
      <AlarmStatus statusesAlarm={objectPropertiesMap?.statusesAlarm} />
      <DataStatus statusesData={objectPropertiesMap?.statusesData} />
      <EmulationStatus statusesEmulation={objectPropertiesMap?.statusesEmulation} />
      <GpsStatus statusesGps={objectPropertiesMap?.statusesGps} />
    </span>
  );
};

export default HeaderStatuses;
