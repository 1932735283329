import { CommonFieldProps, IFormValues, validationSchema } from '@components/create-widget/DataChartColor/constants';
import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

const InputField = ({
  form,
  field,
  label,
  clearFieldIcon,
  type,
  multiline,
}: FieldProps<IFormValues> & CommonFieldProps) => {
  return (
    <Grid item>
      <CustomInput
        {...fieldRequiredProps(form, validationSchema, field.name)}
        label={label}
        type={type}
        multiline={multiline}
        clearFieldIcon={clearFieldIcon}
      />
    </Grid>
  );
};

export default InputField;
