import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import { SETTINGS_STYLE_OPTIONS } from '@utils/constants/selectOptions';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const Style: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <>
      <Grid item>
        <CustomSelect
          {...fieldRequiredProps(form, validationSchema, 'style')}
          label="Style"
          list={SETTINGS_STYLE_OPTIONS}
        />
      </Grid>
    </>
  );
};

export default Style;
