import { useQuery } from '@apollo/client';
import { LinearProgress } from '@mui/material';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { lazy, Suspense } from 'react';

import { GET_MONITOR_OBJECT_WITHOUT_FRAGMENTS } from './api/GetMonitorObjectWithoutFragments';
import st from './style.module.css';

const TabContentObjects = lazy(() => {
  return import('@components/side-card/TabContentObjects');
});

export interface ITabGeneralObjectStatisticProps {
  item: ObjectType;
  refetch: () => void;
}

const MonitorObjectTabObjects = ({ item, refetch }: ITabGeneralObjectStatisticProps) => {
  const { data: monitorItem, loading } = useQuery(GET_MONITOR_OBJECT_WITHOUT_FRAGMENTS, {
    variables: {
      objectId: item.id,
    },
    fetchPolicy: 'cache-first',
  });

  return (
    <>
      {!monitorItem && loading && <LinearProgress className={st.progressBar} />}
      {monitorItem && !loading && (
        <Suspense fallback={<LinearProgress className={st.progressBar} />}>
          <TabContentObjects item={monitorItem.object} refetch={refetch} />
        </Suspense>
      )}
    </>
  );
};

export default MonitorObjectTabObjects;
