import { gql } from '../../../__generated__';

const UPDATE_USER = gql(/* GraphQL */ `
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      clientMutationId
    }
  }
`);

export { UPDATE_USER };
