import CloseIcon from '@mui/icons-material/Close';
import { FormControl } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/system';
import { castToType } from '@utils/misc.js';

import st from './style.module.css';

const CustomSelectColor = (props) => {
  const theme = useTheme();

  const { name, label, colors } = props;

  const handleInputChangeCasted = (event, type) => {
    let { name, value, checked } = event.target;
    const e = {
      target: { name: name, value: castToType(value, type), checked: checked },
    };
    props.onChange(e);
  };

  const colorsMap = {
    red: {
      color: theme.palette.wRed,
      title: 'red',
      sx: {
        backgroundColor: theme.palette.wRed,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wRed,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#E51010 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    green: {
      color: theme.palette.wGreen,
      title: 'green',
      sx: {
        backgroundColor: theme.palette.wGreen,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wGreen,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#3E8D42 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    orange: {
      color: theme.palette.wOrange,
      title: 'orange',
      sx: {
        backgroundColor: theme.palette.wOrange,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wOrange,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#FF8528 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    yellow: {
      color: theme.palette.wYellow,
      title: 'yellow',
      sx: {
        backgroundColor: theme.palette.wYellow,
        color: theme.palette.wBlack,
        '&:focus': {
          backgroundColor: theme.palette.wYellow,
          color: theme.palette.wBlack,
        },
        '&:hover': {
          backgroundColor: '#FFE610 !important',
          color: theme.palette.wBlack,
        },
      },
    },
    blue: {
      color: theme.palette.wBlue,
      title: 'blue',
      sx: {
        backgroundColor: theme.palette.wBlue,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wBlue,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#3790F3 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    black: {
      color: theme.palette.wBlack,
      title: 'black',
      sx: {
        backgroundColor: theme.palette.wBlack,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wBlack,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#333333 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    white: {
      color: theme.palette.wWhite,
      title: 'white',
      sx: {
        backgroundColor: theme.palette.wWhite,
        color: theme.palette.wBlack,
        '&:focus': {
          backgroundColor: theme.palette.wWhite,
          color: theme.palette.wBlack,
        },
        '&:hover': {
          backgroundColor: '#ffffff !important',
          color: theme.palette.wBlack,
        },
      },
    },
    transparent: {
      color: theme.palette.wTransparent,
      title: 'transparent',
      sx: {
        backgroundColor: theme.palette.wTransparent,
        color: theme.palette.wBlack,
        '&:focus': {
          backgroundColor: theme.palette.wTransparent,
          color: theme.palette.wBlack,
        },
        '&:hover': {
          backgroundColor: 'transparent !important',
          color: theme.palette.wBlack,
        },
      },
    },
    default: {
      color: theme.palette.wBlue,
      title: 'default',
      sx: {
        backgroundColor: theme.palette.wBlue,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.wBlue,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#3790F3 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    gray3: {
      color: theme.palette.gray3,
      title: 'gray',
      sx: {
        backgroundColor: theme.palette.gray3,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.gray3,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#555555 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    gray4: {
      color: theme.palette.gray4,
      title: 'grey',
      sx: {
        backgroundColor: theme.palette.gray4,
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: theme.palette.gray4,
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#616161 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    yellowGreen: {
      color: '#C0CA33',
      title: 'yellow green',
      sx: {
        backgroundColor: '#C0CA33',
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: '#C0CA33',
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#C0CA33 !important',
          color: theme.palette.wWhite,
        },
      },
    },
    deepRed: {
      color: '#920000',
      title: 'deep red',
      sx: {
        backgroundColor: '#920000',
        color: theme.palette.wWhite,
        '&:focus': {
          backgroundColor: '#920000',
          color: theme.palette.wWhite,
        },
        '&:hover': {
          backgroundColor: '#920000 !important',
          color: theme.palette.wWhite,
        },
      },
    },
  };

  return (
    <FormControl fullWidth variant={'standard'}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        name={name}
        id={`${name}-label`}
        value={props.value}
        labelId={`${name}-label`}
        data-test-select-color={props['data-test'] || props.name}
        disabled={Boolean(props.disabled)}
        onChange={(e) => {
          handleInputChangeCasted(e, props.propType);
        }}
        {...(props.clearFieldIcon && props.value ? { classes: { icon: st.icon, select: st.selectSelect } } : {})}
        endAdornment={
          props.clearFieldIcon &&
          props.value && (
            <InputAdornment position="end">
              <IconButton
                size="small"
                aria-label="reset"
                onClick={() => props.onChange({ target: { name: props.name, value: '' } })}
                edge="end"
              >
                <CloseIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        fullWidth
        style={{
          backgroundColor: colorsMap[props.value]?.color,
          color: props.value === 'yellow' ? theme.palette.wBlack : theme.palette.wWhite,
        }}
      >
        {colors.map((color) => {
          return (
            <MenuItem value={color} key={color} sx={colorsMap[color]?.sx}>
              {colorsMap[color]?.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default CustomSelectColor;
