import { gql, useMutation } from '@apollo/client';
import MapMarkerRadius from '@components/icons/mapMarkerRadius';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import useMoreMenu from '@components/useMoreMenu';
import { Label } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TuneIcon from '@mui/icons-material/Tune';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import cloneDeep from 'lodash.clonedeep';

import tabStyle from '@components/side-card/tab.module.css';
import FilterGeotagsModal from './FilterGeotagsModal';
import FilterGroupModal from './FilterGroupModal';
import MonitorStatusAddRowModal from './MonitorStatusAddRowModal';
import MonitorStatusColumnModal from './MonitorStatusColumnModal';

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const MonitorStatusTableGeneral = (props) => {
  const { item } = props;

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const columns = () => {
    return getPropertyByKey('sourceColumns').value.columns;
  };

  const rows = () => {
    return getPropertyByKey('sourceRows').value.rows;
  };

  const geotagName = () => {
    return getPropertyByKey('filtersGeotagName').value?.title || 'All geotags';
  };

  const groupName = () => {
    return getPropertyByKey('filtersGroupName').value?.title || 'All groups';
  };

  const linkedOnly = () => {
    return getPropertyByKey('filtersLinkedOnly');
  };

  const columnsProperty = () => {
    return item.objectProperties.find((item) => item.key === 'sourceColumns');
  };

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: RowMenu, openMoreMenu: openRowMenu } = useMoreMenu();

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const handleDelete = (conditionIndex) => {
    const conditionPropertyItem = getPropertyByKey('sourceColumns');
    const oldValues = cloneDeep(conditionPropertyItem.value.columns);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      columns: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  const handleDeleteRow = (conditionIndex) => {
    const conditionPropertyItem = getPropertyByKey('sourceRows');
    const oldValues = cloneDeep(conditionPropertyItem.value.rows);

    oldValues.splice(conditionIndex, 1);

    let patch = {
      ...conditionPropertyItem.value,
      rows: [...oldValues],
    };

    updateProperty({
      variables: {
        input: {
          id: conditionPropertyItem.id,
          patch: {
            value: patch,
          },
        },
      },
    }).then(() => {});
  };

  return (
    <div>
      <List>
        <RowMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                MonitorStatusAddRowModal({
                  isEdit: true,
                  conditionIndex: obj.propIndex,
                  conditionProperty: getPropertyByKey('sourceRows'),
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <DeleteIcon />,
              title: 'Delete',
              id: 'delete',
              handleAction: (obj) => {
                handleDeleteRow(obj.propIndex);
              },
              disabled: false,
            },
          ]}
        />
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                MonitorStatusColumnModal({
                  isEdit: true,
                  value: obj.item,
                  conditionProperty: getPropertyByKey('sourceColumns'),
                })
                  .then()
                  .catch(() => {});
              },
              disabled: false,
            },
            {
              icon: <DeleteIcon />,
              title: 'Delete',
              id: 'delete',
              handleAction: (obj) => {
                handleDelete(obj.propIndex);
              },
              disabled: false,
            },
          ]}
        />
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Filters</Typography>
        </ListSubheader>

        {/*Geotag name*/}
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <MapMarkerRadius />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Geotag: {geotagName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey('filtersGeotagName');
                FilterGeotagsModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {/*Group name */}
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <Label />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Group: {groupName()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getPropertyByKey('filtersGroupName');
                FilterGroupModal({
                  value: target.value,
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Linking: {linkedOnly().value ? 'Linked only' : 'All'}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                EditDynamicProperty({
                  handleSave: handleUpdateProperty,
                  property: linkedOnly(),
                  id: linkedOnly().id,
                  value: linkedOnly().value,
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Rows</Typography>
        </ListSubheader>
        {rows().map((item, index) => {
          return (
            <ListItem
              title={item.state?.title}
              classes={{
                container: tabStyle.itemToHover,
              }}
              style={{ height: '48px', paddingRight: '48px' }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap={true} variant="body1">
                    {item.type?.title}: {item.state?.title}
                  </Typography>
                }
                onClick={() => {}}
              />
              <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={(e) => {
                    openRowMenu(e, {
                      ...props.item,
                      item: item,
                      propIndex: index,
                    });
                  }}
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
        <ListItem
          style={{ height: '48px' }}
          button
          onClick={() => {
            MonitorStatusAddRowModal({
              isEdit: false,
              conditionProperty: getPropertyByKey('sourceRows'),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add row</Typography>} />
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Columns</Typography>
        </ListSubheader>
        {columns().map((item, index) => {
          return (
            <ListItem
              classes={{
                container: tabStyle.itemToHover,
              }}
              style={{ height: '48px', paddingRight: '96px' }}
              button
              onClick={() => {}}
              key={index}
            >
              <ListItemIcon></ListItemIcon>
              <ListItemText primary={<Typography variant="body1">{item.title}</Typography>} onClick={() => {}} />
              <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
                <IconButton
                  edge="end"
                  aria-label="more"
                  onClick={(e) => {
                    openColumnMenu(e, {
                      ...props.item,
                      item: item,
                      propIndex: index,
                    });
                  }}
                  size="large"
                >
                  <MoreVertIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}

        <ListItem
          style={{ height: '48px' }}
          button
          onClick={() => {
            MonitorStatusColumnModal({
              isEdit: false,
              conditionProperty: columnsProperty(),
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Add column</Typography>} />
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </div>
  );
};

export default MonitorStatusTableGeneral;
