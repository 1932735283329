import { ObjectProperty } from '@src/__generated__/graphql';

/** @internal
 * Group array of objects by a specific key.
 * @param arr
 * @param key
 */
const grouping = (arr: ObjectProperty[], key: string): Record<string, ObjectProperty> =>
  (arr || []).reduce((acc, x) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }), {});

export default grouping;
