import AddIcon from '@mui/icons-material/Add';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { setSettings } from '@store/settingsSlice';
import orderBy from 'lodash.orderby';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import useRoute from '../../hooks/useRoute';
import AddDashboardModal from '../dashboard/AddDashboardModal';
import LinkDashboardModal from '../LinkDashboardModal';
import ObjectListItem from './ObjectListItem';

const TabContentDashboards = (props) => {
  const { item } = props;
  const { getParamsByRoute, getTypeByRoute } = useRoute();
  const dispatch = useDispatch();
  const type = getTypeByRoute();
  const { dashboardId, groupId, widgetId } = getParamsByRoute();

  return (
    <>
      <List>
        {orderBy(item.objectsToObjectsByObject2Id, ['object1.name'], ['asc']).map((object, index) => (
          <ObjectListItem
            objectsToObjectsByObject2Id={item.objectsToObjectsByObject2Id}
            key={index}
            index={index}
            item={{ ...object.object1, linkId: object.id }}
            dashboardId={dashboardId}
            widgetId={widgetId}
            groupId={groupId}
            type={type}
          />
        ))}

        <ListItemButton
          onClick={() => {
            LinkDashboardModal({
              widgetId: item.id,
              refetch: props.refetch,
            })
              .then()
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <IconButton size="small">
              <AddIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary="Add existing dashboard/report" />
        </ListItemButton>

        <ListItemButton
          onClick={() => {
            AddDashboardModal()
              .then(() => {
                dispatch(setSettings({ isDrawerOpen: false }));
              })
              .catch(() => {});
          }}
        >
          <ListItemIcon>
            <IconButton size="small">
              <AddIcon />
            </IconButton>
          </ListItemIcon>
          <ListItemText primary={'Add new dashboard'} />
        </ListItemButton>
      </List>
    </>
  );
}; //TabContentObjects

TabContentDashboards.propTypes = {
  refetch: PropTypes.func.isRequired,
};

export default TabContentDashboards;
