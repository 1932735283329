import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const WidgetType: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item>
    <CustomInput
      {...fieldRequiredProps(form, validationSchema, 'widgetType')}
      label="Widget type"
      clearFieldIcon={true}
      disabled
    />
  </Grid>
);

export default WidgetType;
