import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMenu from '@components/side-card/menu/useMenu';
import useMoreMenu from '@components/useMoreMenu';
import LinkOff from '@mui/icons-material/LinkOff';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { ObjectProperty, ObjectsToObject } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { updatedBy } from '@utils/updatedBy';

import { containerClasses } from '../../constants';
import st from '../../style.module.css';

interface IProps {
  items: ObjectsToObject[];
}

const getItemIcon = (itemPropertiesMap: Record<ObjectProperty['key'], ObjectProperty>, alertColor: string) => {
  const hasLink = itemPropertiesMap?.stateValue?.linkedPropertyId;
  if (!hasLink) {
    return <LinkOff />;
  }
  if (itemPropertiesMap?.stateAlert?.value === 'TRIGGERED') {
    return <NotificationsActiveIcon style={{ color: alertColor }} />;
  }
  if (itemPropertiesMap?.stateAlert?.value !== 'OFF') {
    return <NotificationsIcon />;
  }
  return null;
};

const MonitoringItems = ({ items }: IProps) => {
  const theme = useTheme();
  const handleCopy = useHandleCopy();

  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  const { getMenuAlarms, genMenuEditItem, genMenuShowHistory } = useMenu();

  return (
    <>
      <MoreMenu
        items={[
          genMenuEditItem({
            title: 'Source',
            disabled: true,
            handleAction: ({ item, property }) => {},
          }),
          getMenuAlarms({
            disabled: true,
            handleAction: ({ item, property }) => {},
          }),
          genMenuShowHistory({
            disabled: true,
            handleAction: ({ item, property }) => {},
          }),
        ]}
        handleMenuItemClick={closeMoreMenu}
      />
      {items.map((item) => {
        const itemPropertiesMap = getPropertiesMap(item?.object2?.objectProperties);
        const { updatedAt, userByBy } = itemPropertiesMap.stateValue;
        return (
          <ListItem key={item.id} className={st.listItem} classes={containerClasses}>
            <ListItemIcon className={st.listItemIcon}>
              {getItemIcon(itemPropertiesMap, theme.palette.red as string)}
            </ListItemIcon>
            <ListItemText
              onClick={() => {
                handleCopy({
                  text: item.id,
                  message: 'Copied',
                });
              }}
              primary={
                <Typography variant="body1">
                  {itemPropertiesMap.infoName?.value}: {itemPropertiesMap.stateValue?.value || 'n/a'}
                </Typography>
              }
              secondary={updatedBy(updatedAt, userByBy)}
            />
            <ListItemSecondaryAction className={st.itemToHideOrShow}>
              <IconButton
                edge="end"
                aria-label="more"
                onClick={(e) => {
                  openMoreMenu(e, {
                    item: item?.object2,
                    property: itemPropertiesMap.stateValue,
                  });
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </>
  );
};

export default MonitoringItems;
