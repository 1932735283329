import { useCallback } from 'react';
import useIsFieldRequired from './useIsFieldRequired';

// TODO: #773
const useFormikRequiredProps = (formik: any, validationSchema: any) => {
  const isFieldRequired = useIsFieldRequired(validationSchema);

  return useCallback(
    (name: string) => {
      return {
        id: name,
        name,
        value: formik?.values?.[name],
        onChange: formik?.handleChange,
        error: formik?.touched?.[name] && Boolean(formik?.errors?.[name]),
        helperText: formik.touched?.[name] && formik.errors?.[name],
        required: isFieldRequired(name),
      };
    },
    [formik, isFieldRequired]
  );
};

export default useFormikRequiredProps;
