import { useApolloClient } from '@apollo/client';
import { ReactComponent as MenuDown } from '@assets/menu-down.svg';
import EmptyTableWithoutColumns from '@components/common/EmptyTableWithoutColumns';
import WidgetEditControls from '@components/WidgetEditControls';
import getColorOfRow from '@components/widgets/utils/getColorOfRow';
import { highlightSelectedStyle } from '@constants/constants';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { TableContainer, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { SettingsFormatStaticTable } from '@utils/constants/selectOptions';
import useColors from '@utils/useColors';
import { BASE_HEIGHT } from '@utils/widgetSizes';
import React, { forwardRef, useEffect, useReducer, useState } from 'react';
import { TableVirtuoso } from 'react-virtuoso';

import ChartPropertyHistoryTable from './ChartPropertyHistoryTable';
import ControlsPropertyHistoryTable from './ControlsPropertyHistoryTable';
import { generatePropertyHistoryTable } from './prepareData';
import st from './style.module.css';

const W_PropertyHistoryTable = (props) => {
  const client = useApolloClient();
  const { objectProperties, selected, object, id } = props;

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;

  const { getColorBasedOnStyle } = useColors();

  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));

  const defaultValues = {
    valueValue: getPropValue('valueValue'),
    settingsShowTitle: getPropValue('settingsShowTitle'),
    settingsShowRefreshButtons: getPropValue('settingsShowRefreshButtons'),
    chartChartType: getPropValue('chartChartType'),
    chartSmoothType: getPropValue('chartSmoothType'),
    chartLineWidth: getPropValue('chartLineWidth'),
    settingsChartSize: getPropValue('settingsChartSize'),
    settingsYaxisScale: getPropValue('settingsYaxisScale'),
    settingsMaximum: getPropValue('settingsMaximum'),
    settingsMinimum: getPropValue('settingsMinimum'),
    settingsProperty: getPropValue('settingsProperty'),
    settingsSchema: getPropValue('settingsSchema'),
    settingsGeotag: getPropValue('settingsGeotag'),
    settingsGroup: getPropValue('settingsGroup'),
    settingsDateRange: getPropValue('settingsDateRange'),
    settingsPeriod: getPropValue('settingsPeriod'),
    settingsWidgetControls: getPropValue('settingsWidgetControls'),
    settingsLinkedOnly: getPropValue('settingsLinkedOnly'),
    settingsFormat: getPropValue('settingsFormat'),
  };

  const colors = [getPropValue('settingsStyle'), null];

  const [
    {
      valueValue,
      settingsShowTitle,
      settingsShowRefreshButtons,
      chartChartType,
      chartSmoothType,
      chartLineWidth,
      settingsChartSize,
      settingsYaxisScale,
      settingsMaximum,
      settingsMinimum,
      settingsProperty,
      settingsGroup,
      settingsGeotag,
      settingsSchema,
      settingsDateRange,
      settingsPeriod,
      settingsWidgetControls,
      settingsLinkedOnly,
      settingsFormat,
    },
    setValues,
  ] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const [orderByType, setOrderBy] = useState('desc');
  const { headers } = generatePropertyHistoryTable(valueValue);

  function fixedHeaderContent() {
    return (
      <TableRow
        style={{
          whiteSpace: 'normal',
          wordWrap: 'break-word',
        }}
      >
        <TableCell
          onClick={() => {
            setOrderBy(orderByType === 'desc' ? 'asc' : 'desc');
          }}
          className={st.tableCellHead}
          style={{
            color: fgColor,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              height: '38px',
              alignItems: 'center',
            }}
          >
            <span>Date time</span>
            {orderByType === 'desc' && (
              <MenuDown
                style={{
                  height: '20px',
                }}
              />
            )}
            {orderByType === 'asc' && (
              <MenuDown
                style={{
                  transform: 'rotate(180deg)',
                  height: '20px',
                }}
              />
            )}
          </div>
        </TableCell>
        {headers.map((item, index) => (
          <TableCell
            key={`header-table-cell-${index}`}
            align="right"
            className={st.tableCellHead}
            style={{ color: fgColor }}
          >
            {item.name}
          </TableCell>
        ))}
      </TableRow>
    );
  }

  const rowContent = (_index, item) => {
    return (
      <>
        <TableCell component="th" scope="row" className={st.tableCell} style={{ color: fgColor }}>
          {item['time']}
        </TableCell>
        {item.values.map((cellValue, index) => (
          <TableCell
            key={`time-table-cell-${_index}-${index}`}
            scope="row"
            className={st.tableCell}
            style={{
              color: fgColor,
              textAlign: 'right',
              width: '1px',
              whiteSpace: 'nowrap',
            }}
          >
            {cellValue.value}
          </TableCell>
        ))}
      </>
    );
  };

  const VirtuosoTableComponents = {
    Scroller: forwardRef((props, ref) => <TableContainer {...props} ref={ref} />),
    Table: (props) => (
      <Table
        {...props}
        size="small"
        className={st.table}
        aria-label="simple table"
        style={{ backgroundColor: 'transparent' }}
      />
    ),
    TableHead: ({ ...props }) => (
      <TableHead
        {...props}
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
          backgroundColor: bgColor,
          height: `${BASE_HEIGHT}px`,
        }}
      ></TableHead>
    ),
    TableRow: (props) => (
      <TableRow
        {...props}
        style={{
          height: `${BASE_HEIGHT}px`,
          backgroundColor: getColorOfRow(props['data-index'], colors),
        }}
      />
    ),
    TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      const key = data.Objects.relatedNode?.key;
      const value = data.Objects.relatedNode?.value;

      if (key) {
        setValues({ [key]: value });
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  return (
    <div
      className={'force-scroll'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
        textAlign: 'center',
      }}
    >
      {settingsShowTitle && (
        <div
          style={{
            width: '100%',
            height: `${BASE_HEIGHT}px`,
            display: 'flex',
            flexShrink: 0,
            alignItems: 'center',
            color: fgColor,
            justifyContent: 'center',
          }}
        >
          <Typography variant="h5">{props.name}</Typography>
        </div>
      )}

      {settingsShowRefreshButtons && (
        <ControlsPropertyHistoryTable
          settingsStyle={getPropValue('settingsStyle')}
          fgColor={fgColor}
          bgColor={bgColor}
          value={valueValue}
          settingsProperty={settingsProperty}
          settingsGroup={settingsGroup}
          settingsGeotag={settingsGeotag}
          settingsSchema={settingsSchema}
          settingsDateRange={settingsDateRange}
          settingsPeriod={settingsPeriod}
          settingsLinkedOnly={settingsLinkedOnly}
          settingsWidgetControls={settingsWidgetControls}
          item={object}
        />
      )}

      {settingsFormat !== SettingsFormatStaticTable.table && (
        <ChartPropertyHistoryTable
          chartChartType={chartChartType}
          chartSmoothType={chartSmoothType}
          chartLineWidth={chartLineWidth}
          settingsTimeInterval={settingsPeriod}
          settingsChartSize={settingsChartSize}
          settingsYaxisScale={settingsYaxisScale}
          settingsMaximum={settingsMaximum}
          settingsMinimum={settingsMinimum}
          settingsFormat={settingsFormat}
          settingsShowRefreshButtons={settingsShowRefreshButtons}
          settingsShowTitle={settingsShowTitle}
          fgColor={fgColor}
          series={valueValue}
        />
      )}

      {settingsFormat !== 'chart' && (
        <>
          {valueValue.length > 0 && (
            <div style={{ width: '100%', height: '100%' }}>
              <TableVirtuoso
                size="small"
                data={orderByType === 'asc' ? valueValue : valueValue.map((item) => item).reverse()}
                components={VirtuosoTableComponents}
                fixedHeaderContent={fixedHeaderContent}
                itemContent={rowContent}
              />
            </div>
          )}

          {valueValue.length === 0 && <EmptyTableWithoutColumns fgColor={fgColor} />}

          {/*{settingsColumns.columns.length > 0 && valueValue.length === 0 && (*/}
          {/*  <EmptyTableWithColumns*/}
          {/*    fgColor={fgColor}*/}
          {/*    infoText={*/}
          {/*      'If you just configured table you need to click "Update table" button'*/}
          {/*    }*/}
          {/*  />*/}
          {/*)}*/}
        </>
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default React.memo(W_PropertyHistoryTable);
