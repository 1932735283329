import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const Text: FC<FieldProps<IFormValues>> = ({ form }) => (
  <>
    {form.values.text !== undefined ? (
      form.values.text !== null ? (
        <Grid item>
          <CustomInput {...fieldRequiredProps(form, validationSchema, 'text')} label="Text" clearFieldIcon={true} />
        </Grid>
      ) : null
    ) : form.initialValues.text !== null ? (
      <Grid item>
        <CustomInput {...fieldRequiredProps(form, validationSchema, 'text')} label="Text" clearFieldIcon={true} />
      </Grid>
    ) : null}
  </>
);

export default Text;
