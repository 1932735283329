import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import { compareAsc, format, isValid } from 'date-fns';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import CommonModal from '../../../components/CommonModal';
import { msg } from '../../../constants/messages';
import ClockOutline from '../../icons/clockOutline';

const defaultStartDate = new Date();

defaultStartDate.setHours(0);
defaultStartDate.setMinutes(0);
defaultStartDate.setSeconds(0);

const defaultEndDate = new Date();

defaultEndDate.setHours(23);
defaultEndDate.setMinutes(59);
defaultEndDate.setSeconds(0);

const ParameterTimeRange = (props) => {
  const theme = useTheme();

  const formatStart = props.formatStart || 'HH:mm';
  const formatEnd = props.formatEnd || 'HH:mm';

  const [type] = useState('device');
  const [openStart] = useState(false);
  const [, setOpenEnd] = useState(false);

  const reject = () => props.onReject();

  const handleClose = () => reject();
  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);

  const handleSave = () => {
    if (!startDate && !endDate) {
      props.save({
        title: `n/a`,
        value: null,
      });
      handleClose();
    } else if (startDate && endDate) {
      if (isValid(startDate) && isValid(endDate)) {
        props.save({
          title: `${format(startDate, 'HH:mm')} — ${format(endDate, 'HH:mm')}`,
          value: [format(startDate, formatStart), format(endDate, formatEnd)],
        });
        handleClose();
      }
    }
  };

  useEffect(() => {
    if (props.selected.value?.length) {
      const dateStart = new Date();
      const dateEnd = new Date();
      const startTime = props.selected.value[0].split(':');
      const endTime = props.selected.value[1].split(':');

      dateStart.setHours(startTime[0]);
      dateStart.setMinutes(startTime[1]);
      dateStart.setSeconds(0);
      setStartDate(dateStart);

      dateEnd.setHours(endTime[0]);
      dateEnd.setMinutes(endTime[1]);
      dateEnd.setSeconds(0);

      setEndDate(dateEnd);
    }
  }, [type]);

  useEffect(() => {
    if (startDate && endDate) {
      if (compareAsc(startDate, endDate) === 1) {
        setEndDate(null);
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={'Select time range'}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.linkWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={handleSave}>
              {msg.linkWidgetModal.buttonSave}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '16px 16px 16px 0px' }}>
                <ClockOutline style={{ color: theme.palette.gray1 }} />
              </div>
              <TimePicker
                ampm={false}
                clearable
                disableOpenPicker
                open={openStart}
                renderInput={(props) => (
                  <TextField
                    data-test-timepicker="start"
                    {...props}
                    InputProps={{
                      ...props.inputProps,
                      readOnly: true,
                      endAdornment: startDate && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setStartDate(null);
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    style={{ width: '100%' }}
                    variant="standard"
                    label="From"
                    helperText={null}
                  />
                )}
                disableFuture={true}
                inputFormat="HH:mm"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
              />
            </div>
          </Grid>
          <Grid item>
            <div style={{ display: 'flex' }}>
              <div style={{ padding: '16px 16px 16px 0px' }}>
                <ClockOutline style={{ color: theme.palette.gray1 }} />
              </div>
              <TimePicker
                ampm={false}
                clearable
                disableFuture={true}
                disableOpenPicker
                renderInput={(props) => (
                  <TextField
                    onClick={() => setOpenEnd(true)}
                    style={{ width: '100%' }}
                    data-test-timepicker="end"
                    {...props}
                    InputProps={{
                      ...props.inputProps,
                      readOnly: true,
                      endAdornment: endDate && (
                        <InputAdornment position="end">
                          <IconButton
                            size="small"
                            aria-label="reset"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setEndDate(null);
                            }}
                            edge="end"
                          >
                            <CloseIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    label="To"
                    helperText={null}
                  />
                )}
                inputFormat="HH:mm"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(ParameterTimeRange);
