import LabelIcon from '@components/icons/labelIcon';
import st from '@components/side-card/advanced-button/style.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type IControlListItemProps = {
  openTextMenu: (arg0: any, arg1: any) => void;
  buttonText: any;
  item: any;
};

const ControlListItem = ({ openTextMenu, buttonText, item }: IControlListItemProps) => {
  return (
    <ListItem
      classes={{
        container: st.listItemWrapper,
      }}
    >
      <ListItemIcon>
        <LabelIcon />
      </ListItemIcon>
      <ListItemText
        title={buttonText || 'n/a'}
        primary={
          <Typography variant="body1" noWrap={true}>
            Control: {buttonText || 'n/a'}
          </Typography>
        }
      />
      <ListItemSecondaryAction className={st.itemToHideOrShow}>
        <IconButton
          edge="end"
          aria-label="more"
          onClick={(e) => {
            openTextMenu(e, {
              ...item,
              item: buttonText,
            });
          }}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default ControlListItem;
