import { ArrowEnum, OrientationEnum } from './LineTypes';

export const ARROW_OPTIONS = [{ title: 'Arrow', value: ArrowEnum.arrow }];

export const ORIENTATION_OPTIONS = [
  { title: 'Horizontally', value: OrientationEnum.horizontally },
  { title: 'Vertically', value: OrientationEnum.vertically },
];

export const WIDTH_OPTIONS = [
  { title: '1', value: 1 },
  { title: '2', value: 2 },
  { title: '4', value: 4 },
  { title: '6', value: 6 },
];

export const LINE_COLOR_OPTIONS = [
  {
    title: 'Blue',
    value: '#2780e3',
  },
  {
    title: 'White',
    value: '#fff',
  },
  {
    title: 'Red',
    value: '#D50000',
  },
  {
    title: 'Grey',
    value: '#333',
  },
  {
    title: 'Black',
    value: '#000',
  },
];
