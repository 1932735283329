import { CommonFieldProps, IFormValues, validationSchema } from '@components/create-widget/DataChartColor/constants';
import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

const SelectField = ({ form, field, label, list }: FieldProps<IFormValues> & CommonFieldProps) => {
  return (
    <Grid item>
      <CustomSelect {...fieldRequiredProps(form, validationSchema, field.name)} label={label} list={list} />
    </Grid>
  );
};

export default SelectField;
