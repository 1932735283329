import { gql, useLazyQuery } from '@apollo/client';
import LabelIcon from '@components/icons/labelIcon';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import HumanReadableProperty from '@components/side-card/geo-timer/HumanReadableProperty';
import PropListItem from '@components/side-card/PropListItem';
import { DEVICE_PROP_LINKED_QUERY } from '@graphql/queries';
import ExtensionIcon from '@mui/icons-material/Extension';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { isMonitoringObject } from '@utils/objectType';
import { updatedBy } from '@utils/updatedBy';
import { Suspense, useEffect, useState } from 'react';

import tabStyle from '@components/side-card/tab.module.css';

const GET_OBJECT_BY_ID = gql`
  query getObject($id: UUID!) {
    object(id: $id) {
      id
      name
    }
  }
`;

const GET_OBJECT_PROPERTY = gql`
  query getObjectProperty($id: UUID!) {
    objectProperty(id: $id) {
      id
      spec {
        id
        description
      }
      property
      object {
        id
        name
        schemaTags
      }
    }
  }
`;

const DataboxGeneralTab = ({ item, type, dashboardId, groupId, widgetId }) => {
  const [loadObjectProperty, { loading: loadingProperty }] = useLazyQuery(GET_OBJECT_PROPERTY);
  const [loadDevicePropertyLinkedData, { loading: loadingDevicePropertyData }] = useLazyQuery(DEVICE_PROP_LINKED_QUERY);
  const [objectName, setObjectName] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const isLoading = loadingProperty || loadingDevicePropertyData;

  const valueValue = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  const currentIcon = () => {
    return item.objectProperties.find((item) => item.key === 'valueCurrentIcon');
  };

  const currentLabel = () => {
    return item.objectProperties.find((item) => item.key === 'valueCurrentLabel');
  };

  const linkedObject = (key) => {
    return key === 'valueValue' ? item.objectsToObjectsByObject1Id : '';
  };

  const currentIconName = () => {
    const filtersGroup = currentIcon().value;

    return {
      query: GET_OBJECT_BY_ID,
      config: {
        variables: {
          id: filtersGroup,
        },
      },
    };
  };

  const getLoadedValue = (loader, value) => {
    if (loader) {
      return 'Loading...';
    }
    if (!loader && value) {
      return value;
    }
    return 'n/a';
  };

  useEffect(() => {
    const linkedPropertyId = valueValue().linkedPropertyId;

    if (linkedPropertyId) {
      loadObjectProperty({
        variables: {
          id: linkedPropertyId,
        },
      }).then(async (res) => {
        if (isMonitoringObject(res.data.objectProperty.object.schemaTags)) {
          const linkedData = await loadDevicePropertyLinkedData({
            variables: {
              linkedPropId: linkedPropertyId,
            },
            fetchPolicy: 'network-only',
          });
          const fragments = linkedData.data.objectProperty.object.objectsToObjectsByObject2Id[0];
          setObjectName(fragments.forced === 0 ? fragments.object2.name : fragments.object1.name);
          setPropertyName(res.data?.objectProperty.object.name);
        } else {
          setObjectName(res.data?.objectProperty.object.name);
          setPropertyName(res.data.objectProperty.spec.description || res.data.objectProperty.property);
        }
      });
    }
  }, [item]);

  return (
    <>
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <PropListItem
          linkedObjects={linkedObject('valueValue')}
          tab="general"
          obj={item}
          item={valueValue()} //
          dashboardId={dashboardId}
          widgetId={widgetId}
          groupId={groupId}
          type={type}
        />

        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentIcon().updatedAt, currentIcon().userByBy)}
            primary={
              <Typography variant="body1">
                Current icon:
                {currentIcon().value && (
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <HumanReadableProperty
                        payload={currentIconName()}
                        getValue={(data) => ` ${data?.object?.name}` || ' n/a'}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!currentIcon().value && 'n/a'}
              </Typography>
            }
            onClick={() => {}}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon></ListItemIcon>
          <ListItemText
            secondary={updatedBy(currentLabel().updatedAt, currentLabel().userByBy)}
            primary={<Typography variant="body1">Current label: {currentLabel().value || 'n/a'}</Typography>}
          />
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Source</Typography>
        </ListSubheader>

        <ListItem>
          <ListItemIcon>
            <ExtensionIcon></ExtensionIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Object: {getLoadedValue(isLoading, objectName)}</Typography>}
          />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <LabelIcon></LabelIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">Property: {getLoadedValue(isLoading, propertyName)}</Typography>}
          />
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default DataboxGeneralTab;
