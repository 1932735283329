import { gql } from '../../../__generated__';

const GET_REPORT = gql(/* GraphQL */ `
  query getReport($objId: UUID!) {
    object(id: $objId) {
      id
      name
      description
      readergroup
      usergroup
      editorgroup
      objectProperties(sort: { sortBy: { field: PROPERTY } }) {
        groupName
        property
        spec {
          units
          description
          type {
            name
          }
          valueRange
          valueSet
          hidden
        }
        key
        value
      }
    }
  }
`);

const GET_REPORT_FULL = gql(/* GraphQL */ `
  query getFullReport($objId: UUID!) {
    object(id: $objId) {
      id
      name
      description
      readergroup
      usergroup
      editorgroup
      collections: objectsToObjectsByObject1Id(filter: { object2: { schemaTags: { contains: "collection" } } }) {
        id
        target: object2 {
          name
          id
        }
      }
      objectsToObjectsByObject1Id(filter: { object1Id: { equalTo: $objId } }) {
        id
        object2 {
          id
          name
          description
          schemaType
        }
      }
      objectProperties(sort: { sortBy: { field: PROPERTY } }) {
        groupName
        property
        spec {
          units
          description
          type {
            name
          }
          valueRange
          valueSet
          hidden
        }
        key
        value
      }
    }
  }
`);

export { GET_REPORT, GET_REPORT_FULL };
