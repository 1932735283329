import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { DASHBOARD_STREAM_DATA_TIME } from '@constants/constants';
import { msg } from '@constants/messages';
import { WIDGET_QUERY } from '@graphql/queries';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import { loader } from 'graphql.macro';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import useObjectProperty from '../hooks/useObjectProperty';
import useRoute from '../hooks/useRoute';
import CustomAutocomplete from './CustomAutocomplete';
import CustomSelect from './CustomSelect';
import CustomSelectColor from './CustomSelectColor';
import CustomSwitch from './CustomSwitch';
import FormField from './FormField';

const UPDATE_OBJECT_PROPERTY = loader('../graphql/UpdateObjectWithProperties.graphql');
const GET_OBJECT = loader('../graphql/GetObjectQuery.graphql');
const UPDATE_PROPERTY = gql`
  mutation updateObjectProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_PROPERTIES_BY_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      schemaTags
      objectsToObjectsByObject1Id(
        filter: {
          forced: { equalTo: 1 }
          object2: { schemaTags: { equalTo: ["application", "monitor", "object monitoring item"] } }
        }
      ) {
        object2 {
          id
          name
          schemaTags
          objectProperties(orderBy: [PROPERTY_ASC]) {
            id
            groupName
            property
            value
            key
            linkedPropertyId
          }
        }
      }
      objectProperties(
        orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]
        filter: { typeId: { in: ["00000000-0000-0000-0000-000000000002", "00000000-0000-0000-0000-000000000003"] } }
      ) {
        id
        spec {
          description
          property
          groupName
        }
      }
    }
  }
`;

const ONLY_TIMESERIES = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC], filter: { key: { equalTo: "valueTimeseries" } }) {
        id
        spec {
          description
          property
          groupName
        }
      }
    }
  }
`;

const EditWidgetChartModal = (props) => {
  const theme = useTheme();
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);
  const [updateOneProperty] = useMutation(UPDATE_PROPERTY);
  const client = useApolloClient();
  const chart1ObjectProperty = useObjectProperty(GET_PROPERTIES_BY_OBJECT);
  const chart2ObjectProperty = useObjectProperty(GET_PROPERTIES_BY_OBJECT);
  const chart3ObjectProperty = useObjectProperty(GET_PROPERTIES_BY_OBJECT);
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const allowed = {
    hourly: [
      'lasthour',
      'last12hours',
      'lastday',
      'last8weeks',
      'lastweek',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    daily: [
      'lastday',
      'lastweek',
      'last8weeks',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    weekly: [
      'lastweek',
      'last8weeks',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    none: [
      'lasthour',
      'last12hours',
      'lastday',
      'lastweek',
      'lastmonth',
      'last8weeks',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    monthly: ['lastmonth', 'last8weeks', 'lastyear', 'calendarMonth'],
  };

  const isAllowToSelectFunction = (timeInterval, currentGroupBy) => {
    if (!timeInterval || !currentGroupBy) {
      return true;
    }
    return !allowed[currentGroupBy].includes(timeInterval);
  };

  const [fields, setFields] = useState([]);
  const [loadObject] = useLazyQuery(
    GET_OBJECT,
    {
      variables: { objectId: props.widgetId },
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  let defaultValues = {
    chart1_color_list: [
      {
        title: 'Default',
        value: theme.palette.chartColors.default,
      },
      {
        title: 'Gray',
        value: theme.palette.chartColors.gray,
      },
      {
        title: 'Green',
        value: theme.palette.chartColors.green,
      },
      {
        title: 'Orange',
        value: theme.palette.chartColors.orange,
      },
      {
        title: 'Red',
        value: theme.palette.chartColors.red,
      },
    ],
  };

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const { getParamsByRoute } = useRoute();

  const { dashboardId } = getParamsByRoute();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const [periods, setPeriods] = useState([]);
  const [groupingFunctions, setGroupingFunctions] = useState([]);

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: 'network-only',
        });

        const getSource = (source) => {
          if (source.objectId) {
            return {
              title: source.title,
              value: source.objectId,
            };
          }
          return source;
        };

        const getPropValue = (prop) => result.data.object.objectProperties.find((obj) => obj.key === prop)?.value;
        const getPropByKey = (prop) => result.data.object.objectProperties.find((obj) => obj.key === prop);

        setPeriods(
          getPropByKey('chart1GroupBy').spec.valueSet.list.map((item) => ({
            title: item.title,
            value: item.key,
          }))
        );

        setGroupingFunctions(
          getPropByKey('chart1GroupingFunction').spec.valueSet.list.map((item) => ({
            title: item.title,
            value: item.key,
          }))
        );

        setFields([
          getPropByKey('settingsTimeInterval'),
          // getPropByKey("settingsGroupBy"),
        ]);

        defaultValues['chart_yLabel'] = getPropValue('styleY-AxisLabel');
        defaultValues['settingsTimeInterval'] = getPropValue('settingsTimeInterval');

        defaultValues['chart1_label'] = getPropValue('styleChart1Label');
        defaultValues['chart1_color'] = getPropValue('styleChart1Color');
        defaultValues['chart1_source'] = getSource(getPropValue('chart1Source'));
        defaultValues['chart1_property'] = getPropValue('chart1Property');
        defaultValues['chart1_property_id'] = getPropByKey('chart1Property').id;
        defaultValues['chart1_ready_ts'] = getPropValue('chart1UseReady-MadeTimeseries');
        defaultValues['chart1GroupBy'] = getPropValue('chart1GroupBy');
        defaultValues['chart1GroupingFunction'] = getPropValue('chart1GroupingFunction');
        defaultValues['propertyValueId1'] = getPropByKey('valueChart1').id;
        loadObjects(getPropValue('chart1UseReady-MadeTimeseries'));

        // chart1ObjectProperty.objectsQuery(defaultValues["chart1_ready_ts"]);
        if (defaultValues['chart1_source'].value) {
          chart1ObjectProperty.propertiesQuery({
            variables: {
              objectId: defaultValues['chart1_source']?.objectId || defaultValues['chart1_source'].value,
            },
          });
        }

        defaultValues['chart2_label'] = getPropValue('styleChart2Label');
        defaultValues['chart2_color'] = getPropValue('styleChart2Color');
        defaultValues['chart2_source'] = getSource(getPropValue('chart2Source'));
        defaultValues['chart2_property'] = getPropValue('chart2Property');
        defaultValues['chart2_ready_ts'] = getPropValue('chart2UseReady-MadeTimeseries');
        defaultValues['chart2GroupBy'] = getPropValue('chart2GroupBy');
        defaultValues['chart2GroupingFunction'] = getPropValue('chart2GroupingFunction');
        defaultValues['propertyValueId2'] = getPropByKey('valueChart2').id;

        loadObjects2(getPropValue('chart2UseReady-MadeTimeseries'));

        if (defaultValues['chart2_source'].value) {
          chart2ObjectProperty.propertiesQuery({
            variables: {
              objectId: defaultValues['chart2_source']?.objectId || defaultValues['chart2_source'].value,
            },
          });
        }

        defaultValues['chart3_label'] = getPropValue('styleChart3Label');
        defaultValues['chart3_color'] = getPropValue('styleChart3Color');
        defaultValues['chart3_source'] = getSource(getPropValue('chart3Source'));
        defaultValues['chart3_property'] = getPropValue('chart3Property');
        defaultValues['chart3_ready_ts'] = getPropValue('chart3UseReady-MadeTimeseries');
        defaultValues['chart3GroupBy'] = getPropValue('chart3GroupBy');
        defaultValues['chart3GroupingFunction'] = getPropValue('chart3GroupingFunction');
        defaultValues['propertyValueId3'] = getPropByKey('valueChart3').id;

        loadObjects3(getPropValue('chart3UseReady-MadeTimeseries'));

        if (defaultValues['chart3_source'].value) {
          chart3ObjectProperty.propertiesQuery({
            variables: {
              objectId: defaultValues['chart3_source']?.objectId || defaultValues['chart3_source'].value,
            },
          });
        }

        setValues(defaultValues); // loaded values for each field
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps().then(() => {});
  }, []);

  useEffect(() => {
    if (values) {
      if (values.chart1GroupBy === 'none' && values.chart1GroupingFunction !== null) {
        setValues({ chart1GroupingFunction: null });
      }
      if (values.chart2GroupBy === 'none' && values.chart2GroupingFunction !== null) {
        setValues({ chart2GroupingFunction: null });
      }
      if (values.chart3GroupBy === 'none' && values.chart2GroupingFunction !== null) {
        setValues({ chart3GroupingFunction: null });
      }
    }
  }, [values]);

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (typeof checked !== 'undefined') value = checked;
    setValues({ [name]: value });
  };

  const handleInputRaw = (e) => {
    let { name, rawValue } = e.target;
    setValues({ [name]: rawValue || { title: 'n/a', value: null } });
  };

  const loadObjects = (timeseriesReady) => {
    if (timeseriesReady) {
      chart1ObjectProperty.setPropertiesGql(ONLY_TIMESERIES);
      chart1ObjectProperty
        .objectsQuery({
          variables: {
            filter: {
              schemaTags: {
                contains: ['timeseries'],
              },
            },
          },
        })
        .then(() => {});
    } else {
      chart1ObjectProperty.setPropertiesGql(GET_PROPERTIES_BY_OBJECT);
      chart1ObjectProperty
        .objectQuery({
          variables: {
            objectId: props.widgetId,
          },
        })
        .then(() => {});
    }
  };

  const loadObjects2 = (timeseriesReady) => {
    if (timeseriesReady) {
      chart2ObjectProperty.setPropertiesGql(ONLY_TIMESERIES);
      chart2ObjectProperty
        .objectsQuery({
          variables: {
            filter: {
              schemaTags: {
                contains: ['timeseries'],
              },
            },
          },
        })
        .then(() => {});
    } else {
      chart2ObjectProperty.setPropertiesGql(GET_PROPERTIES_BY_OBJECT);
      chart2ObjectProperty
        .objectQuery({
          variables: {
            objectId: props.widgetId,
          },
        })
        .then(() => {});
    }
  };

  const loadObjects3 = (timeseriesReady) => {
    if (timeseriesReady) {
      chart3ObjectProperty.setPropertiesGql(ONLY_TIMESERIES);
      chart3ObjectProperty
        .objectsQuery({
          variables: {
            filter: {
              schemaTags: {
                contains: ['timeseries'],
              },
            },
          },
        })
        .then(() => {});
    } else {
      chart3ObjectProperty.setPropertiesGql(GET_PROPERTIES_BY_OBJECT);
      chart3ObjectProperty
        .objectQuery({
          variables: {
            objectId: props.widgetId,
          },
        })
        .then(() => {});
    }
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetChartModal.editCharts}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const getSource = (source, property) => {
                  if (property.fragmentId) {
                    return {
                      value: property.fragmentId,
                      objectId: source.value,
                      title: source.title,
                    };
                  }
                  return source;
                };

                const valuesReady1 = [
                  {
                    propertyKey: 'styleY-AxisLabel',
                    value: values.chart_yLabel,
                  },
                  {
                    propertyKey: 'settingsTimeInterval',
                    value: values.settingsTimeInterval,
                  },
                  {
                    propertyKey: 'styleChart1Label',
                    value: values.chart1_label,
                  },
                  {
                    propertyKey: 'chart1Source',
                    value: getSource(values.chart1_source, values.chart1_property),
                  },
                  {
                    propertyKey: 'chart1UseReady-MadeTimeseries',
                    value: values.chart1_ready_ts,
                  },
                  {
                    propertyKey: 'chart1Property',
                    value: values.chart1_property,
                  },
                  {
                    propertyKey: 'styleChart1Color',
                    value: values.chart1_color,
                  },
                  {
                    propertyKey: 'chart1GroupBy',
                    value: values.chart1GroupBy,
                  },
                  {
                    propertyKey: 'chart1GroupingFunction',
                    value: values.chart1GroupingFunction,
                  },
                ];
                const valuesReady2 = [
                  // second chart
                  {
                    propertyKey: 'styleChart2Label',
                    value: values.chart2_label,
                  },
                  {
                    propertyKey: 'styleChart2Color',
                    value: values.chart2_color,
                  },
                  {
                    propertyKey: 'chart2Source',
                    value: getSource(values.chart2_source, values.chart2_property),
                  },
                  {
                    propertyKey: 'chart2UseReady-MadeTimeseries',
                    value: values.chart2_ready_ts,
                  },
                  {
                    propertyKey: 'chart2Property',
                    value: values.chart2_property,
                  },
                  {
                    propertyKey: 'chart2GroupBy',
                    value: values.chart2GroupBy,
                  },
                  {
                    propertyKey: 'chart2GroupingFunction',
                    value: values.chart2GroupingFunction,
                  },
                ];
                const valuesReady3 = [
                  // third chart
                  {
                    propertyKey: 'styleChart3Label',
                    value: values.chart3_label,
                  },
                  {
                    propertyKey: 'styleChart3Color',
                    value: values.chart3_color,
                  },
                  {
                    propertyKey: 'chart3Source',
                    value: getSource(values.chart3_source, values.chart3_property),
                  },
                  {
                    propertyKey: 'chart3UseReady-MadeTimeseries',
                    value: values.chart3_ready_ts,
                  },
                  {
                    propertyKey: 'chart3Property',
                    value: values.chart3_property,
                  },
                  {
                    propertyKey: 'chart3GroupBy',
                    value: values.chart3GroupBy,
                  },
                  {
                    propertyKey: 'chart3GroupingFunction',
                    value: values.chart3GroupingFunction,
                  },
                ];

                const linkPropertiesPromises = [];

                linkPropertiesPromises.push(
                  updateOneProperty({
                    variables: {
                      input: {
                        id: values.propertyValueId1,
                        patch: {
                          linkedPropertyId: values.chart1_ready_ts ? values.chart1_property.value : null,
                        },
                      },
                    },
                  })
                );
                linkPropertiesPromises.push(
                  updateOneProperty({
                    variables: {
                      input: {
                        id: values.propertyValueId2,
                        patch: {
                          linkedPropertyId: values.chart2_ready_ts ? values.chart2_property.value : null,
                        },
                      },
                    },
                  })
                );

                linkPropertiesPromises.push(
                  updateOneProperty({
                    variables: {
                      input: {
                        id: values.propertyValueId3,
                        patch: {
                          linkedPropertyId: values.chart3_ready_ts ? values.chart3_property.value : null,
                        },
                      },
                    },
                  })
                );

                toast
                  .promise(
                    Promise.resolve()
                      .then(() =>
                        updateProperty({
                          variables: {
                            input: {
                              detailedObject: [
                                {
                                  objectId: props.widgetId,
                                  keyedProperties: valuesReady1,
                                },
                              ],
                            },
                          },
                        })
                      )
                      .then(() =>
                        updateProperty({
                          variables: {
                            input: {
                              detailedObject: [
                                {
                                  objectId: props.widgetId,
                                  keyedProperties: valuesReady2,
                                },
                              ],
                            },
                          },
                        })
                      )
                      .then(() =>
                        updateProperty({
                          variables: {
                            input: {
                              detailedObject: [
                                {
                                  objectId: props.widgetId,
                                  keyedProperties: valuesReady3,
                                },
                              ],
                            },
                          },
                        })
                      )
                      .then(() => {
                        return createExecution({
                          variables: {
                            input: {
                              controlExecution: {
                                name: 'StreamDataToDashboard',
                                objectId: dashboardId,
                                params: {
                                  initialize_data: true,
                                  stream_data_for: DASHBOARD_STREAM_DATA_TIME,
                                },
                              },
                            },
                          },
                        });
                      }),
                    {
                      loading: 'Updating charts...',
                      success: () => `Charts updated`,
                      error: (err) => `${err.toString()}`,
                    }
                  )
                  .then(() => {
                    return Promise.allSettled(linkPropertiesPromises);
                  })
                  .then(() => {
                    return loadObject();
                  })
                  .then(() => {
                    submit();
                  });
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {fields.map((field) => {
            return (
              <Grid item key={field.key}>
                <FormField
                  name={field.key}
                  value={values[field.key] ?? ''}
                  onChange={handleInputChange}
                  field={field.spec}
                />
              </Grid>
            );
          })}

          <Grid item>
            <CustomInput
              name={`chart_yLabel`}
              label={'Y-axis label'}
              clearFieldIcon={true}
              value={values[`chart_yLabel`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">{`${msg.editWidgetChartModal.chart} 1`}</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="chart1_ready_ts"
              label={'Use ready-made timeseries'}
              value={values['chart1_ready_ts'] ?? ''}
              onChange={(e) => {
                loadObjects(e.target.checked);
                handleInputChange(e);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart1_source"
              label="Source"
              list={chart1ObjectProperty.objects}
              disabled={chart1ObjectProperty.objectsLoading}
              loading={chart1ObjectProperty.objectsLoading}
              value={values[`chart1_source`]?.value ?? ''}
              onChange={(e) => {
                chart1ObjectProperty
                  .propertiesQuery({
                    variables: {
                      objectId: e.target.value,
                    },
                  })
                  .then(() => {});
                handleInputRaw(e);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart1_property"
              label={values['chart1_ready_ts'] ? 'Timeseries' : 'Property'}
              list={chart1ObjectProperty.properties}
              disabled={chart1ObjectProperty.propertiesLoading || chart1ObjectProperty.objectsLoading}
              loading={chart1ObjectProperty.propertiesLoading || chart1ObjectProperty.objectsLoading}
              value={values[`chart1_property`]?.value ?? ''}
              onChange={handleInputRaw}
            />
          </Grid>

          {!values['chart1_ready_ts'] && (
            <>
              <Grid item>
                <CustomSelect
                  disabled={values['chart1_ready_ts']}
                  name="chart1GroupBy"
                  label="Group by"
                  value={values['chart1GroupBy'] ?? ''}
                  list={periods.map((item) => ({
                    ...item,
                    disabled: isAllowToSelectFunction(values['settingsTimeInterval'], item?.value),
                  }))}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  disabled={values['chart1GroupBy'] === 'none'}
                  name="chart1GroupingFunction"
                  label="Grouping function"
                  value={values['chart1GroupingFunction'] ?? ''}
                  list={groupingFunctions}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <CustomInput
              name="chart1_label"
              propType={'string'}
              label={msg.editWidgetChartModal.label}
              clearFieldIcon={true}
              value={values[`chart1_label`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelectColor
              name="chart1_color"
              colors={['default', 'gray3', 'green', 'orange', 'red']}
              label={msg.editWidgetChartModal.color}
              value={values['chart1_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">{`${msg.editWidgetChartModal.chart} 2`}</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="chart2_ready_ts"
              label={'Use ready-made timeseries'}
              value={values['chart2_ready_ts'] ?? ''}
              onChange={(e) => {
                loadObjects2(e.target.checked);
                handleInputChange(e);
              }}
            />
          </Grid>

          <Grid item>
            <CustomAutocomplete
              name="chart2_source"
              label={msg.linkWidgetModal.object}
              list={chart2ObjectProperty.objects}
              disabled={chart2ObjectProperty.objectsLoading}
              loading={chart2ObjectProperty.objectsLoading}
              value={values[`chart2_source`]?.value ?? ''}
              onChange={(e) => {
                chart2ObjectProperty
                  .propertiesQuery({
                    variables: {
                      objectId: e.target.value,
                    },
                  })
                  .then(() => {});
                handleInputRaw(e);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart2_property"
              label={values['chart2_ready_ts'] ? 'Timeseries' : 'Property'}
              list={chart2ObjectProperty.properties}
              disabled={chart2ObjectProperty.propertiesLoading || chart2ObjectProperty.objectsLoading}
              loading={chart2ObjectProperty.propertiesLoading || chart2ObjectProperty.objectsLoading}
              value={values[`chart2_property`]?.value ?? ''}
              onChange={handleInputRaw}
            />
          </Grid>
          {!values['chart2_ready_ts'] && (
            <>
              <Grid item>
                <CustomSelect
                  disabled={values['chart2_ready_ts']}
                  name="chart2GroupBy"
                  label="Group by"
                  value={values['chart2GroupBy'] ?? ''}
                  list={periods.map((item) => ({
                    ...item,
                    disabled: isAllowToSelectFunction(values['settingsTimeInterval'], item?.value),
                  }))}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  disabled={values['chart2GroupBy'] === 'none'}
                  name="chart2GroupingFunction"
                  label="Grouping function"
                  value={values['chart2GroupingFunction'] ?? ''}
                  list={groupingFunctions.map((item) => item)}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <CustomInput
              name="chart2_label"
              label={msg.editWidgetChartModal.label}
              clearFieldIcon={true}
              value={values[`chart2_label`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelectColor
              name="chart2_color"
              colors={['default', 'gray3', 'green', 'orange', 'red']}
              label={msg.editWidgetChartModal.color}
              value={values['chart2_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">{`${msg.editWidgetChartModal.chart} 3`}</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="chart3_ready_ts"
              label={'Use ready-made timeseries'}
              value={values['chart3_ready_ts'] ?? ''}
              onChange={(e) => {
                loadObjects3(e.target.checked);
                handleInputChange(e);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart3_source"
              label={msg.linkWidgetModal.object}
              list={chart3ObjectProperty.objects}
              disabled={chart3ObjectProperty.objectsLoading}
              loading={chart3ObjectProperty.objectsLoading}
              value={values[`chart3_source`]?.value ?? ''}
              onChange={(e) => {
                chart3ObjectProperty
                  .propertiesQuery({
                    variables: {
                      objectId: e.target.value,
                    },
                  })
                  .then(() => {});
                handleInputRaw(e);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart3_property"
              label={values['chart3_ready_ts'] ? 'Timeseries' : 'Property'}
              list={chart3ObjectProperty.properties}
              disabled={chart3ObjectProperty.propertiesLoading || chart3ObjectProperty.objectsLoading}
              loading={chart3ObjectProperty.propertiesLoading || chart3ObjectProperty.objectsLoading}
              value={values[`chart3_property`]?.value ?? ''}
              onChange={handleInputRaw}
            />
          </Grid>

          {!values['chart3_ready_ts'] && (
            <>
              <Grid item>
                <CustomSelect
                  disabled={values['chart3_ready_ts']}
                  name="chart3GroupBy"
                  label="Group by"
                  value={values['chart3GroupBy'] ?? ''}
                  list={periods.map((item) => ({
                    ...item,
                    disabled: isAllowToSelectFunction(values['settingsTimeInterval'], item?.value),
                  }))}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  disabled={values['chart3GroupBy'] === 'none'}
                  name="chart3GroupingFunction"
                  label="Grouping function"
                  value={values['chart3GroupingFunction'] ?? ''}
                  list={groupingFunctions.map((item) => item)}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <CustomInput
              name="chart3_label"
              label={msg.editWidgetChartModal.label}
              clearFieldIcon={true}
              value={values[`chart3_label`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomSelectColor
              name="chart3_color"
              colors={['default', 'gray3', 'green', 'orange', 'red']}
              label={msg.editWidgetChartModal.color}
              value={values['chart3_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetChartModal);
