import { gql, useApolloClient, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import cloneDeep from 'lodash.clonedeep';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import { msg } from '../../../constants/messages';
import CommonModal from '../../CommonModal';
import CustomAutocomplete from '../../CustomAutocomplete';

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const SCHEMA_QUERY = gql`
  query schemaQuery {
    schemata(filter: { mTags: { contains: ["application", "monitor", "object"] } }) {
      id
      name
      schemataByParentSchemaId(filter: { mTags: { contains: ["application", "monitor", "object state"] } }) {
        id
        name
      }
    }
  }
`;

const MonitorStatusAddRowModal = ({ isEdit, conditionProperty, conditionIndex, onResolve, onReject, isOpen }) => {
  const client = useApolloClient();

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY);

  const [typesList, setTypesList] = useState([]);
  const [statesList, setStatesList] = useState([]);

  const [valueType, setValueType] = useState(null);
  const [valueState, setValueState] = useState(null);

  const submit = () => onResolve();

  const reject = () => onReject();

  useEffect(() => {
    const queryObjects = async () => {
      // setLoading(true);
      try {
        const result = await client.query({
          query: SCHEMA_QUERY,
          fetchPolicy: 'network-only',
        });

        setTypesList(
          result.data.schemata.map((item) => {
            return {
              value: item.id,
              title: item.name,
              states: item.schemataByParentSchemaId.map((item) => ({
                value: item.id,
                title: item.name,
              })),
            };
          })
        );

        // setValue(props.value);
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        // setLoading(false);
      }
    };

    queryObjects().then(() => {});
  }, []);

  useEffect(() => {
    if (isEdit && typesList.length > 0) {
      const value = conditionProperty.value.rows[conditionIndex];

      if (value.state) {
        setValueState(value.state);
      }

      if (value.type) {
        setValueType(value.type);
      }

      typesList.forEach((item) => {
        if (item.value === value.type.value) {
          setStatesList(item.states);
        }
      });
    }
  }, [typesList]);

  useEffect(() => {
    if (!valueType) {
      setValueState(null);
    }

    if (valueState) {
      if (!statesList.map((item) => item.value).includes(valueState.value)) {
        setValueState(null);
      }
    }
  }, [valueType]);

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: conditionProperty.id,
          patch: {
            value: value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };
  return (
    <CommonModal
      key="MonitorTableColumnModal"
      modalOpen={isOpen}
      title={isEdit ? 'Edit row' : 'Add row'}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button
            disabled={!Boolean(valueType) || !Boolean(valueState)}
            color="primary"
            onClick={async () => {
              const oldValues = cloneDeep(conditionProperty.value.rows);

              if (isEdit) {
                oldValues.splice(conditionIndex, 1, {
                  type: valueType,
                  state: valueState,
                });
                let patch = {
                  ...conditionProperty.value,
                  rows: [...oldValues],
                };
                handleEditProperty(patch);
              } else {
                oldValues.push({
                  type: valueType,
                  state: valueState,
                });
                let patch = {
                  ...conditionProperty.value,
                  rows: [...oldValues],
                };

                handleEditProperty(patch);
              }
            }}
          >
            {loading ? <CircularProgress size={23} /> : isEdit ? msg.default.save : msg.default.add}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CustomAutocomplete
            name="typeMonitor"
            label="Type"
            list={typesList}
            value={valueType?.value}
            onChange={(e) => {
              if (e.target?.rawValue) {
                setStatesList(e.target.rawValue?.states);
                setValueType({
                  title: e.target.rawValue.title,
                  value: e.target.rawValue.value,
                });
              } else {
                setValueType(null);
              }
            }}
            required={true}
          />
        </Grid>
        <Grid item>
          <CustomAutocomplete
            name="stateMonitor"
            label="State"
            list={statesList}
            value={valueState?.value}
            onChange={(e) => {
              setValueState(e.target.rawValue);
            }}
            required={true}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(MonitorStatusAddRowModal);
