import { FORMAT_OPTIONS_COMMAND_BUTTON } from '@components/create-widget/databox/constants';
import { FormatCommandButtonEnum } from '@components/create-widget/databox/DataboxTypes';
import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { ChangeEvent, FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import setFieldsByVariant from './utils/setFieldsByVariant';

const Format: FC<FieldProps<IFormValues>> = ({ form }) => {
  const requiredProps = fieldRequiredProps(form, validationSchema, 'format');

  return (
    <>
      <Grid item>
        <CustomSelect
          {...requiredProps}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setFieldsByVariant(form, e.target.value as FormatCommandButtonEnum);
            requiredProps.onChange(e);
          }}
          label="Format"
          list={FORMAT_OPTIONS_COMMAND_BUTTON}
        />
      </Grid>
    </>
  );
};

export default Format;
