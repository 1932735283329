import { gql } from '@src/__generated__';

export const SELECTED_SCHEMA_PROPERTIES = gql(/* GraphQL */ `
  query loadSchemaProperties($id: UUID!) {
    schema(id: $id) {
      schemaProperties {
        id
        groupName
        property
        description
      }
    }
  }
`);
