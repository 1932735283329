export const parseObject = (json: unknown) => {
  if (typeof json === 'string') {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return JSON.parse(json);
    } catch (error) {
      return {};
    }
  }
  return json;
};
