import { useApolloClient } from '@apollo/client';
import { ReactComponent as MenuDown } from '@assets/menu-down.svg';
import WidgetInitInfo from '@components/common/WidgetInitInfo';
import WidgetEditControls from '@components/WidgetEditControls';
import { COLOR_SCHEMAS, highlightSelectedStyle } from '@constants/constants';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useColors from '@utils/useColors';
import { BASE_HEIGHT } from '@utils/widgetSizes';
import { format, parseISO } from 'date-fns';
import orderBy from 'lodash.orderby';
import sortBy from 'lodash.sortby';
import { useEffect, useState } from 'react';

import st from './style.module.css';

const isNullableValue = (val) => {
  if (typeof val === 'string') {
    return val.trim() === '';
  } else if (!val) {
    return val !== 0;
  }
  return false;
};

const W_MonitorTable = (props) => {
  const { objectProperties, selected, id, name } = props;
  const client = useApolloClient();

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const [valueInitial, setValue] = useState(objectProperties.find((obj) => obj.key === 'valueValue')?.value);
  const columns = objectProperties.find((item) => item.key === 'sourceColumns').value?.columns || [];
  const colors = [getPropValue('settingsStyle'), null];
  const showTitle = getPropValue('settingsShowTitle');
  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));

  const [sort, setSort] = useState({
    type: 'asc',
    name: 'object',
  });

  const headers = (() => {
    if (valueInitial.length) {
      let sortedCollection = sortBy(valueInitial[0].properties, function (item) {
        return columns.findIndex((o) => o.property.id === item.schemaId);
      });

      return sortedCollection.map((item) => {
        const type = columns.find((i) => i.property.id === item.schemaId)?.property?.type;

        if (type && type !== 'value') {
          return `${item.name} (Last update)`;
        }

        return item.name || 'n/a';
      });
    }
    return [];
  })();

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  const sortColumns = (value) => {
    const flat = [];

    value.forEach((item) => {
      const itemLocal = {
        ...item,
        properties: {},
        propertiesInternal: {},
      };

      let sortedCollection = sortBy(item.properties, function (item) {
        return columns.findIndex((o) => o.property.id === item.schemaId);
      });

      sortedCollection.forEach((property) => {
        const lastUpdate = format(parseISO(property.lastUpdate), 'dd-MM-yyyy HH:mm:ss');
        const type = columns.find((item) => item.property.id === property.schemaId)?.property?.type;
        itemLocal.properties[type === 'value' ? property.name : `${property.name} (Last update)`] =
          type === 'value' ? property.value : lastUpdate;
        itemLocal.propertiesInternal[`${property.name}_isAlert`] = property.isAlert;

        if (type !== 'value') {
          itemLocal.propertiesInternal[`${property.name} (Last update)`] = property.lastUpdate;
        }
      });

      flat.push(itemLocal);
    });

    if (sort.name && sort.type) {
      if (sort.name === 'object') {
        return orderBy(flat, ['objectName'], [sort.type]);
      }

      if (sort.name.includes('Last update')) {
        return orderBy(flat, [(o) => new Date(o.propertiesInternal[`${sort.name}`])], [sort.type]);
      }

      return orderBy(flat, [(o) => o.properties[`${sort.name}`]], [sort.type]);
    }

    return flat;
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        setValue(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  return (
    <div
      className={'force-scroll'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: bgColor,
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
      }}
    >
      <div style={{ width: '100%' }}>
        <Table size="small" className={st.table} aria-label="simple table" style={{ backgroundColor: 'transparent' }}>
          <TableHead
            style={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: bgColor,
            }}
          >
            {showTitle && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                }}
              >
                <TableCell
                  className={st.tableCellHead}
                  align="center"
                  variant="head"
                  colSpan={headers?.length + sortColumns(valueInitial)?.length}
                >
                  <Typography style={{ color: fgColor }} variant="h6">
                    {name}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            {headers?.length > 0 && (
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                }}
              >
                <TableCell
                  className={st.tableCellHead}
                  onClick={() => {
                    setSort({
                      name: 'object',
                      type: 'object' === sort.name && sort.type === 'asc' ? 'desc' : 'asc',
                    });
                  }}
                  style={{ textAlign: 'left', color: fgColor }}
                >
                  Object
                  {sort.type === 'desc' && sort.name === 'object' && (
                    <MenuDown
                      style={{
                        height: '20px',
                        position: 'absolute',
                      }}
                    />
                  )}
                  {sort.type === 'asc' && sort.name === 'object' && (
                    <MenuDown
                      style={{
                        transform: 'rotate(180deg)',
                        height: '20px',
                        position: 'absolute',
                      }}
                    />
                  )}
                </TableCell>
                {headers?.map((header, index) => (
                  <TableCell
                    key={index}
                    onClick={() => {
                      setSort({
                        name: header,
                        type: header === sort.name && sort.type === 'asc' ? 'desc' : 'asc',
                      });
                    }}
                    align="right"
                    className={st.tableCellHead}
                    style={{ color: fgColor, paddingRight: '25px' }}
                  >
                    {header}

                    {sort.type === 'desc' && sort.name === header && (
                      <MenuDown
                        style={{
                          height: '20px',
                          position: 'absolute',
                        }}
                      />
                    )}
                    {sort.type === 'asc' && sort.name === header && (
                      <MenuDown
                        style={{
                          transform: 'rotate(180deg)',
                          height: '20px',
                          position: 'absolute',
                        }}
                      />
                    )}
                  </TableCell>
                ))}
              </TableRow>
            )}
          </TableHead>
          <TableBody>
            {valueInitial?.length > 0 &&
              sortColumns(valueInitial).map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    height: `${BASE_HEIGHT}px`,
                    backgroundColor: getColorOfRow(index),
                  }}
                >
                  <TableCell scope="row" className={st.tableCell} style={{ color: fgColor }}>
                    {item.objectName}
                  </TableCell>
                  {Object.entries(item.properties).map(([key, value]) => (
                    <TableCell
                      align="right"
                      className={st.tableCell}
                      style={{
                        color: fgColor,
                        backgroundColor: item.propertiesInternal[`${key}_isAlert`] ? '#D50000' : '',
                      }}
                    >
                      {`${isNullableValue(value) ? 'n/a' : value}`}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
      {(columns?.length === 0 || valueInitial?.length === 0) && (
        <WidgetInitInfo fgColor={fgColor} infoText={'Make sure you added at least one column'} />
      )}
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_MonitorTable;
