import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { ChangeEvent, FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import useControl from './hooks/useControl';

const defaultErrorText = {
  error: true,
  helperText: 'Failed to load ...',
};

const Control: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { error, rpcsListFormated, action } = useControl(form);
  const requiredProps = fieldRequiredProps(form, validationSchema, 'control');

  const isLogicError = !action?.objectId ? { helperText: 'First select an object', disabled: true, error: true } : {};

  return (
    <Grid item>
      <CustomSelect
        {...requiredProps}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          const schemaId = rpcsListFormated.find((item) => item.value === e?.target?.value)?.schemaId;
          form.setFieldValue('schemaId', schemaId);
          requiredProps.onChange(e);
        }}
        {...(error ? defaultErrorText : null)}
        label="Control"
        list={rpcsListFormated}
        {...isLogicError}
      />
    </Grid>
  );
};

export default Control;
