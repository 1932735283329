import * as yup from 'yup';

export type ParameterType = 'number' | 'int' | 'string' | 'bool' | 'json' | 'json object' | 'json array';

export interface IFormValues {
  control: string;
  widgetId: string;
  propKey: string;
  parameters: {
    title: string;
    value: string | null | boolean | number;
    argument: string;
    type: ParameterType;
  }[];
}

export const validationSchema = yup.object({
  control: yup.string().trim().required('Control is required'),
  parameters: yup.array().of(
    yup
      .object({
        title: yup.string(),
        value: yup.mixed(),
        argument: yup.string(),
        type: yup.string(),
      })
      .test('validate-based-on-type', 'Invalid JSON format or type mismatch', function (param) {
        const { type, value } = param || {};
        if (!value) return true;

        try {
          const parsed = JSON.parse(value);

          if ((type === 'json' || type === 'json object') && typeof parsed !== 'object') {
            return this.createError({ path: `${this.path}.value`, message: 'Value must be a valid JSON object' });
          }
          if (type === 'json array' && !Array.isArray(parsed)) {
            return this.createError({ path: `${this.path}.value`, message: 'Value must be a valid JSON array' });
          }
          return true;
        } catch {
          if (type === 'json' || type === 'json array' || type === 'json object') {
            return this.createError({ path: `${this.path}.value`, message: 'Invalid JSON format' });
          }
          return true;
        }
      })
  ),
});
