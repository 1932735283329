import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { CommonFieldProps, IFormValues, validationSchema } from '../../constants/formik';

const InputField = ({ form, field, label, clearFieldIcon, multiline }: FieldProps<IFormValues> & CommonFieldProps) => {
  return (
    <Grid item>
      <CustomInput
        {...fieldRequiredProps(form, validationSchema, field.name)}
        label={label}
        multiline={multiline}
        clearFieldIcon={clearFieldIcon}
      />
    </Grid>
  );
};

export default InputField;
