import { useCallback } from 'react';

const useSmoothScrollToElement = () => {
  return useCallback((elementId: string) => {
    const element = document.getElementById(elementId);

    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      console.warn(`ElementById"${elementId}" is not found`);
    }
  }, []);
};

export default useSmoothScrollToElement;
