import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { UPDATE_PROPERTIES } from '@modules/reports/api/UpdateProperties';
import { GET_OBJECT } from '@shared/api/getObject';
import { ObjectProperty } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';

import { GET_OBJECT_PROPERTIES } from '@shared/api/getObjectProperties';
import { IFormValues, validationSchema } from '../constants';
import { parseObject } from '../utils';

interface IProps {
  onResolve: () => void;
  widgetId?: string;
  group: ObjectProperty[];
}

const useForm = ({ onResolve, group, widgetId }: IProps) => {
  const [updateProperties, { loading: savingLoading }] = useMutation(UPDATE_PROPERTIES);
  const [, { refetch, loading: refetchLoading }] = useLazyQuery(GET_OBJECT, {
    variables: { objectId: widgetId },
    fetchPolicy: 'cache-and-network',
  });
  const { data, loading } = useQuery(GET_OBJECT_PROPERTIES, {
    variables: { ids: group.map((item) => item.id as string) },
    fetchPolicy: 'network-only',
  });

  const initialValues = data?.objectProperties.reduce((acc, item) => {
    let value = item.value;
    if (item.spec?.type?.name?.includes('json') && typeof value === 'object') {
      value = JSON.stringify(item.value, null, 2);
    }
    acc[item?.key] = value;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return acc;
  }, {});

  const formik = {
    initialValues,
    validationSchema,
    onSubmit: async (values: IFormValues) => {
      const propertiesMap = getPropertiesMap(group);
      const payload = Object.entries(values)
        .map(([key, value]) => ({
          propertyKey: key,
          value: propertiesMap[key].spec?.type?.name?.includes('json') ? parseObject(value) : value, // 'json array', 'json', 'json object'
        }))
        .filter((item) => item.value !== initialValues[item.propertyKey]);

      await updateProperties({
        variables: {
          input: {
            detailedObject: [{ objectId: widgetId, keyedProperties: payload }],
          },
        },
      });
      await refetch();
      onResolve();
    },
  };
  return {
    formik: data && formik,
    savingLoading: savingLoading || refetchLoading || loading,
    properties: data?.objectProperties,
  };
};

export default useForm;
