import { gql } from '@src/__generated__';

export const LOAD_ALL_GROUPS = gql(/* GraphQL */ `
  query loadAllGroups {
    userGroups {
      id
      isSystem
      groupName
      description
    }
  }
`);
