import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const SwitchTextOn: FC<FieldProps<IFormValues>> = ({ form }) => (
  <>
    {form.values.switchTextOn !== undefined ? (
      form.values.switchTextOn !== null ? (
        <Grid item>
          <CustomInput
            {...fieldRequiredProps(form, validationSchema, 'switchTextOn')}
            label="ON text"
            clearFieldIcon={true}
          />
        </Grid>
      ) : null
    ) : form.initialValues.switchTextOn !== null ? (
      <Grid item>
        <CustomInput
          {...fieldRequiredProps(form, validationSchema, 'switchTextOn')}
          label="ON text"
          clearFieldIcon={true}
        />
      </Grid>
    ) : null}
  </>
);

export default SwitchTextOn;
