import { useTheme } from '@mui/system';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import st from './style.module.css';

const sideBarWidth = 412;

const ContentLayout = (props) => {
  const theme = useTheme();
  const isFullScreen = useSelector((state) => state.settings.isFullScreen);
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const contentStyle = {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const contentShiftStyle = {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: sideBarWidth,
  };

  return (
    <>
      <main
        style={{
          userSelect: isEditMode ? 'none' : 'auto',
          WebkitUserSelect: isEditMode ? 'none' : 'auto',
          overflow: 'auto',
          ...contentStyle,
          ...(!isFullScreen ? contentShiftStyle : {}),
        }}
        className={clsx(st.content, {
          [st.contentShift]: !isFullScreen,
        })}
      >
        {props.children}
      </main>
    </>
  );
};

export default ContentLayout;
