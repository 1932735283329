import CommonModal from '@components/CommonModal';
import { msg } from '@constants/messages';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import Function from './components/Function';
import InputField from './components/InputField';
import Property from './components/Property';
import Schema from './components/Schema';
import SwitchField from './components/SwitchField';
import useForm from './hooks/useForm';

export interface IObjectStatisticProps {
  onResolve?: () => void;
  isOpen?: boolean;
  object?: any;
}

const EditObjectStatisticModal = ({ onResolve, isOpen, object }: IObjectStatisticProps) => {
  const { formik, isLoading, objectPropertiesMap } = useForm({ onResolve, object });

  const functionOptions =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    objectPropertiesMap?.settingsFunction?.spec?.valueSet?.list?.map((item: { key: string; title: string }) => ({
      value: item?.key,
      title: item?.title,
    }));

  return (
    <CommonModal
      key="EditObjectStatistic"
      modalOpen={isOpen}
      title={'Edit object'}
      handleClose={onResolve}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="subtitle2" color="primary">
            General info
          </Typography>
        </Grid>
        <FastField name="name" label="Name" clearFieldIcon={true} component={InputField} />
        <FastField name="enabled" label="Enabled" component={SwitchField} />
        <Grid item>
          <Typography variant="subtitle2" color="primary">
            Settings
          </Typography>
        </Grid>
        <FastField name="schemaId" label="Schema" component={Schema} />
        <FastField name="propertyId" label="Property" component={Property} />
        <FastField name="functionId" label="Function" list={functionOptions} component={Function} />
        <FastField name="linkedOnly" label="Linked only" component={SwitchField} />

        <Grid item>
          <Typography variant="subtitle2" color="primary">
            Description
          </Typography>
        </Grid>
        <FastField
          name="description"
          clearFieldIcon={true}
          multiline={true}
          label={msg.addWidgetModal.description}
          component={InputField}
        />
      </Grid>
    </CommonModal>
  );
};
export default create(EditObjectStatisticModal);
