import { useLazyQuery } from '@apollo/client';
import OBJECT_HISTORY_LIST from '@modules/objects/api/objectHistory';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { ObjectPropertiesHistoriesEdge } from '@src/__generated__/graphql';
import { formatISO, sub } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ComponentItem } from './components/ComponentItem';
import HistoryLoader from './components/HistoryLoader';
import HistoryToolbar from './components/HistoryToolbar';
import { ProcessItemType } from './constants';
import st from './style.module.css';
import { downloadPropertiesHistory, downloadPropertyHistory, processItem } from './utils';

interface IProps {
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any;
}

const HistoryLayout: FC<IProps> = ({ id, object }) => {
  const isPropertyHistory = Boolean(id);

  const [recordedFilter, setRecordedFilter] = useState<[string, string]>([
    formatISO(sub(Date.now(), { days: 1 })),
    formatISO(Date.now()),
  ]);
  const [after, setAfter] = useState(null);
  const [historyItems, setHistoryItems] = useState<ProcessItemType[]>([]);
  const [loadHistory, { data }] = useLazyQuery(OBJECT_HISTORY_LIST, {
    variables: {
      first: 30,
      after,
      filter: {
        objectId: {
          equalTo: isPropertyHistory ? id : object.id,
        },
        recordedAt: {
          greaterThanOrEqualTo: recordedFilter[0],
          lessThanOrEqualTo: recordedFilter[1],
        },
        ...(isPropertyHistory
          ? {
              propertyId: { equalTo: id },
              objectId: { equalTo: object.id },
            }
          : null),
      },
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (res) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const objectPropertiesMap: Record<string, any> = object?.objectProperties.reduce((acc, property) => {
        if (!acc[property.id]) {
          acc[property.id as string] = property;
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return acc as Record<string, any>;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }, {} as Record<string, any>);
      const items: ProcessItemType[] = (res?.objectPropertiesHistoriesConnection?.edges ?? []).map((item) => {
        return processItem(item as ObjectPropertiesHistoriesEdge, objectPropertiesMap);
      });
      setHistoryItems(items);
    },
  });

  useEffect(() => {
    loadHistory().catch(() => {});
  }, [id, recordedFilter, object.id]);

  const fetchData = () => {
    if (data.objectPropertiesHistoriesConnection.pageInfo.hasNextPage) {
      setAfter(data.objectPropertiesHistoriesConnection.pageInfo.endCursor);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
      }}
      className={st.root}
    >
      <HistoryToolbar
        name={object.name}
        recordedFilter={recordedFilter}
        setRecordedFilter={setRecordedFilter}
        setAfter={setAfter}
        downloadHistory={isPropertyHistory ? downloadPropertyHistory : downloadPropertiesHistory}
        downloadIds={isPropertyHistory ? [id] : [object?.id]}
      />
      <div className={st.content} id="scrollContainer">
        <List>
          <InfiniteScroll
            scrollableTarget="scrollContainer"
            dataLength={historyItems.length}
            next={fetchData}
            hasMore={data?.objectPropertiesHistoriesConnection?.pageInfo?.hasNextPage ?? true}
            loader={<HistoryLoader />}
            endMessage={
              <Typography variant={'body1'} className={st.empty}>
                {historyItems.length === 0 && <span>No more data</span>}
              </Typography>
            }
          >
            {historyItems.map((historyItem, index: number) => (
              <ComponentItem key={index} item={historyItem} />
            ))}
          </InfiniteScroll>
        </List>
      </div>
    </Box>
  );
};

export default HistoryLayout;
