import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

const GET_PROPERTY = gql(`
  query getObjectProperty($id: UUID!) {
      objectProperty(id: $id) {
          id
          spec {
            units
            description
          }
      }
  }
`);

const usePropertyInfoData = ({ value, raw, time, author }: any) => {
  const {
    loading,
    error,
    data: dataPropertyId,
  } = useQuery(GET_PROPERTY, {
    variables: {
      id: raw?.propertyId,
    },
  });

  const data = useMemo(
    () => [
      { name: 'Long name', value: error ? 'Error' : dataPropertyId?.objectProperty?.spec?.description },
      { name: 'Name', value: raw?.property },
      { name: 'Value', value },
      { name: 'Units', value: error ? 'Error' : dataPropertyId?.objectProperty?.spec?.units },
      { name: 'Time', value: time },
      { name: 'Author', value: author },
    ],
    [dataPropertyId, error, value, time, author]
  );

  return { loading, data };
};

export default usePropertyInfoData;
