import { msg } from '@constants/messages';
import { Button } from '@mui/material';
import { FC } from 'react';

interface IProps {
  handleClose: () => void;
  isEdit?: boolean;
}

const Buttons: FC<IProps> = ({ handleClose, isEdit }) => (
  <>
    <Button color="inherit" onClick={handleClose}>
      {msg.default.cancel}
    </Button>
    <Button
      data-test={isEdit ? 'editWidget' : undefined}
      data-test-add-widget={isEdit ? undefined : 'true'}
      color="primary"
      type="submit"
      disabled={false}
    >
      {isEdit ? msg.default.save : msg.default.add}
    </Button>
  </>
);
export default Buttons;
