import { gql } from '@apollo/client';

const RPCS_QUERY = gql`
  query getRPC($deviceId: UUID!) {
    object(id: $deviceId) {
      schema {
        schemaControls(orderBy: [RPC_DESC, ARGUMENT_DESC]) {
          rpc
          description
          schemaId
          isRpc
          valueRange
          valueSet
          defaultValue
          description
          argument
          typeSpec
          typeId: typeSpec
        }
      }
    }
  }
`;

export { RPCS_QUERY };
