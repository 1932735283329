import { useLazyQuery, useMutation } from '@apollo/client';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useEffect, useReducer, useState } from 'react';
import { msg } from '../../../constants/messages';
import CommonModal from '../../CommonModal';

import { toast } from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';

import { loader } from 'graphql.macro';
import isEmpty from 'lodash.isempty';
import sortBy from 'lodash.sortby';
import isEqual from 'lodash.isequal';
import { ObjectProperty, ObjectsToObject } from '../../../__generated__/graphql';
import Alarm from './Alarm';
import AlarmOptions from './AlarmOptions';
import { GET_CONTROLS_LIST } from './api/getControlsList';
import { WEEK_DAYS } from './constants';
import {
  alarmOptionsInitialValue,
  createAlarmConfig,
  defaultAlarmOptions,
  divide60Calculator,
  getControlsList,
  modulo60Calculator,
} from './functions';
import {
  AlarmAlertsOptionsPropertyType,
  AlarmAlertsPropertyType,
  AlarmInitialValues,
  AlarmOptionsDefaultType,
  AlarmOptionsType,
  AlarmType,
  ControlsListType,
  defaultValues,
  DefaultValuesReducer,
  OnOffType,
  ValuesType,
} from './type';

const UPDATE_PROPERTIES_BY_IDS = loader('src/graphql/UpdateObjectPropertiesByIdMutation.graphql');

type EditWidgetAlarmsModalProps = {
  isMonitor: boolean;
  properties: ObjectProperty[];
  value: AlarmType[];
  handlePrepareData: any;
  propertyId?: string;
  linkedObjects: ObjectsToObject[];
};

const EditWidgetAlarmsModal = (props: EditWidgetAlarmsModalProps & InstanceProps<{}>) => {
  const [updateProperties] = useMutation(UPDATE_PROPERTIES_BY_IDS);

  const getPropertyByKey = <K extends 'alarmsAlerts' | 'alarmsAlertsOptions'>(
    key: K
  ): K extends 'alarmsAlerts' ? AlarmAlertsPropertyType : AlarmAlertsOptionsPropertyType => {
    return props.properties?.find((item) => item.key === key);
  };
  const alarmAlertsPropertyId: string | undefined = getPropertyByKey('alarmsAlerts')?.id;
  const alarmAlertsOptionsPropertyId: string | undefined = getPropertyByKey('alarmsAlertsOptions')?.id;

  const [showAlarm1, setShowAlarm1] = useState(true);
  const [showAlarm2, setShowAlarm2] = useState(true);
  const [showAlarm3, setShowAlarm3] = useState(true);

  const defaultValuesReducer: DefaultValuesReducer = (prev, updated) => ({
    ...prev,
    ...updated,
  });
  const [values, setValues] = useReducer(defaultValuesReducer, defaultValues);

  const [alarmsWeekDays, setAlarmsWeekDays] = useState({
    alarm1: WEEK_DAYS,
    alarm2: WEEK_DAYS,
    alarm3: WEEK_DAYS,
  });

  const [allArguments, setAllArguments] = useState([]);

  const [loadControlsList] = useLazyQuery(GET_CONTROLS_LIST, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { controlsList, params } = getControlsList(data);
      setAllArguments(params);

      const whichAlarmUserWorkingOn = values.whichAlarmUserWorkingOn;
      const findSelectedControls = (controls: ControlsListType[], value) => controls.find((i) => i.rpc === value);

      setValues({
        [whichAlarmUserWorkingOn]: {
          ...values[whichAlarmUserWorkingOn],
          selectedControls: findSelectedControls(controlsList, values[whichAlarmUserWorkingOn].selectedControls.value),
          controlsList,
        },
      });
    },
  });
  useEffect(() => {
    const alarmsAlerts = getPropertyByKey('alarmsAlerts');
    const alarmOptions = getPropertyByKey('alarmsAlertsOptions');
    const getPropValue = () => (props.isMonitor ? props?.value : alarmsAlerts?.value);
    const chooseAlert = (alertNumber: number) => getPropValue()[alertNumber - 1] || AlarmInitialValues;
    const prefixFrom = (alarmNumber) => `alarmsAlert${alarmNumber}_timeIntervalInMinutes_from`;
    const prefixTo = (alarmNumber) => `alarmsAlert${alarmNumber}_timeIntervalInMinutes_to`;
    const getTimeInterval = (intervalType: 'to' | 'from', index: number): string | number => {
      return chooseAlert(index)?.timeIntervalInMinutes[intervalType];
    };

    const setAlarmsAlertIntervals = (value: string | number, prefix) => {
      const interval = value;

      return {
        [`${prefix}_h`]: divide60Calculator(interval),
        [`${prefix}_m`]: modulo60Calculator(interval),
      };
    };

    const handleAlert = (alertNumber: number) => {
      const alert = chooseAlert(alertNumber);
      const alertPrefix = `alarmsAlert${alertNumber}`;
      const conditionPath = `${alertPrefix}_condition`;
      const timerPath = `${alertPrefix}_timer`;
      const timeoutPath = `${alertPrefix}_timeout`;
      const timeIntervalFrom = getTimeInterval('from', alertNumber);
      const timeIntervalTo = getTimeInterval('to', alertNumber);

      const alarmObject: Partial<ValuesType> = {
        [`${conditionPath}_value`]: alert?.condition?.value,
        [`${conditionPath}_operator`]: alert?.condition?.operator,
        ...setAlarmsAlertIntervals(timeIntervalFrom, prefixFrom(alertNumber)),
        ...setAlarmsAlertIntervals(timeIntervalTo, prefixTo(alertNumber)),
      };

      if (!props.isMonitor) {
        alarmObject[`${timeoutPath}_units`] = alert?.timeout.units || 'minutes';
        alarmObject[`${timeoutPath}_value`] = alert?.timeout.value;
        alarmObject[`${timerPath}_dismiss`] = alert?.timer?.dismiss || false;
        alarmObject[`${timerPath}_units`] = alert?.timer?.units || 'minutes';
        alarmObject[`${timerPath}_value`] = alert?.timer?.value;
      }

      return alarmObject;
    };

    setValues({
      ...handleAlert(1),
      ...handleAlert(2),
      ...handleAlert(3),
    });
    const firstAlert = chooseAlert(1);
    const secondAlert = chooseAlert(2);
    const thirdAlert = chooseAlert(3);

    const alarmAlerts1WeekDays = firstAlert?.weekDays || alarmsWeekDays.alarm1;
    const alarmAlerts2WeekDays = secondAlert?.weekDays || alarmsWeekDays.alarm2;
    const alarmAlerts3WeekDays = thirdAlert?.weekDays || alarmsWeekDays.alarm3;

    setAlarmsWeekDays({
      alarm1: alarmAlerts1WeekDays,
      alarm2: alarmAlerts2WeekDays,
      alarm3: alarmAlerts3WeekDays,
    });

    const setAlarm = (index: number, alertObj, alarmAlertsWeekDays, setShowAlarm) => {
      const isPropValueEmpty = getPropValue()?.length === 0;
      const conditions = [
        getTimeInterval('from', index) !== '',
        getTimeInterval('to', index) !== '',
        alertObj?.timeout.value !== '' && alertObj?.timeout.value !== 0,
        !isEqual(sortBy(alarmAlertsWeekDays), WEEK_DAYS),
      ];
      const isShowAlarmCondition = conditions.some((item) => item);

      setShowAlarm(!isPropValueEmpty && isShowAlarmCondition);
    };

    setAlarm(1, firstAlert, alarmAlerts1WeekDays, setShowAlarm1);
    setAlarm(2, secondAlert, alarmAlerts2WeekDays, setShowAlarm2);
    setAlarm(3, thirdAlert, alarmAlerts3WeekDays, setShowAlarm3);

    // Options
    if (!props.isMonitor) {
      const getPropValueOptions = (): AlarmOptionsDefaultType => alarmOptions.value;

      const linkedObjectsList = props.linkedObjects?.map(({ object2 }) => ({
        value: object2.id,
        title: object2.name,
      }));

      const on = getPropValueOptions().on;
      const off = getPropValueOptions().off;

      const selectedLinkedObjectOn = linkedObjectsList.find((item) => item.value === on.linkedObject.value);
      const selectedLinkedObjectOff = linkedObjectsList.find((item) => item.value === off.linkedObject.value);

      setValues({
        executeOnAlarmOn: !isEmpty(selectedLinkedObjectOn),
        executeOnAlarmOff: !isEmpty(selectedLinkedObjectOff),
        optionalTags: getPropValueOptions().tags,
        whichAlarmUserWorkingOn: null,
        repeat: alarmOptions?.value?.repeat || {
          enabled: false,
          value: null,
          units: 'minutes',
        },
      });

      if (linkedObjectsList?.length === 0) {
        updateProperties({
          variables: {
            input: {
              propertiesArray: [
                {
                  propertyId: alarmAlertsOptionsPropertyId,
                  value: {
                    tags: values.optionalTags,
                    on: defaultAlarmOptions,
                    off: defaultAlarmOptions,
                  },
                },
              ],
            },
          },
        })
          .then(() => {
            setValues({
              executeOnAlarmOnObject: alarmOptionsInitialValue([]),
              executeOnAlarmOffObject: alarmOptionsInitialValue([]),
            });
          })
          .catch(() => {
            toast.error('Updating Properties failed');
          });
      } else {
        const createExecuteObject = (condition) => {
          const selectedLinkedObject = condition === on ? selectedLinkedObjectOn : selectedLinkedObjectOff;
          const controls = {
            rpc: null,
            value: null,
          };
          const argumentsAndParameters = isEmpty(selectedLinkedObject) ? [] : condition.arguments;

          return {
            selectedLinkedObject: selectedLinkedObject || {
              value: null,
              title: null,
            },
            linkedObjectsList,
            selectedControls: isEmpty(selectedLinkedObject) ? controls : condition.controls,
            params: argumentsAndParameters,
          };
        };
        setValues({
          executeOnAlarmOnObject: createExecuteObject(on),
          executeOnAlarmOffObject: createExecuteObject(off),
        });

        setValues({
          whichAlarmUserWorkingOn: 'executeOnAlarmOnObject',
        });

        loadControlsList({
          variables: { id: selectedLinkedObjectOn?.value || '' },
        })
          .then(() => {
            setValues({
              whichAlarmUserWorkingOn: 'executeOnAlarmOffObject',
            });
            return loadControlsList({
              variables: { id: selectedLinkedObjectOff?.value || '' },
            });
          })
          .catch(() => {
            toast.error('Loading Controls failed');
          });
      }
    }
  }, []);

  const submit = (...params) => props.onResolve(params);
  const reject = () => props.onReject();

  const handleClose = () => reject();
  const handleUpdateWidgetProps = (
    valuesReady: { propertyKey: string; value: AlarmType[] }[],
    alarmsAlertsOptions?: {
      propertyId: string;
      value: AlarmOptionsDefaultType;
    }
  ) => {
    const value = props.handlePrepareData ? props.handlePrepareData(valuesReady[0].value) : valuesReady[0].value;

    const propertiesArray = [
      {
        propertyId: props.isMonitor ? props.propertyId : alarmAlertsPropertyId,
        value,
      },
    ];

    if (!props.isMonitor) {
      propertiesArray.push(alarmsAlertsOptions);
    }

    toast
      .promise(
        updateProperties({
          variables: {
            input: {
              propertiesArray,
            },
          },
        }),
        {
          loading: 'Updating...',
          success: () => 'Updated',
          error: (err) => `${err.toString()}`,
        }
      )
      .then(() => {
        submit();
      })
      .catch(() => {});
  };

  const buildAlarmsAlertsConfigs = () => {
    return [
      createAlarmConfig(1, alarmsWeekDays, values),
      createAlarmConfig(2, alarmsWeekDays, values),
      createAlarmConfig(3, alarmsWeekDays, values),
    ];
  };

  const buildValuesReady = (alarmsList: AlarmType[]) => {
    return [
      {
        propertyKey: 'alarmsAlerts',
        value: alarmsList,
      },
    ];
  };

  const buildControlsConfig = (object: AlarmOptionsType): OnOffType => {
    return {
      controls: {
        rpc: object.selectedControls?.rpc,
        value: object.selectedControls?.rpc,
      },
      arguments: object.params,
      linkedObject: {
        value: object.selectedLinkedObject?.value,
      },
    };
  };

  const buildAlarmsAlertsOptions = (onObject: AlarmOptionsType, offObject: AlarmOptionsType) => {
    const onConfig = buildControlsConfig(onObject);
    const offConfig = buildControlsConfig(offObject);

    const alarmsAlertsOptions: {
      propertyId: string;
      value: AlarmOptionsDefaultType;
    } = {
      propertyId: alarmAlertsOptionsPropertyId,
      value: {
        tags: values.optionalTags,
        on: onConfig,
        off: offConfig,
        repeat: values.repeat,
      },
    };
    return alarmsAlertsOptions;
  };

  return (
    <CommonModal
      modalOpen={props.isOpen}
      title={msg.editWidgetAlarmsModal.alarms}
      handleClose={handleClose}
      buttons={
        <>
          <Button onClick={handleClose} color="inherit" data-test-edit-alarm="WidgetAlarmCancel">
            {msg.editWidgetModal.buttonCancel}
          </Button>
          <Button
            data-test-save-alarm="WidgetAlarmSave"
            color="primary"
            onClick={() => {
              const alarmsAlertsConfigs = buildAlarmsAlertsConfigs();
              const executeOnAlarmOnObject = values.executeOnAlarmOnObject;
              const executeOnAlarmOffObject = values.executeOnAlarmOffObject;
              const valuesReady = buildValuesReady(alarmsAlertsConfigs);

              if (!props.isMonitor) {
                const alarmsAlertsOptions = buildAlarmsAlertsOptions(executeOnAlarmOnObject, executeOnAlarmOffObject);
                handleUpdateWidgetProps(valuesReady, alarmsAlertsOptions);
                return;
              }
              handleUpdateWidgetProps(valuesReady);
            }}
          >
            {msg.editWidgetModal.buttonUpdate}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        {/* alert 1 */}
        <Alarm
          values={values}
          setValues={setValues}
          showAlarm={showAlarm1}
          setShowAlarm={setShowAlarm1}
          setAlarmsWeekDays={setAlarmsWeekDays}
          alarmsWeekDays={alarmsWeekDays}
          isMonitor={props.isMonitor}
          alertNumber={1}
        />
        {/* alert 2 */}
        <Alarm
          values={values}
          setValues={setValues}
          showAlarm={showAlarm2}
          setShowAlarm={setShowAlarm2}
          setAlarmsWeekDays={setAlarmsWeekDays}
          alarmsWeekDays={alarmsWeekDays}
          isMonitor={props.isMonitor}
          alertNumber={2}
        />
        {/* alert 3 */}
        <Alarm
          values={values}
          setValues={setValues}
          showAlarm={showAlarm3}
          setShowAlarm={setShowAlarm3}
          setAlarmsWeekDays={setAlarmsWeekDays}
          alarmsWeekDays={alarmsWeekDays}
          isMonitor={props.isMonitor}
          alertNumber={3}
        />
        {/* Options */}
        {!props.isMonitor && (
          <AlarmOptions
            values={values}
            setValues={setValues}
            allArguments={allArguments}
            setAllArguments={setAllArguments}
          />
        )}
      </Grid>
    </CommonModal>
  );
};

export default create(EditWidgetAlarmsModal);
