import CustomSelect from '@components/CustomSelect';
import { ARROW_OPTIONS } from '@modules/widgets/forms/LineWidgetModal/constants/constants';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const LeftEnd: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <Grid item md={6} xs={12}>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'leftEnd')}
        label="Left end"
        list={ARROW_OPTIONS}
        clearFieldIcon={true}
      />
    </Grid>
  );
};

export default LeftEnd;
