import { useMutation, useSubscription } from '@apollo/client';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import { UPDATE_PROPERTY } from '@modules/objects/api/updateProperty';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { Object as ObjectType } from '@src/__generated__/graphql';
import { useMemo } from 'react';

import { SUBSCRIBE_MONITOR_ITEMS } from '@components/side-card/monitor-object/MonitorObjectGeneralTab/api/SubscribeMonitorItems';
import { containsAll } from '@components/side-card/monitor-object/MonitorObjectGeneralTab/utils';
import ManagementItems from './components/ManagementItems';
import MonitoringItems from './components/MonitoringItems';
import st from './style.module.css';

export interface ITabGeneralObjectStatisticProps {
  item: ObjectType;
}

const MonitorObjectGeneralTab = ({ item }: ITabGeneralObjectStatisticProps) => {
  const monitoringItems = useMemo(
    () =>
      item.objectsToObjectsByObject1Id
        .filter((obj) => containsAll(['application', 'monitor', 'object monitoring item'], obj?.object2?.schemaTags))
        .sort((a, b) => a.object2?.name?.toLowerCase()?.localeCompare(b.object2?.name?.toLowerCase())),
    [item.objectsToObjectsByObject1Id]
  );

  const managementItems = useMemo(
    () =>
      item.objectsToObjectsByObject1Id
        .filter((obj) => containsAll(['application', 'monitor', 'object management item'], obj?.object2?.schemaTags))
        .sort((a, b) => a.object2?.name?.toLowerCase()?.localeCompare(b.object2?.name?.toLowerCase())),
    [item.objectsToObjectsByObject1Id]
  );

  useSubscription(SUBSCRIBE_MONITOR_ITEMS, {
    variables: {
      objectId: item.id,
      tags: ['application', 'monitor', 'object monitoring item'],
    },
  });

  useSubscription(SUBSCRIBE_MONITOR_ITEMS, {
    variables: {
      objectId: item.id,
      tags: ['application', 'monitor', 'object management item'],
    },
  });

  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const handleUpdateProperty = async (id: string, value) => {
    await updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  return (
    <>
      <List>
        {monitoringItems.length > 0 && (
          <>
            <ListSubheader color="primary" className={st.listSubheader}>
              <Typography variant="subtitle2">Monitoring items</Typography>
            </ListSubheader>
            <MonitoringItems items={monitoringItems} />
          </>
        )}

        {managementItems.length > 0 && (
          <>
            <ListSubheader color="primary" className={st.listSubheader}>
              <Typography variant="subtitle2">Management items</Typography>
            </ListSubheader>
            <ManagementItems items={managementItems} />
          </>
        )}

        <ServiceSection classes={st} item={item} />

        <AccessSection classes={st} item={item} />

        {item.description && <DescriptionSection classes={st} item={item} />}
      </List>
    </>
  );
};

export default MonitorObjectGeneralTab;
