import { gql } from '@src/__generated__';

export const GET_OBJECT = gql(/* GraphQL */ `
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      id
      name
      description
      schema {
        id
        name
        mPicture
        mIcon
      }
      schemaTags
      schemaType
      enabled
      muted
      userGroupByEditorgroup {
        id
        groupName
      }
      userGroupByUsergroup {
        id
        groupName
      }
      userGroupByReadergroup {
        id
        groupName
      }
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
        groupName
        property
        value
        key
        id
        linkedPropertyId
        updatedAt
        spec {
          id
          hidden
          units
          key
          valueSet
          valueRange
          description
          defaultValue
          type {
            id
            name
          }
        }
        userByBy {
          id
          login
        }
      }

      objectsToObjectsByObject1Id(filter: { object2: { schemaType: { notEqualTo: "media" } } }) {
        id
        object2 {
          id
          name
          muted
          description
          schemaTags
          schemaType
          enabled
          ...AccessGroupsFragment
          objectProperties(orderBy: [GROUP_NAME_DESC, PROPERTY_DESC]) {
            id
            groupName
            property
            linkedPropertyId
            value
            key
            updatedAt
            userByBy {
              id
              login
            }
          }
        }
      }
      objectsToObjectsByObject2Id {
        id
        object1 {
          id
          name
          description
          schemaTags
          schemaType
          enabled
          ...AccessGroupsFragment
          objectProperties(orderBy: [GROUP_NAME_DESC, PROPERTY_DESC]) {
            id
            groupName
            property
            linkedPropertyId
            value
            key
            updatedAt
            userByBy {
              id
              login
            }
          }
        }
      }
    }
  }
`);
