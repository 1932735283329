import { FormatCommandButtonEnum } from '@components/create-widget/databox/DataboxTypes';
import LabelIcon from '@components/icons/labelIcon';
import st from '@components/side-card/advanced-button/style.module.css';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

type IControlListItemProps = {
  openTextMenu: (arg0: any, arg1: any) => void;
  buttonText: any;
  item: any;
  openTextOnMenu: (arg0: any, arg1: any) => void;
  buttonOnText: any;
  openTextOffMenu: (arg0: any, arg1: any) => void;
};

const ControlListItem = ({
  openTextMenu,
  buttonText,
  item,
  openTextOnMenu,
  buttonOnText,
  openTextOffMenu,
}: IControlListItemProps) => {
  const getPropertyByKey = (key: string) => {
    return item.objectProperties.find((objectProperty: { key: string }) => objectProperty.key === key);
  };

  const rpcControlOn = () => getPropertyByKey('targetControlOn');
  const rpcControlOff = () => getPropertyByKey('targetControlOff');
  const mode = () => getPropertyByKey('settingsFormat');
  const buttonOffText = () => getPropertyByKey('valueSwitchtextoff');

  return (
    <>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-argument */}
      {[FormatCommandButtonEnum.switcher_text, FormatCommandButtonEnum.switcher].includes(mode().value) ? (
        <>
          <ListItem
            classes={{
              container: st.listItemWrapper,
            }}
          >
            <ListItemIcon>
              <LabelIcon></LabelIcon>
            </ListItemIcon>
            <ListItemText
              title={rpcControlOn()?.value?.rpc || 'n/a'}
              primary={
                <Typography variant="body1" noWrap={true}>
                  Control ON: {rpcControlOn()?.value?.rpc || 'n/a'}
                </Typography>
              }
            />
            <ListItemSecondaryAction className={st.itemToHideOrShow}>
              <IconButton
                edge="end"
                aria-label="more"
                onClick={(e) => {
                  openTextOnMenu(e, {
                    ...item,
                    item: buttonOnText(),
                  });
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem
            classes={{
              container: st.listItemWrapper,
            }}
          >
            <ListItemIcon>
              <LabelIcon></LabelIcon>
            </ListItemIcon>
            <ListItemText
              title={rpcControlOff()?.value?.rpc || 'n/a'}
              primary={
                <Typography variant="body1" noWrap={true}>
                  Control OFF: {rpcControlOff()?.value?.rpc || 'n/a'}
                </Typography>
              }
            />
            <ListItemSecondaryAction className={st.itemToHideOrShow}>
              <IconButton
                edge="end"
                aria-label="more"
                onClick={(e) => {
                  openTextOffMenu(e, {
                    ...item,
                    item: buttonOffText(),
                  });
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        </>
      ) : (
        <ListItem
          classes={{
            container: st.listItemWrapper,
          }}
        >
          <ListItemIcon>
            <LabelIcon></LabelIcon>
          </ListItemIcon>
          <ListItemText
            title={buttonText || 'n/a'}
            primary={
              <Typography variant="body1" noWrap={true}>
                Control: {buttonText || 'n/a'}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={st.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openTextMenu(e, {
                  ...item,
                  item: buttonText,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      )}
    </>
  );
};

export default ControlListItem;
