import HeaderStatuses from '@components/side-card/monitor-object/HeaderStatuses';
import { msg } from '@constants/messages';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { isMonitoringObject } from '@utils/objectType';

import st from './style.module.css';

const CommonHeader = (props) => {
  const { item } = props;
  const theme = useTheme();

  return (
    <>
      <div className={st.commonHeader}>
        <Typography variant="h6" data-test-card={item.name} noWrap={true}>
          {item.name}
        </Typography>
        <IconButton onClick={() => {}} size="small" className={st.starButton}>
          <StarBorderIcon color="primary" />
        </IconButton>
      </div>
      <Box className={st.boxContainer}>
        <Typography variant="body1" noWrap={true} style={{ color: theme.palette.gray1 }}>
          {item.enabled ? <span>{msg.sideCard.enabled}</span> : <span>{msg.sideCard.disabled}</span>}
        </Typography>
        {isMonitoringObject(item?.schemaTags) && <HeaderStatuses item={item} />}
        {item.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'on' && (
          <NotificationsIcon fontSize={'small'} style={{ color: theme.palette.gray1 }} />
        )}
        {item.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'triggered' && (
          <NotificationsActiveIcon fontSize={'small'} style={{ color: theme.palette.red }} />
        )}
      </Box>
    </>
  );
};

export default CommonHeader;
