/**
 * Checks if all elements in the 'sub' array are present in the 'main' array.
 *
 * @param main - The main array to check against.
 * @param sub - The sub-array whose elements are checked for presence in the main array.
 * @returns A boolean indicating whether all elements in 'sub' are present in 'main'.
 *          Returns false if either array is empty or undefined.
 */
export function containsAll(main: Array<string>, sub: Array<string>): boolean {
  if (!main?.length || !sub?.length) {
    return false;
  }

  return sub.every((item) => main.includes(item));
}