import { useMutation } from '@apollo/client';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import { REMOVE_OBJECT } from '@modules/objects/api/removeObject';
import useMedia from '@utils/useMedia';

type Options = {
  objectId: string;
  newImageId: string | null;
  prevImageId: string | null;
  unlinkWithRemove?: boolean;
  currentLinkId?: string;
};

export const useUpdateBackgroundImage = () => {
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const [deleteObjects] = useMutation(REMOVE_OBJECT);
  const [linkObjects] = useMutation(LINK_OBJECTS);

  const removeImage = async (imageId) => {
    await deleteObjects({
      variables: {
        input: {
          mnPatch: [{ id: imageId }],
        },
      },
    }).catch(() => {});
  };

  const updateImageToObjectLink = async ({
    objectId,
    newImageId,
    prevImageId,
    unlinkWithRemove,
    currentLinkId,
  }: Options) => {
    if (prevImageId && newImageId !== prevImageId) {
      // unlink previous image
      if (currentLinkId) {
        await unlinkObjects({
          variables: {
            linkId: currentLinkId,
          },
        }).catch(() => {});
      }
      if (unlinkWithRemove) {
        await removeImage(prevImageId);
      }
    }
    if (newImageId && newImageId !== prevImageId) {
      // link new image
      await linkObjects({
        variables: {
          widgetId: objectId,
          objectId: newImageId,
        },
      }).catch(() => {});
    }
  };

  const { uploadMedia } = useMedia();
  const uploadImageFile = async (img: Blob | string | null) => {
    if (img instanceof Blob) {
      try {
        const { data } = await uploadMedia(img);
        return data as string | null;
      } catch (e) {
        return null;
      }
    } else if (typeof img === 'string') {
      return img;
    } else {
      return null;
    }
  };

  const uploadImageWithoutLink = async (previousUid: string | null, newUid: string | Blob | null): Promise<string> => {
    const uploadingImageUid = await uploadImageFile(newUid);
    if (uploadingImageUid !== previousUid && previousUid) {
      await removeImage(previousUid);
    }
    return uploadingImageUid;
  };

  return { updateImageToObjectLink, uploadImageFile, uploadImageWithoutLink, removeImage };
};
