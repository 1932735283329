import ImageItem from '@components/common/ImageItem';
import { msg } from '@constants/messages';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../../constants/formik';
import st from './style.module.css';

const Logo = ({ form, field }: FieldProps<IFormValues>) => {
  return (
    <>
      <Grid item>
        <Typography variant={'subtitle2'} className={st.companyLabel}>
          {msg.settingsModal.companyLogo}
        </Typography>
      </Grid>
      <Grid item width="100%">
        <ImageItem
          {...fieldRequiredProps(form, validationSchema, field.name)}
          label={'Company logo'}
          withUnlink={false}
        />
      </Grid>
    </>
  );
};

export default Logo;
