import { gql } from '@src/__generated__';

export const GET_NOTIFICATIONS_DELIVERIES = gql(/* GraphQL */ `
  query fetchNotificationsDeliveries($first: Int = 30, $after: Cursor, $condition: NotificationDeliveryCondition) {
    notificationDeliveriesConnection(first: $first, after: $after, orderBy: CREATED_AT_DESC, condition: $condition) {
      nodes {
        id
        message
        createdAt
        user
        userLogin
        notification {
          id
          message
          createdAt
          userByBy {
            id
            login
            mName
          }
          byName
          tags
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`);
