import { useMutation, useQuery } from '@apollo/client';
import showMessages from '@hooks/apollo/showMessages';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { UPDATE_WIDGET } from '@modules/widgets/api/UpdateWidget';
import { IFormValues, validationSchema } from '../constants/formik';

interface IProps {
  onResolve: () => void;
  widgetId?: string;
  propKey?: string;
}

const useForm = ({ onResolve, widgetId, propKey }: IProps) => {
  const [editWidget, { loading }] = useMutation(UPDATE_WIDGET, {
    ...showMessages,
  });
  const { data, loading: loadingWidget } = useQuery(GET_WIDGET, {
    variables: { objId: widgetId },
    fetchPolicy: 'network-only',
  });

  const action = data?.object.objectProperties?.find((obj) => obj.key === propKey)?.value;

  const formik = {
    initialValues: {
      name: action?.objectId || '',
      widgetId,
    },
    validationSchema,
    onSubmit: (values: IFormValues) => {
      void editWidget({
        variables: {
          widgetId,
          values: [
            {
              propertyKey: propKey,
              value: { objectId: values.name },
            },
            {
              propertyKey: 'targetControlOn',
              value: {},
            },
            {
              propertyKey: 'targetControlOff',
              value: {},
            },
          ],
        },
      }).then(() => {
        onResolve();
      });
    },
  };

  return { formik: data && formik, isLoading: loading || loadingWidget };
};

export default useForm;
