import BlurOnIcon from '@mui/icons-material/BlurOn';
import { ObjectProperty } from '@src/__generated__/graphql';

import st from './style.module.css';

interface IProps {
  statusesEmulation?: ObjectProperty;
}

const EmulationStatus = ({ statusesEmulation }: IProps) => {
  if (!statusesEmulation?.value) return null;

  return (
    <span className={st.icon}>
      <BlurOnIcon fontSize="inherit" />
    </span>
  );
};

export default EmulationStatus;
