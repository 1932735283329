import { gql, useApolloClient, useMutation } from '@apollo/client';
import CustomInput from '@components/CustomInput';
import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import WidgetEditControls from '@components/WidgetEditControls';
import { highlightSelectedStyle } from '@constants/constants';
import { RPC_PARAMS_QUERY } from '@graphql/queries';
import LoadingButton from '@mui/lab/LoadingButton';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import useColors from '@utils/useColors';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

import { UPDATE_WIDGET } from '@modules/widgets/api/UpdateWidget';
import st from './style.module.css';

const GET_DATA_SUBSCRIPTION = gql`
  subscription Objects($objId: UUID!) {
    Objects(filterA: { id: [$objId] }) {
      event
      relatedNode {
        ... on ObjectProperty {
          id
          linkedPropertyId
          groupName
          property
          value
          key
        }
      }
      relatedNodeId
    }
  }
`;

const W_AdvancedButton = (props) => {
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);
  const [editWidget] = useMutation(UPDATE_WIDGET);

  const { id, objectProperties, selected } = props;
  const [listOfParams, setListOfParams] = useState([]);

  const getPropValue = (prop) => objectProperties.find((obj) => obj.key === prop)?.value;
  const action = getPropValue('valueValue');
  const buttonText = getPropValue('valueText');
  const params = getPropValue('valueParams');

  let defaultValues = {};

  const [valuesParams, setValuesParams] = useReducer(
    (prev, updated) => (updated['action'] === 'reset' ? defaultValues : { ...prev, ...updated }),
    defaultValues
  );

  const handleInputParamsChange = (e) => {
    let { name, value } = e.target;
    setValuesParams({ [name]: value });
  };

  const loadRpcParams = async (rpc) => {
    setValuesParams({ ['action']: 'reset' });
    const localAction = getPropValue('valueValue');
    if (rpc?.schemaId || action?.schemaId) {
      try {
        const result = await client.query({
          query: RPC_PARAMS_QUERY,
          variables: {
            schemaId: rpc?.schemaId || localAction.schemaId,
            rpc: rpc?.value || localAction.value,
          },
          fetchPolicy: 'network-only',
        });

        setListOfParams(
          result.data.schemaControls.map((item) => {
            return {
              value: item.argument,
              title: item.description || item.argument,
              raw: item,
            };
          })
        );
      } catch (err) {
        toast.error(err.toString());
      }
    } else {
      setListOfParams([]);
    }
  };
  const client = useApolloClient();

  const style = getPropValue('settingsStyle');

  const { getColorBasedOnStyle } = useColors();

  const valueCurrentColor = getPropValue('valueCurrentColor');
  const isFieldEmpty = getPropValue('valueValue')?.value?.value;
  const [colors, setColors] = useState(getColorBasedOnStyle(style, valueCurrentColor));

  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const [bgColor, setBgColor] = useState(style !== 'lightondark' ? colors.bg : '#fff');

  const handleExecuteControl = () => {
    isFieldEmpty
      ? toast
          .promise(
            createExecution({
              variables: {
                input: {
                  controlExecution: {
                    objectId: action.objectId,
                    params: valuesParams,
                    name: action.value,
                  },
                },
              },
            }).then(() => {
              editWidget({
                variables: {
                  widgetId: id,
                  values: [
                    {
                      propertyKey: 'valueParams',
                      value: valuesParams,
                    },
                  ],
                },
              }).then();
            })
          )
          .then()
      : toast.error('No control was found.');
  };

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        loadRpcParams(data.Objects.relatedNode.value).then(() => {});
      } else if (data.Objects.relatedNode?.key === 'valueCurrentColor') {
        setColors(getColorBasedOnStyle(style, data.Objects.relatedNode?.value));
      } else if (data.Objects.relatedNode?.key === 'settingsStyle') {
        setColors(getColorBasedOnStyle(data.Objects.relatedNode?.value, valueCurrentColor));
        if (data.Objects.relatedNode.value !== 'lightondark') {
          setBgColor(colors.bg);
        } else {
          setBgColor('#fff');
        }
      }
    });

    return () => subscription.unsubscribe();
  }, [id]);

  useEffect(() => {
    loadRpcParams().then(() => {});
  }, [id]);

  const mergeValues = (defaults, userValues) => {
    return { ...defaults, ...userValues };
  };

  return (
    <div
      className={'force-scroll'}
      style={{
        position: 'relative',
        backgroundColor: bgColor,
        pointerEvents: isEditMode ? 'none' : 'auto',
        filter: selected ? highlightSelectedStyle : '',
        borderRadius: '2px',
        height: '100%',
      }}
    >
      <div
        style={{
          padding: style !== 'lightontransparent' ? '0 2px 2px 2px' : '0',
          gap: '0',
          rowGap: '4px',
          columnGap: '6px',
          marginTop: style !== 'lightontransparent' ? '2px' : '0',
          display: 'grid',
          flexGrow: 1,
          gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
          gridAutoFlow: 'dense',
        }}
      >
        {listOfParams.map((item) => (
          <CustomInput
            title={item.title}
            className={st.root}
            variant={'outlined'}
            key={item.value}
            name={item.value}
            size={'medium'}
            placeholder={item.title}
            clearFieldIcon={true}
            inputProps={{
              disableUnderline: true,
              style: {
                height: style !== 'lightontransparent' ? 42 : 46,
              },
            }}
            value={mergeValues(params, valuesParams)[item.value] ?? ''}
            onChange={handleInputParamsChange}
          />
        ))}

        <RpcSubscribeWrapper
          isConfirm={true}
          rpcName={action.value}
          objectId={action.objectId}
          object={null}
          handler={handleExecuteControl}
          title={buttonText || 'n/a'}
        >
          <LoadingButton
            sx={{
              whiteSpace: 'nowrap',
              display: 'inline-block',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              height: style !== 'lightontransparent' ? 42 : 46,
            }}
            variant={style !== 'lightontransparent' ? 'outlined' : 'contained'}
            fullWidth
            disableElevation
          ></LoadingButton>
        </RpcSubscribeWrapper>
      </div>
      <WidgetEditControls {...props} />
    </div>
  );
};

export default W_AdvancedButton;
