import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const Description: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item>
    <CustomInput
      {...fieldRequiredProps(form, validationSchema, 'description')}
      label="Description"
      clearFieldIcon={true}
    />
  </Grid>
);

export default Description;
