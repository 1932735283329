import { useMutation } from '@apollo/client';
import showMessages from '@hooks/apollo/showMessages';
import { UPDATE_OBJECT } from '@modules/objects/api/updateObject';

interface IProps {
  onResolve?: () => void;
  id?: string;
  enabled?: boolean;
}

const useForm = ({ onResolve, enabled, id }: IProps) => {
  const [updateObject, { loading }] = useMutation(UPDATE_OBJECT, {
    ...showMessages,
  });

  const formik = {
    initialValues: {},
    onSubmit: () => {
      void updateObject({
        variables: {
          input: {
            id,
            patch: {
              enabled: !enabled,
            },
          },
        },
      }).then(() => {
        onResolve();
      });
    },
  };

  return { formik, isLoading: loading };
};

export default useForm;
