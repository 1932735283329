import { gql } from '@src/__generated__';

export const DASHBOARD_NAMES_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getDashboardNamesWithoutCollections {
    dashboards: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "dashboard"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);
