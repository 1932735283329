import { Button } from '@mui/material';
import { FC } from 'react';
import { msg } from '../../../../../../constants/messages';

interface IProps {
  handleClose: () => void;
}

const Buttons: FC<IProps> = ({ handleClose }) => (
  <>
    <Button color="inherit" onClick={handleClose}>
      {msg.default.cancel}
    </Button>
    <Button color="primary" type="submit" disabled={false}>
      {msg.default.save}
    </Button>
  </>
);
export default Buttons;
