import AddGroupModal from '@components/AddGroupModal';
import SelectWidget from '@components/create-widget/SelectWidget';
import DeleteGroupModal from '@components/DeleteGroupModal';
import { msg } from '@constants/messages';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import { RootState } from '@src/store';
import lightOrDark from '@utils/lightOrDark';
import { useSelector } from 'react-redux';

import st from './style.module.css';

interface IProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  group: any;
  backgroundImage?: string;
  backgroundColor?: string;
  isHasWidgets: boolean;
}

const GroupControls = ({ group, backgroundImage, backgroundColor, isHasWidgets }: IProps) => {
  const theme = useTheme();
  const isEditMode = useSelector((state: RootState) => state.settings.isEditMode);

  const colorOfControls = lightOrDark(backgroundColor) === 'light' ? theme.palette.black : theme.palette.white;

  return (
    <div
      data-test-board-container={group.name}
      data-target-empty-group="1"
      className={st.groupContainer}
      style={{
        backgroundImage,
        backgroundColor,
      }}
    >
      {isEditMode && (
        <>
          <Tooltip title={msg.dashboard.deleteGroup}>
            <IconButton
              data-test-group-control="deleteGroup"
              onClick={() => {
                DeleteGroupModal({
                  groupIds: [group.id],
                  name: group.name,
                })
                  .then()
                  .catch(() => {});
              }}
              size="large"
            >
              <DeleteIcon style={{ color: colorOfControls }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={msg.dashboard.editGroup}>
            <IconButton
              data-test-group-control="edit"
              onClick={() => {
                AddGroupModal({
                  object: group,
                }).catch(() => {});
              }}
              size="large"
            >
              <EditIcon style={{ color: colorOfControls }} />
            </IconButton>
          </Tooltip>
          {!isHasWidgets && (
            <Tooltip title={msg.dashboard.addWidget}>
              <IconButton
                data-test-group-control="addWidget"
                onClick={() => {
                  SelectWidget({
                    group,
                  })
                    .then()
                    .catch(() => {});
                }}
                size="large"
              >
                <AddIcon style={{ color: colorOfControls }} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </div>
  );
};

export default GroupControls;
