import { gql } from '@apollo/client';

// the same WIDGET_QUERY
const GET_WIDGET = gql(/* GraphQL */ `
  query getWidget($objId: UUID!, $groupNames: [ObjectPropertyFilter!]) {
    object(id: $objId) {
      id
      name
      description
      schemaName
      enabled
      muted
      userGroupByEditorgroup {
        id
        groupName
      }
      userGroupByUsergroup {
        id
        groupName
      }
      userGroupByReadergroup {
        id
        groupName
      }
      objectProperties(orderBy: [PROPERTY_ASC], filter: { or: $groupNames }) {
        groupName
        property
        id
        spec {
          units
          type {
            name
          }
          key
          description
          hidden
          type {
            name
          }
          valueSet
        }
        key
        value
      }
    }
  }
`);

export { GET_WIDGET };
