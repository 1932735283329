import { IListItem } from '../modules/ListItem';
import formatTime from '../utils/formatTime';

const useNotificationModalData = ({ createdAt, id, tags, message, objectName, userByBy }: any) => {
  const dataStructure: IListItem[] = [
    { name: 'Object', value: objectName },
    { name: 'Sender', value: userByBy.login },
    { name: 'Time', value: formatTime(createdAt as string) },
    { name: 'ID', value: id },
    { name: 'Tags', value: tags },
    { name: 'Message', value: message, valueOnNewLine: true },
  ];

  return {
    data: dataStructure,
  };
};

export default useNotificationModalData;
