import { ReactComponent as ImgBoard } from '@assets/board.svg';
import BulletInBoard from '@components/icons/bulletInBoard';
import useUniversalMenu from '@components/side-card/menu/useUniversalMenu';
import useLongPress from '@components/useLongPress';
import useMoreMenu from '@components/useMoreMenu';
import { Map } from '@mui/icons-material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import { isCollection } from '@utils/objectType';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import st from './style.module.css';

const CustomListItem = (props) => {
  const { item, itemsChecked, setItemsChecked } = props;
  const { dashboardId } = useParams();

  const handleToggle = (value) => {
    const currentIndex = itemsChecked.indexOf(value);
    const newChecked = [...itemsChecked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setItemsChecked(newChecked);
  };

  useEffect(() => {
    setItemsChecked([]);
  }, [dashboardId]);

  // to highlight current object
  const handleMouseOver = () => {
    // dispatch(setContext({ objectHoveredId: e.currentTarget.id }));
  };
  // to highlight current object
  const handleMouseLeave = () => {
    // dispatch(setContext({ objectHoveredId: null }));
  };

  const onLongPress = useLongPress();

  const { menuBasedOnType } = useUniversalMenu();

  const getIconByTags = (item) => {
    if (item?.type?.length) {
      if (item.type[0].value === 'minimap') {
        return <Map />;
      } else {
        return <BulletInBoard />;
      }
    }

    if (isCollection(item.schemaTags)) {
      return <ImgBoard />;
    }

    return <DashboardIcon />;
  };
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  return (
    <>
      <MoreMenu items={() => menuBasedOnType(item)} />
      <ListItem
        data-list-item={item.name}
        key={item.key}
        id={item.id}
        button
        classes={{
          root: st.itemToHover,
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        onContextMenu={(event) => event.preventDefault()}
      >
        <ListItemIcon className={clsx(itemsChecked.indexOf(item.id) === -1 ? st.item3ToHideOrShow : st.item3ToShow)}>
          <Checkbox
            onClick={(e) => {
              e.preventDefault();
              //            handleToggle(index);
              handleToggle(item.id);
            }}
            checked={itemsChecked.indexOf(item.id) !== -1}
            edge="start"
            tabIndex={-1}
            disableRipple
            className={st.checkbox}
            color="primary"
          />
        </ListItemIcon>

        <ListItemAvatar className={clsx(itemsChecked.indexOf(item.id) === -1 ? st.item1ToHideOrShow : st.hidden)}>
          <Avatar>{getIconByTags(item)}</Avatar>
        </ListItemAvatar>

        <Link to={item.link || ''} className={st.link}>
          <ListItemText
            {...onLongPress(() => handleToggle(item.id))}
            primary={item.name}
            secondary={item.enabled ? <span>Enabled</span> : <span>Disabled</span>}
          />
        </Link>

        {itemsChecked.indexOf(item.id) === -1 && (
          <>
            <ListItemSecondaryAction className={st.item2ToHideOrShow}>
              <IconButton edge="end" aria-label="favorite" className={st.hidden} size="large">
                <StarBorderIcon />
              </IconButton>
              <IconButton
                edge="end"
                data-list-item-test={`contextMenu_${item.name}`}
                aria-label="more"
                onClick={(e) => {
                  e.preventDefault();
                  openMoreMenu(e);
                }}
                size="large"
              >
                <MoreVertIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </>
        )}
      </ListItem>
    </>
  );
};

export default CustomListItem;
