import CustomInput from '@components/CustomInput';
import CustomSelect from '@components/CustomSelect';
import CustomSwitch from '@components/CustomSwitch';
import { msg } from '@constants/messages';
import sixtyMinutes from '@constants/sixtyMinutes';
import twentyFourHours from '@constants/twentyFourHours';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import React, { FC, useEffect } from 'react';
import { LIST_OF_TIME_UNITS, OPERATORS_LIST } from './constants';
import { ValuesType } from './type';

type AlarmsType = {
  values: ValuesType;
  setValues: React.Dispatch<any>;
  showAlarm: boolean;
  setAlarmsWeekDays: React.Dispatch<React.SetStateAction<{ alarm1: number[]; alarm2: number[]; alarm3: number[] }>>;
  alarmsWeekDays: { alarm1: number[]; alarm2: number[]; alarm3: number[] };
  setShowAlarm: React.Dispatch<React.SetStateAction<boolean>>;
  isMonitor: boolean;
  alertNumber: number;
};

const daysOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
const week = [0, 1, 2, 3, 4, 5, 6];

const Alarm: FC<AlarmsType> = (props) => {
  const { values, setValues, showAlarm, setAlarmsWeekDays, alarmsWeekDays, setShowAlarm, isMonitor, alertNumber } =
    props;

  const theme = useTheme();

  const handleInputChange = (e) => {
    // eslint-disable-next-line prefer-const
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  function setDefaultTimeout() {
    if (!isMonitor) {
      return {
        [`alarmsAlert${alertNumber}_timeout_value`]: '',
        [`alarmsAlert${alertNumber}_timeout_units`]: '',
      };
    }
    return {};
  }

  useEffect(() => {
    if (!showAlarm) {
      setValues({
        [`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_h`]: '',
        [`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_m`]: '',
        [`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_h`]: '',
        [`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_m`]: '',
        ...setDefaultTimeout(),
      });
      setAlarmsWeekDays({
        ...alarmsWeekDays,
        [`alarm${alertNumber}`]: week,
      });
    } else {
      setAlarmsWeekDays({
        ...alarmsWeekDays,
        [`alarm${alertNumber}`]: week,
      });
    }
  }, [showAlarm]);

  return (
    <>
      <Grid item>
        <Typography
          variant="subtitle2"
          color="primary"
        >{`${msg.editWidgetAlarmsModal.alert} ${alertNumber}`}</Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{
            color: theme.palette.gray1,
          }}
        >
          Alarm condition
        </Typography>
      </Grid>

      <Grid item container>
        <Grid item xs={6} style={{ paddingRight: '10px' }}>
          <CustomSelect
            clearFieldIcon={true}
            name={`alarmsAlert${alertNumber}_condition_operator`}
            list={OPERATORS_LIST}
            value={values[`alarmsAlert${alertNumber}_condition_operator`] ?? ''}
            onChange={handleInputChange}
            data-test-alarm-condition="alarmConditionInput1"
          />
        </Grid>
        <Grid item container xs={6} alignContent="flex-end">
          <CustomInput
            name={`alarmsAlert${alertNumber}_condition_value`}
            label="&nbsp;"
            clearFieldIcon={true}
            value={values[`alarmsAlert${alertNumber}_condition_value`] ?? ''}
            onChange={handleInputChange}
            type="text"
            data-test-alarm-condition="alarmConditionInput2"
          />
        </Grid>
      </Grid>

      <Grid item container justifyContent="space-between" alignItems="center">
        <CustomSwitch
          name={`alarm${alertNumber}TimeIntervalToggle`}
          label="Triggering options"
          value={showAlarm}
          onChange={() => {
            setShowAlarm((prev) => !prev);
          }}
        />
      </Grid>

      {showAlarm && (
        <>
          {!isMonitor && (
            <>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  color="primary"
                  style={{
                    color: theme.palette.gray1,
                  }}
                >
                  Alarm condition met for
                </Typography>
              </Grid>

              <Grid item container>
                <Grid item container xs={6} style={{ paddingRight: '10px', marginTop: '16px' }}>
                  <CustomInput
                    name={`alarmsAlert${alertNumber}_timeout_value`}
                    clearFieldIcon={true}
                    min={0}
                    value={values[`alarmsAlert${alertNumber}_timeout_value`] ?? ''}
                    onChange={handleInputChange}
                    type="number"
                    data-test-alarm-timeout="alarmTimeoutInput1"
                  />
                </Grid>
                <Grid item container xs={6}>
                  <CustomSelect
                    name={`alarmsAlert${alertNumber}_timeout_units`}
                    list={[
                      ...LIST_OF_TIME_UNITS,
                      {
                        value: 'times',
                        title: 'times',
                      },
                    ]}
                    value={values[`alarmsAlert${alertNumber}_timeout_units`] || 'minutes'}
                    onChange={handleInputChange}
                    data-test-alarm-timeout="alarmTimeoutInput2"
                  />
                </Grid>
              </Grid>
            </>
          )}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              style={{
                color: theme.palette.gray1,
              }}
            >
              Schedule
            </Typography>
          </Grid>

          {/* from h,m */}

          <Grid item container>
            <Grid item container xs={2} alignItems="flex-end">
              <Typography
                variant="subtitle2"
                color="primary"
                style={{
                  color: theme.palette.gray1,
                }}
              >
                {msg.editWidgetAlarmsModal.from}
              </Typography>
            </Grid>

            <Grid item xs={5} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name={`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_h`}
                label=""
                list={twentyFourHours}
                value={values[`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_h`] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={5} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name={`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_m`}
                label=""
                list={sixtyMinutes}
                value={values[`alarmsAlert${alertNumber}_timeIntervalInMinutes_from_m`] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          {/* to h,m */}

          <Grid item container>
            <Grid item container xs={2} alignItems="flex-end">
              <Typography
                variant="subtitle2"
                color="primary"
                style={{
                  color: theme.palette.gray1,
                }}
              >
                {msg.editWidgetAlarmsModal.to}
              </Typography>
            </Grid>

            <Grid item xs={5} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name={`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_h`}
                list={twentyFourHours}
                value={values[`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_h`] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>

            <Grid item xs={5} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name={`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_m`}
                list={sixtyMinutes}
                value={values[`alarmsAlert${alertNumber}_timeIntervalInMinutes_to_m`] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item>
            <ToggleButtonGroup
              fullWidth
              color="primary"
              value={alarmsWeekDays[`alarm${alertNumber}`]}
              onChange={(e: any) => {
                const value = +e.target?.value;
                if (alarmsWeekDays[`alarm${alertNumber}`].includes(value)) {
                  setAlarmsWeekDays({
                    ...alarmsWeekDays,
                    [`alarm${alertNumber}`]: alarmsWeekDays[`alarm${alertNumber}`].filter(
                      (item: string | number) => +item !== +value
                    ),
                  });
                } else {
                  setAlarmsWeekDays({
                    ...alarmsWeekDays,
                    [`alarm${alertNumber}`]: [...alarmsWeekDays[`alarm${alertNumber}`], +value],
                  });
                }
              }}
            >
              {daysOfWeek.map((day, index) => (
                <ToggleButton key={index} fullWidth value={index}>
                  {day}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Grid>
        </>
      )}

      {!isMonitor && (
        <Grid item container justifyContent="space-between" alignItems="center">
          <CustomSwitch
            name={`alarmsAlert${alertNumber}_timer_dismiss`}
            label={'Dismissing options'}
            value={values[`alarmsAlert${alertNumber}_timer_dismiss`] ?? false}
            onChange={(e) => {
              handleInputChange(e);
              if (values[`alarmsAlert${alertNumber}_timer_dismiss`]) {
                setValues({
                  [`alarmsAlert${alertNumber}_timer_value`]: '',
                  [`alarmsAlert${alertNumber}_timer_units`]: '',
                });
              }
            }}
          />
        </Grid>
      )}
      {values[`alarmsAlert${alertNumber}_timer_dismiss`] && (
        <Grid item container>
          <Grid item container xs={6} style={{ paddingRight: '10px', marginTop: '16px' }} alignContent="flex-end">
            <CustomInput
              name={`alarmsAlert${alertNumber}_timer_value`}
              clearFieldIcon={true}
              min={0}
              value={values[`alarmsAlert${alertNumber}_timer_value`] ?? ''}
              onChange={handleInputChange}
              type="number"
            />
          </Grid>

          <Grid item xs={6}>
            <CustomSelect
              name={`alarmsAlert${alertNumber}_timer_units`}
              list={[
                ...LIST_OF_TIME_UNITS,
                {
                  value: 'times',
                  title: 'times',
                },
              ]}
              value={values[`alarmsAlert${alertNumber}_timer_units`] || 'minutes'}
              onChange={handleInputChange}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Alarm;
