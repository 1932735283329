export enum WidgetTypesEnum {
  COMMAND_BUTTON = 'command button',
  ADVANCED_BUTTON = 'advanced button',
  MONITOR_TABLE = 'monitor table',
  MONITOR_STATUS = 'monitor status',
  HISTORY_TABLE = 'history table',
  STATIC_TABLE = 'static table',
  TRACKING_TABLE = 'tracking table',
  PROPERTY_HISTORY_TABLE = 'property history table',
  GEOTAGS_TABLE = 'geotags table',
  DATABOX = 'databox',
  GEO_TIMER = 'geo timer',
  DATACHART = 'datachart',
  DATACHART_COLOR = 'color datachart',
  DIAGRAM = 'diagram',
  DATETIME = 'datetime',
  TITLE = 'title',
  TIMER = 'timer',
  IMAGEBOX = 'imagebox',
  LINE = 'line',
}

const WIDGETS_ENUM = {
  COMMAND_BUTTON: WidgetTypesEnum.COMMAND_BUTTON,
  ADVANCED_BUTTON: WidgetTypesEnum.ADVANCED_BUTTON,
  MONITOR_TABLE: WidgetTypesEnum.MONITOR_TABLE,
  MONITOR_STATUS: WidgetTypesEnum.MONITOR_STATUS,
  HISTORY_TABLE: WidgetTypesEnum.HISTORY_TABLE,
  STATIC_TABLE: WidgetTypesEnum.STATIC_TABLE,
  TRACKING_TABLE: WidgetTypesEnum.TRACKING_TABLE,
  DATABOX: WidgetTypesEnum.DATABOX,
  LINE: WidgetTypesEnum.LINE,
  GEO_TIMER: WidgetTypesEnum.GEO_TIMER,
  DATACHART: WidgetTypesEnum.DATACHART,
  DATACHART_COLOR: WidgetTypesEnum.DATACHART_COLOR,
  DIAGRAM: WidgetTypesEnum.DIAGRAM,
  DATETIME: WidgetTypesEnum.DATETIME,
  TITLE: WidgetTypesEnum.TITLE,
  TIMER: WidgetTypesEnum.TIMER,
  IMAGEBOX: WidgetTypesEnum.IMAGEBOX,
  GEOTAGS_TABLE: WidgetTypesEnum.GEOTAGS_TABLE,
  PROPERTY_HISTORY_TABLE: WidgetTypesEnum.PROPERTY_HISTORY_TABLE,
};

const WIDGETS_FOR_BOARD = [
  { value: WidgetTypesEnum.TITLE, title: 'Title' },
  { value: WidgetTypesEnum.DATETIME, title: 'Date Time' },
  { value: WidgetTypesEnum.DATABOX, title: 'DataBox' },
  { value: WidgetTypesEnum.COMMAND_BUTTON, title: 'Command Button' },
  { value: WidgetTypesEnum.ADVANCED_BUTTON, title: 'Advanced Button' },
  { value: WidgetTypesEnum.DATACHART, title: 'Data Chart' },
  { value: WidgetTypesEnum.DATACHART_COLOR, title: 'Data Chart Color' },
  { value: WidgetTypesEnum.STATIC_TABLE, title: 'Static table' },
  { value: WidgetTypesEnum.MONITOR_TABLE, title: 'Monitor Table' },
  { value: WidgetTypesEnum.MONITOR_STATUS, title: 'Monitor Status Table' },
  { value: WidgetTypesEnum.DIAGRAM, title: 'Data Diagram (Demo)' },
  { value: WidgetTypesEnum.HISTORY_TABLE, title: 'History Table (Demo)' },
  { value: WidgetTypesEnum.LINE, title: 'Line' },
];

const WIDGETS_FOR_REPORT = [
  { value: WidgetTypesEnum.TITLE, title: 'Title' },
  { value: WidgetTypesEnum.GEO_TIMER, title: 'Geo timer', isMinimapOnly: true },
  { value: WidgetTypesEnum.STATIC_TABLE, title: 'Static table' },
  { value: WidgetTypesEnum.TRACKING_TABLE, title: 'Tracking table' },
  { value: WidgetTypesEnum.PROPERTY_HISTORY_TABLE, title: 'Property history table' },
  { value: WidgetTypesEnum.GEOTAGS_TABLE, title: 'Geotags table' },
  { value: WidgetTypesEnum.ADVANCED_BUTTON, title: 'Advanced Button' },
  { value: WidgetTypesEnum.COMMAND_BUTTON, title: 'Command Button' },
  { value: WidgetTypesEnum.LINE, title: 'Line' },
];

export { WIDGETS_FOR_REPORT, WIDGETS_FOR_BOARD, WIDGETS_ENUM };
