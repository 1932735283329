import { useLazyQuery, useQuery } from '@apollo/client';
import { RPCS_QUERY } from '@modules/rpcs/api/GetRpc';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { IFormValues } from '@modules/widgets/forms/CommandButton/EditControlModal/constants/formik';
import { FieldProps } from 'formik';
import { useEffect } from 'react';

const useControl = (form: FieldProps<IFormValues>['form']) => {
  const { data } = useQuery(GET_WIDGET, {
    variables: { objId: form?.values?.widgetId },
  });

  const [getRpcs, { data: rpcsList, error }] = useLazyQuery(RPCS_QUERY);

  const action = data?.object?.objectProperties?.find((obj) => obj.key === form.values.propKey)?.value;

  const rpcsListFormated = rpcsList?.object?.schema?.schemaControls
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    ?.filter((item) => item?.isRpc)
    ?.map((item) => {
      return { value: item.rpc, title: item.description, schemaId: item.schemaId };
    });

  useEffect(() => {
    if (!action) return;
    getRpcs({
      variables: { deviceId: action.objectId },
      fetchPolicy: 'network-only',
    });
  }, [action]);

  return { error, rpcsListFormated, action };
};

export default useControl;
