import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import { WIDTH_OPTIONS } from '@modules/widgets/forms/LineWidgetModal/constants/constants';

const Width: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <Grid item md={6} xs={12}>
      <CustomSelect
        type={'number'}
        {...fieldRequiredProps(form, validationSchema, 'width')}
        label="Width"
        list={WIDTH_OPTIONS}
      />
    </Grid>
  );
};

export default Width;
