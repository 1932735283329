import { IFormValues } from '../constants/formik';

type TProps = IFormValues['parameters'];

const arrayToObject = (arr: TProps): Record<string, string> =>
  arr.reduce((acc, item) => {
    acc[item.argument] = item.value;
    return acc;
  }, {} as Record<string, string>);

export default arrayToObject;
