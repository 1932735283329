import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';

import { EAlarmStatuses } from '../../constants';
import st from './style.module.css';

const alarmIcons = {
  [EAlarmStatuses.ON]: <NotificationsIcon fontSize="inherit" />,
  [EAlarmStatuses.TRIGGERED]: <NotificationsActiveIcon fontSize="inherit" />,
};

interface IProps {
  statusesAlarm?: ObjectProperty;
}

const AlarmStatus = ({ statusesAlarm }: IProps) => {
  const theme = useTheme();
  const status = statusesAlarm.value || statusesAlarm;

  if (!alarmIcons[status]) return null;

  return (
    <span className={st.icon} style={{ color: status === EAlarmStatuses.TRIGGERED ? theme.palette.red : theme.palette.gray1 }}>
      {alarmIcons[status]}
    </span>
  );
};

export default AlarmStatus;
