import { gql, useLazyQuery, useMutation } from '@apollo/client';
import EditObjectPropertyGroup from '@components/EditObjectPropertyGroup';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import DateModal from '@components/modals/DateModal';
import EditDevice from '@components/modals/EditDevice';
import useRoute from '@hooks/useRoute';
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from '@modules/reports/api/ReportsList';
import { setSettings } from '@store/settingsSlice';
import downloadNotifications from '@utils/downloadNotifications';
import grouping from '@utils/groupProperties';
import hideIfEmpty from '@utils/hideIfEmpty';
import { loader } from 'graphql.macro';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteObjectModal from '../../DeleteObjectModal';
import ChangeAccessModal from '../../modals/ChangeAccessModal/ChangeAccessModal';

import useHandleCopy from './handlers/useHandleCopy';
import useMenu from './useMenu';

const UNLINK_OBJECT = loader('../../../graphql/UnlinkObject.graphql');

// TODO: Need to extract to file
const DASHBOARDS_QUERY_WITHOUT_COLLECTIONS = gql(/* GraphQL */ `
  query getDashboardsWithoutCollections {
    dashboards: objects(
      filter: {
        schemaTags: { contains: ["application", "board", "dashboard"] }
        objectsToObjectsByObject1IdConnection: {
          every: { object2: { not: { schemaTags: { contains: ["collection"] } } } }
        }
      }
      orderBy: NAME_ASC
    ) {
      id
      name
    }
  }
`);

const UPDATE_OBJECT = gql`
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`;

export default function useObjectMenu() {
  const dispatch = useDispatch();
  const items = useMenu();
  const handleCopy = useHandleCopy();
  const [unlinkObject] = useMutation(UNLINK_OBJECT);
  const navigate = useCustomNavigate();
  const { getParamsByRoute } = useRoute();
  const { collectionId } = useParams();
  const [updateObject] = useMutation(UPDATE_OBJECT);

  const [dashboardsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const getMenu = (object) => {
    if (!object) {
      return [];
    }
    const groups = grouping(object.objectProperties, 'groupName');
    const groupNames = Object.keys(groups);

    return [
      items.genMenuEditItem({
        handleAction: () => {
          EditDevice({
            object,
          })
            .then()
            .catch(() => {});
        },
      }),
      items.genMenuAccess({
        handleAction: () => {
          ChangeAccessModal({ object }).then();
        },
      }),
      items.getMenuDisableItem({
        enabled: !!object?.enabled,
        handleAction: async () => {
          await updateObject({
            variables: {
              input: {
                id: object.id,
                patch: {
                  enabled: !object?.enabled,
                },
              },
            },
          });
        },
      }),
      items.genMenuSendItem({
        disabled: true,
      }),
      items.genMenuShowHistory({
        handleAction: () => {
          const { dashboardId, reportId, groupId, widgetId } = getParamsByRoute();

          dispatch(setSettings({ isShowHistory: true, historyPropertyId: '' }));

          if (dashboardId) {
            navigate(`/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`);
          }

          if (reportId) {
            navigate(`/reports/${reportId}/${groupId}/${widgetId}/${object.id}`);
          }
        },
      }),
      items.genMenuShowControlsHistory({
        handleAction: () => {
          const { dashboardId, reportId, groupId, widgetId } = getParamsByRoute();

          dispatch(setSettings({ isShowControlsHistory: true }));

          if (dashboardId) {
            navigate(`/boards/${dashboardId}/${groupId}/${widgetId}/${object.id}`);
          }

          if (reportId) {
            navigate(`/reports/${reportId}/${groupId}/${widgetId}/${object.id}`);
          }
        },
      }),
      items.genMenuDownloadNotifications({
        handleAction: () => {
          DateModal({
            downloadHistory: downloadNotifications,
            downloadIds: [object.id],
          })
            .then()
            .catch(() => {});
        },
      }),
      {
        id: 'divider',
      },
      ...(() =>
        groupNames.map((groupName) =>
          items.genMenuEditPropertyGroup({
            id: `edit-${groupName}`,
            title: `Edit ${groupName}`,
            handleAction: async () => {
              EditObjectPropertyGroup({
                group: groups[groupName],
                widgetId: object.id,
              }).catch(() => {});
            },
          })
        ))(),
      {
        id: 'divider',
      },
      items.genCopyUUID({
        handleAction: () => handleCopy({ object }),
      }),
      items.genMenuDelete({
        disabled: false,
        handleAction: async () => {
          await DeleteObjectModal({
            objectId: object.id,
          });
        },
      }),

      ...hideIfEmpty(object.linkId, [
        items.genUnlinkItem({
          handleAction: () => {
            toast
              .promise(
                unlinkObject({
                  variables: {
                    linkId: object.linkId,
                  },
                }).then(() => {
                  if (collectionId) {
                    return Promise.allSettled([dashboardsLazyQuery(), dashboardsWithoutCollectionsLazyQuery()]);
                  } else {
                    return Promise.resolve();
                  }
                }),
                {
                  loading: 'Unlinking object...',
                  success: () => 'Object unlinked',
                  error: (err) => `${err.toString()}`,
                }
              )
              .then(() => {});
          },
        }),
      ]),
    ];
  };

  return {
    getMenu,
  };
}
