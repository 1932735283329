import LabelIcon from '@components/icons/labelIcon';
import LinkIcon from '@components/icons/LinkIcon';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import { ColorLens, LinkOff, Notes } from '@mui/icons-material';
import ExtensionIcon from '@mui/icons-material/Extension';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import tabStyle from '@components/side-card/tab.module.css';

const ColorChartGeneralTab = (props) => {
  const { item } = props;

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  // Chart 1
  const chart1Property = () => getPropertyByKey('chart1Property');
  const chart1Source = () => getPropertyByKey('chart1Source');
  const chart1ReadyTimeseries = () => getPropertyByKey('chart1UseReady-MadeTimeseries');
  const styleChart1Color = () => getPropertyByKey('styleChart1Color');
  const styleChart1Label = () => getPropertyByKey('styleChart1Label');

  return (
    <>
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Chart 1</Typography>
        </ListSubheader>

        {/*Chart 1*/}

        {!chart1ReadyTimeseries().value && (
          <>
            <ListItem style={{ height: '48px' }}>
              <ListItemIcon>
                {chart1Source().value.value === null && <LinkOff />}
                {chart1Source().value.value !== null && <ExtensionIcon />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap title={chart1Source().value.title} variant="body1">
                    Object: {chart1Source().value.title}
                  </Typography>
                }
              />
            </ListItem>

            <ListItem style={{ height: '48px' }}>
              <ListItemIcon>
                <LabelIcon></LabelIcon>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography noWrap variant="body1">
                    Property: {chart1Property().value.title}
                  </Typography>
                }
              />
            </ListItem>
          </>
        )}

        {chart1ReadyTimeseries().value && (
          <ListItem style={{ height: '48px' }}>
            <ListItemIcon>
              {chart1Source().value.value === null && <LinkOff />}
              {chart1Source().value.value !== null && <LinkIcon />}
            </ListItemIcon>
            <ListItemText
              noWrap
              title={chart1Source().value.title}
              primary={<Typography variant="body1">Dataset: {chart1Source().value.title}</Typography>}
            />
          </ListItem>
        )}

        <ListItem style={{ height: '48px' }}>
          <ListItemIcon>
            <ColorLens></ColorLens>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Color: {styleChart1Color().value}</Typography>} />
        </ListItem>

        <ListItem style={{ height: '48px', paddingRight: '96px' }}>
          <ListItemIcon>
            <Notes></Notes>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Label: {styleChart1Label().value || 'n/a'}</Typography>} />
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default ColorChartGeneralTab;
