import CommonModal from '@components/CommonModal';
import { msg } from '@constants/messages';
import Grid from '@mui/material/Grid';
import { ObjectProperty } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { create, InstanceProps } from 'react-modal-promise';

import DynamicFiled from './components/DynamicField';
import useForm from './hooks/useForm';

interface IProps {
  group: ObjectProperty[];
  widgetId: string;
}

const EditObjectPropertyGroup = ({ group, widgetId, isOpen, onResolve, onReject }: IProps & InstanceProps<{}>) => {
  const { formik, savingLoading, properties } = useForm({ onResolve, group, widgetId });

  return (
    <CommonModal
      isForm={true}
      formik={formik}
      loading={savingLoading}
      modalOpen={isOpen}
      title={msg.editWidgetPropertyModal.editProperty}
      handleClose={onReject}
    >
      <Grid container direction="column" spacing={2}>
        {properties?.map((property) => {
          return (
            <Grid item key={property.id}>
              <FastField name={property.key} property={property} widgetId={widgetId} component={DynamicFiled} />
            </Grid>
          );
        })}
      </Grid>
    </CommonModal>
  );
};

export default create(EditObjectPropertyGroup);
