import { gql, useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { DASHBOARD_STREAM_DATA_TIME } from '@constants/constants';
import { msg } from '@constants/messages';
import { WIDGET_QUERY } from '@graphql/queries';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import { useFormik } from 'formik';
import { loader } from 'graphql.macro';
import { useEffect, useReducer, useState } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import * as yup from 'yup';
import CommonModal from '../components/CommonModal';
import CustomInput from '../components/CustomInput';
import useObjectProperty from '../hooks/useObjectProperty';
import useRoute from '../hooks/useRoute';
import CustomAutocomplete from './CustomAutocomplete';
import CustomSelect from './CustomSelect';
import CustomSelectColor from './CustomSelectColor';
import CustomSwitch from './CustomSwitch';
import FormField from './FormField';

const UPDATE_OBJECT_PROPERTY = loader('../graphql/UpdateObjectWithProperties.graphql');
const GET_OBJECT = loader('../graphql/GetObjectQuery.graphql');
const UPDATE_PROPERTY = gql`
  mutation updateObjectProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_PROPERTIES_BY_OBJECT = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      schemaTags
      objectsToObjectsByObject1Id(
        filter: {
          forced: { equalTo: 1 }
          object2: { schemaTags: { equalTo: ["application", "monitor", "object monitoring item"] } }
        }
      ) {
        object2 {
          id
          name
          schemaTags
          objectProperties(orderBy: [PROPERTY_ASC]) {
            id
            groupName
            property
            value
            key
            linkedPropertyId
          }
        }
      }
      objectProperties(
        orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]
        filter: { typeId: { in: ["00000000-0000-0000-0000-000000000002", "00000000-0000-0000-0000-000000000003"] } }
      ) {
        id
        spec {
          description
          property
          groupName
        }
      }
    }
  }
`;

const ONLY_TIMESERIES = gql`
  query getObject($objectId: UUID!) {
    object(id: $objectId) {
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC], filter: { key: { equalTo: "valueTimeseries" } }) {
        id
        spec {
          description
          property
          groupName
        }
      }
    }
  }
`;

const EditWidgetColorChartModal = (props) => {
  const theme = useTheme();
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);
  const [updateOneProperty] = useMutation(UPDATE_PROPERTY);
  const client = useApolloClient();
  const chart1ObjectProperty = useObjectProperty(GET_PROPERTIES_BY_OBJECT);
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const allowed = {
    hourly: [
      'lasthour',
      'last12hours',
      'lastday',
      'last8weeks',
      'lastweek',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    daily: [
      'lastday',
      'lastweek',
      'last8weeks',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    weekly: [
      'lastweek',
      'last8weeks',
      'lastmonth',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    none: [
      'lasthour',
      'last12hours',
      'lastday',
      'lastweek',
      'lastmonth',
      'last8weeks',
      'lastyear',
      'calendarMonth',
      'calendarWeekMonday',
      'calendarWeekSunday',
    ],
    monthly: ['lastmonth', 'last8weeks', 'lastyear', 'calendarMonth'],
  };

  const isAllowToSelectFunction = (timeInterval, currentGroupBy) => {
    if (!timeInterval || !currentGroupBy) {
      return true;
    }
    return !allowed[currentGroupBy].includes(timeInterval);
  };

  const [fields, setFields] = useState([]);
  const [loadObject] = useLazyQuery(
    GET_OBJECT,
    {
      variables: { objectId: props.widgetId },
    },
    {
      fetchPolicy: 'network-only',
    }
  );

  let defaultValues = {
    chart1_color_list: [
      {
        title: 'Default',
        value: theme.palette.chartColors.default,
      },
      {
        title: 'Gray',
        value: theme.palette.chartColors.gray,
      },
      {
        title: 'Green',
        value: theme.palette.chartColors.green,
      },
      {
        title: 'Orange',
        value: theme.palette.chartColors.orange,
      },
      {
        title: 'Red',
        value: theme.palette.chartColors.red,
      },
    ],
  };

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  const { getParamsByRoute } = useRoute();

  const { dashboardId } = getParamsByRoute();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const [periods, setPeriods] = useState([]);
  const [groupingFunctions, setGroupingFunctions] = useState([]);

  const conditionSchema = yup.object({
    min: yup.number().nullable(true).label('Min'),
    max: yup
      .number()
      .nullable(true)
      .label('Max')
      .when('min', (v, schema) => {
        if (typeof v === 'number') {
          return schema.required('Max value required').moreThan(v);
        } else {
          return schema.transform((_, val) => (val === Number(val) ? val : null));
        }
      }),
  });

  const validationSchema = yup.object({
    condition1: conditionSchema,
    condition2: conditionSchema,
    condition3: conditionSchema,
    condition4: conditionSchema,
  });

  const formik = useFormik({
    initialValues: {
      condition1: {
        min: null,
        color: null,
        max: null,
      },
      condition2: {
        min: null,
        color: null,
        max: null,
      },
      condition3: {
        min: null,
        color: null,
        max: null,
      },
      condition4: {
        min: null,
        color: null,
        max: null,
      },
    },
    validationSchema: validationSchema,
    onSubmit: (valuesForm, actions) => {
      let countErrors = 0;

      const checkIsValid = (v) => {
        if (v === '') {
          return false;
        }

        return v !== null;
      };

      [1, 2, 3, 4].forEach((i) => {
        if (checkIsValid(valuesForm[`condition${i}`].max) && !checkIsValid(valuesForm[`condition${i}`].min)) {
          countErrors += 1;
          actions.setFieldError(`condition${i}.min`, 'Min value required');
        }
      });

      const getSource = (source, property) => {
        if (property.fragmentId) {
          return {
            value: property.fragmentId,
            objectId: source.value,
            title: source.title,
          };
        }
        return source;
      };

      if (!countErrors) {
        const valuesReady1 = [
          {
            propertyKey: 'styleY-AxisLabel',
            value: values.chart_yLabel,
          },
          {
            propertyKey: 'settingsTimeInterval',
            value: values.settingsTimeInterval,
          },
          {
            propertyKey: 'styleChart1Label',
            value: values.chart1_label,
          },
          {
            propertyKey: 'chart1Source',
            value: getSource(values.chart1_source, values.chart1_property),
          },
          {
            propertyKey: 'chart1UseReady-MadeTimeseries',
            value: values.chart1_ready_ts,
          },
          {
            propertyKey: 'chart1Property',
            value: values.chart1_property,
          },
          {
            propertyKey: 'styleChart1Color',
            value: values.chart1_color,
          },
          {
            propertyKey: 'chart1GroupBy',
            value: values.chart1GroupBy,
          },
          {
            propertyKey: 'chart1GroupingFunction',
            value: values.chart1GroupingFunction,
          },
          {
            propertyKey: 'chart1Conditions',
            value: valuesForm,
          },
        ];
        const linkPropertiesPromises = [];

        linkPropertiesPromises.push(
          updateOneProperty({
            variables: {
              input: {
                id: values.propertyValueId1,
                patch: {
                  linkedPropertyId: values.chart1_ready_ts ? values.chart1_property.value : null,
                },
              },
            },
          })
        );

        toast
          .promise(
            Promise.resolve()
              .then(() =>
                updateProperty({
                  variables: {
                    input: {
                      detailedObject: [
                        {
                          objectId: props.widgetId,
                          keyedProperties: valuesReady1,
                        },
                      ],
                    },
                  },
                })
              )
              .then(() => {
                return createExecution({
                  variables: {
                    input: {
                      controlExecution: {
                        name: 'StreamDataToDashboard',
                        objectId: dashboardId,
                        params: {
                          initialize_data: true,
                          stream_data_for: DASHBOARD_STREAM_DATA_TIME,
                        },
                      },
                    },
                  },
                });
              }),
            {
              loading: 'Updating charts...',
              success: () => `Charts updated`,
              error: (err) => `${err.toString()}`,
            }
          )
          .then(() => {
            return Promise.allSettled(linkPropertiesPromises);
          })
          .then(() => {
            return loadObject();
          })
          .then(() => {
            submit();
          });
      }
    },
  });

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: 'network-only',
        });

        const getPropValue = (prop) => result.data.object.objectProperties.find((obj) => obj.key === prop)?.value;
        const getPropByKey = (prop) => result.data.object.objectProperties.find((obj) => obj.key === prop);

        setPeriods(
          getPropByKey('chart1GroupBy').spec.valueSet.list.map((item) => ({
            title: item.title,
            value: item.key,
          }))
        );

        setGroupingFunctions(
          getPropByKey('chart1GroupingFunction').spec.valueSet.list.map((item) => ({
            title: item.title,
            value: item.key,
          }))
        );

        const getSource = (source) => {
          if (source.objectId) {
            return {
              title: source.title,
              value: source.objectId,
            };
          }
          return source;
        };
        setFields([
          getPropByKey('settingsTimeInterval'),
          // getPropByKey("settingsGroupBy"),
        ]);

        defaultValues['chart_yLabel'] = getPropValue('styleY-AxisLabel');
        defaultValues['settingsTimeInterval'] = getPropValue('settingsTimeInterval');

        defaultValues['chart1_label'] = getPropValue('styleChart1Label');
        defaultValues['chart1Conditions'] = getPropValue('chart1Conditions');
        defaultValues['chart1_color'] = getPropValue('styleChart1Color');
        defaultValues['chart1_source'] = getSource(getPropValue('chart1Source'));
        defaultValues['chart1_property'] = getPropValue('chart1Property');
        defaultValues['chart1_property_id'] = getPropByKey('chart1Property').id;
        defaultValues['chart1_ready_ts'] = getPropValue('chart1UseReady-MadeTimeseries');
        defaultValues['chart1GroupBy'] = getPropValue('chart1GroupBy');
        defaultValues['chart1GroupingFunction'] = getPropValue('chart1GroupingFunction');
        defaultValues['propertyValueId1'] = getPropByKey('valueChart1').id;
        loadObjects(getPropValue('chart1UseReady-MadeTimeseries'));

        // chart1ObjectProperty.objectsQuery(defaultValues["chart1_ready_ts"]);
        if (defaultValues['chart1_source'].value) {
          chart1ObjectProperty.propertiesQuery({
            variables: {
              objectId: defaultValues['chart1_source']?.objectId || defaultValues['chart1_source'].value,
            },
          });
        }
        formik.setValues({
          condition1: values.chart1Conditions.condition1,
          condition2: values.chart1Conditions.condition2,
          condition3: values.chart1Conditions.condition3,
          condition4: values.chart1Conditions.condition4,
        });
        setValues(defaultValues); // loaded values for each field
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps().then(() => {});
  }, []);

  useEffect(() => {
    if (values) {
      if (values.chart1GroupBy === 'none' && values.chart1GroupingFunction !== null) {
        setValues({ chart1GroupingFunction: null });
      }
    }
  }, [values]);

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;
    if (typeof checked !== 'undefined') value = checked;
    setValues({ [name]: value });
  };

  const handleInputRaw = (e) => {
    let { name, rawValue } = e.target;
    setValues({ [name]: rawValue || { title: 'n/a', value: null } });
  };

  const loadObjects = (timeseriesReady) => {
    if (timeseriesReady) {
      chart1ObjectProperty.setPropertiesGql(ONLY_TIMESERIES);
      chart1ObjectProperty
        .objectsQuery({
          variables: {
            filter: {
              schemaTags: {
                contains: ['timeseries'],
              },
            },
          },
        })
        .then(() => {});
    } else {
      chart1ObjectProperty.setPropertiesGql(GET_PROPERTIES_BY_OBJECT);
      chart1ObjectProperty
        .objectQuery({
          variables: {
            objectId: props.widgetId,
          },
        })
        .then(() => {});
    }
  };

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.EditWidgetColorChartModal.editCharts}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button color="primary" onClick={formik.handleSubmit}>
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {fields.map((field) => {
            return (
              <Grid item key={field.key}>
                <FormField
                  name={field.key}
                  value={values[field.key] ?? ''}
                  onChange={handleInputChange}
                  field={field.spec}
                />
              </Grid>
            );
          })}

          <Grid item>
            <CustomInput
              name={`chart_yLabel`}
              label={'Y-axis label'}
              clearFieldIcon={true}
              value={values[`chart_yLabel`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">{`${msg.EditWidgetColorChartModal.chart} 1`}</Typography>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="chart1_ready_ts"
              label={'Use ready-made timeseries'}
              value={values['chart1_ready_ts'] ?? ''}
              onChange={(e) => {
                loadObjects(e.target.checked);
                handleInputChange(e);
              }}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart1_source"
              label="Source"
              list={chart1ObjectProperty.objects}
              disabled={chart1ObjectProperty.objectsLoading}
              loading={chart1ObjectProperty.objectsLoading}
              value={values[`chart1_source`]?.value ?? ''}
              onChange={(e) => {
                chart1ObjectProperty
                  .propertiesQuery({
                    variables: {
                      objectId: e.target.value,
                    },
                  })
                  .then(() => {});
                handleInputRaw(e);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              name="chart1_property"
              label={values['chart1_ready_ts'] ? 'Timeseries' : 'Property'}
              list={chart1ObjectProperty.properties}
              disabled={chart1ObjectProperty.propertiesLoading || chart1ObjectProperty.objectsLoading}
              loading={chart1ObjectProperty.propertiesLoading || chart1ObjectProperty.objectsLoading}
              value={values[`chart1_property`]?.value ?? ''}
              onChange={handleInputRaw}
            />
          </Grid>

          {!values['chart1_ready_ts'] && (
            <>
              <Grid item>
                <CustomSelect
                  disabled={values['chart1_ready_ts']}
                  name="chart1GroupBy"
                  label="Group by"
                  value={values['chart1GroupBy'] ?? ''}
                  list={periods.map((item) => ({
                    ...item,
                    disabled: isAllowToSelectFunction(values['settingsTimeInterval'], item?.value),
                  }))}
                  onChange={handleInputChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  disabled={values['chart1GroupBy'] === 'none'}
                  name="chart1GroupingFunction"
                  label="Grouping function"
                  value={values['chart1GroupingFunction'] ?? ''}
                  list={groupingFunctions}
                  onChange={handleInputChange}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <CustomInput
              name="chart1_label"
              propType={'string'}
              label={msg.EditWidgetColorChartModal.label}
              clearFieldIcon={true}
              value={values[`chart1_label`] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          {values.chart1Conditions &&
            [1, 2, 3, 4].map((i) => (
              <>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    color="primary"
                    style={{
                      marginTop: '15px',
                    }}
                  >{`Value range ${i}`}</Typography>
                </Grid>

                <Grid item>
                  <CustomSelectColor
                    name={`condition${i}.color`}
                    clearFieldIcon={true}
                    colors={['default', 'red', 'deepRed', 'blue', 'green', 'yellow', 'yellowGreen', 'orange']}
                    label="Color"
                    value={formik.values[`condition${i}`]?.color}
                    onChange={formik.handleChange}
                    data-test-color-object="objectColor"
                  />
                </Grid>

                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <CustomInput
                      allowNegative={true}
                      name={`condition${i}.min`}
                      label="Min"
                      type="number"
                      value={formik.values[`condition${i}`]?.min}
                      clearFieldIcon={true}
                      error={formik.touched?.[`condition${i}`]?.min && Boolean(formik.errors?.[`condition${i}`]?.min)}
                      helperText={formik.touched?.[`condition${i}`]?.min && formik.errors?.[`condition${i}`]?.min}
                      onChange={formik.handleChange}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <CustomInput
                      allowNegative={true}
                      name={`condition${i}.max`}
                      label="Max"
                      type="number"
                      value={formik.values[`condition${i}`]?.max}
                      clearFieldIcon={true}
                      error={formik.touched?.[`condition${i}`]?.max && Boolean(formik.errors?.[`condition${i}`]?.max)}
                      helperText={formik.touched?.[`condition${i}`]?.max && formik.errors?.[`condition${i}`]?.max}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                </Grid>
              </>
            ))}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditWidgetColorChartModal);
