import { useQuery } from '@apollo/client';
import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import CustomSelect from '../../../../../../components/CustomSelect';
import { GroupType } from '../../../../../../components/modals/ChangeAccessModal/type';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { LOAD_GROUPS } from '../../../../../groups/api/GetGroups';
import { IFormValues, validationSchema } from '../../constants/formik';

const defaultErrorText = {
  error: true,
  helperText: 'Failed to load user groups',
};

const UsersGroup: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { data, error } = useQuery(LOAD_GROUPS);

  const usersList: GroupType[] =
    data?.userGroups
      ?.map((group: { groupName: string; id: string }) => ({
        title: group.groupName,
        value: group.id,
        id: group.id,
        disabled: false,
      }))
      .sort((a, b) => a.title?.localeCompare(b.title)) || [];

  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'usersGroup')}
        {...(error ? defaultErrorText : null)}
        label="Default users group"
        list={usersList}
      />
    </Grid>
  );
};

export default UsersGroup;
