import CustomInput from '@components/CustomInput';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const Description: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid container direction="column" spacing={2} item>
    <Grid item>
      <Typography variant="subtitle2" color="primary">
        Description
      </Typography>
    </Grid>
    <Grid item>
      <CustomInput
        {...fieldRequiredProps(form, validationSchema, 'description')}
        autoComplete="description"
        label={'Description'}
        clearFieldIcon={true}
      />
    </Grid>
  </Grid>
);

export default Description;
