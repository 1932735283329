import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { dispatch as dispatchBus } from 'use-bus';
import * as yup from 'yup';
import CommonModal from '../../../components/CommonModal';
import { msg } from '../../../constants/messages';
import {
  CHART_TYPE_OPTIONS,
  LINE_WIDTH_OPTIONS,
  SETTINGS_SIZE_OPTIONS,
  SETTINGS_STYLE_OPTIONS,
  SMOOTH_TYPE_OPTIONS,
  TypeOptionsEnum,
} from '../../../utils/constants/selectOptions';
import { getPropertyValueByKey } from '../../../utils/getPropertyByKey';
import { WIDGETS_ENUM } from '../../../utils/widgetTypes';
import CustomInput from '../../CustomInput';
import CustomSelect from '../../CustomSelect';
import CustomSwitch from '../../CustomSwitch';
import { TRegularChartProps } from '../types';
import { useAddWidget } from '../useAddWidget';
import { FORM_DEFAULT_VALUES, ScaleOptionsEnum, SCALE_OPTIONS } from './constants';

const RegularChart = (props: TRegularChartProps & InstanceProps<{}>) => {
  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  const { createWidgetFn, updateWidget, isLoading } = useAddWidget({
    group: props.group,
    widgetType: WIDGETS_ENUM.DATACHART,
    cb: submit,
  });

  const isEdit = props?.widget?.id !== undefined;

  const getPrevValues = () => {
    const defaultValues = {
      ...FORM_DEFAULT_VALUES,
    };

    if (props?.widget?.objectProperties) {
      for (const property in FORM_DEFAULT_VALUES) {
        defaultValues[property] = getPropertyValueByKey(props.widget.objectProperties, property);
      }
    }

    return defaultValues;
  };

  const validationSchema = yup.object({
    name: yup
      .string()
      .trim()
      .test('name', 'Name is required', (value) => !isEdit || (value && value.trim() !== '')),
    properties: yup.object({
      settingsYaxisScale: yup.string().required('Type is required'),
      settingsMinimum: yup.number().when('settingsYaxisScale', {
        is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
        then: yup.number().required('Min is required'),
        otherwise: yup.number().notRequired(),
      }),
      settingsMaximum: yup.number().when('settingsYaxisScale', {
        is: (type: ScaleOptionsEnum) => type === ScaleOptionsEnum.fixed,
        then: yup.number().required('Max is required').moreThan(yup.ref('settingsMinimum'), 'Must be greater than min'),
        otherwise: yup.number().notRequired(),
      }),
    }),
  });

  const formik = useFormik({
    initialValues: {
      description: isEdit ? props.widget.description : '',
      name: props.name,
      properties: {
        ...getPrevValues(),
      },
    },
    validationSchema,
    onSubmit: ({ properties, name, description }) => {
      if (isEdit) {
        updateWidget({
          values: properties,
          defaultValues: { ...getPrevValues() },
          id: props.widget.id,
          name,
          description,
          cb: submit,
        });
      } else {
        createWidgetFn({
          values: properties,
          name,
          description,
          cb: submit,
        });
      }
    },
  });

  const isLineChart = () => {
    return formik.values.properties.settingsChartType === TypeOptionsEnum.line;
  };

  const isFixedScaleYAxis = () => {
    return formik.values.properties.settingsYaxisScale === ScaleOptionsEnum.fixed;
  };

  useEffect(() => {
    if (isEdit) {
      dispatchBus('@@board/SAVE_BOARD');
    }
  }, []);

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={isEdit ? 'Edit widget' : 'Add widget'}
        handleClose={reject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-chart" color="inherit" onClick={reject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button
              disabled={isLoading}
              data-test="save-regular-chart"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          {isEdit && (
            <>
              <Grid item>
                <CustomInput
                  required={isEdit}
                  name="name"
                  label="Name"
                  clearFieldIcon={true}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.errors.name)}
                  helperText={formik.errors.name}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Content
            </Typography>
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsTitle"
              label="Title"
              value={formik.values.properties.settingsTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsTitleSecondary"
              label="Subtitle"
              value={formik.values.properties.settingsTitleSecondary}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item container justifyContent="space-between" alignItems="center">
            <CustomSwitch
              name="properties.settingsSimulation"
              label="Simulation"
              value={formik.values.properties.settingsSimulation}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsYaxisScale"
              label="Y-axis scale"
              list={SCALE_OPTIONS}
              value={formik.values.properties.settingsYaxisScale}
              onChange={formik.handleChange}
            />
          </Grid>
          {isFixedScaleYAxis() && (
            <>
              <Grid item>
                <Typography variant="subtitle2" color="#686868">
                  Axis boundaries
                </Typography>
              </Grid>

              <Grid item container spacing={2}>
                <Grid item xs={6} alignContent="flex-end">
                  <CustomInput
                    type="number"
                    name="properties.settingsMinimum"
                    label="Min"
                    value={formik.values.properties.settingsMinimum}
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.properties?.settingsMinimum)}
                    helperText={formik.errors.properties?.settingsMinimum}
                  />
                </Grid>
                <Grid item xs={6} alignContent="flex-end">
                  <CustomInput
                    type="number"
                    name="properties.settingsMaximum"
                    label="Max"
                    error={Boolean(formik.errors.properties?.settingsMaximum)}
                    helperText={formik.errors.properties?.settingsMaximum}
                    value={formik.values.properties.settingsMaximum}
                    onChange={formik.handleChange}
                  />
                </Grid>
              </Grid>
            </>
          )}
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Appearance
            </Typography>
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsStyle"
              label="Style"
              list={SETTINGS_STYLE_OPTIONS}
              value={formik.values.properties.settingsStyle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsSize"
              label="Size"
              list={SETTINGS_SIZE_OPTIONS}
              value={formik.values.properties.settingsSize}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomSelect
              name="properties.settingsChartType"
              label="Chart type"
              list={CHART_TYPE_OPTIONS}
              value={formik.values.properties.settingsChartType}
              onChange={formik.handleChange}
            />
          </Grid>
          {isLineChart() && (
            <>
              <Grid item>
                <CustomSelect
                  name="properties.settingsSmoothType"
                  label="Smooth Type"
                  list={SMOOTH_TYPE_OPTIONS}
                  value={formik.values.properties.settingsSmoothType}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item>
                <CustomSelect
                  name="properties.settingsLineWidth"
                  label="Line width"
                  list={LINE_WIDTH_OPTIONS}
                  value={formik.values.properties.settingsLineWidth}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item container justifyContent="space-between" alignItems="center">
                <CustomSwitch
                  name="properties.settingsFill"
                  label="Fill"
                  value={formik.values.properties.settingsFill}
                  onChange={formik.handleChange}
                />
              </Grid>
            </>
          )}
          <Grid item>
            <CustomInput
              name="description"
              label={msg.addWidgetModal.description}
              clearFieldIcon={true}
              multiline={true}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(RegularChart);
