import { getIn } from 'formik';
import * as Yup from 'yup';

// TODO: #773
const fieldRequiredProps = (formik: any, validationSchema: any, name: string) => {
  const isNestedProperty = name?.includes('.');
  const value = isNestedProperty ? getIn(formik?.values, name) : formik?.values?.[name];
  const error = isNestedProperty ? getIn(formik?.errors, name) : formik?.errors?.[name];
  const touched = isNestedProperty ? getIn(formik?.touched, name) : formik?.touched?.[name];
  let schemaField: any = {};
  if (isNestedProperty) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      schemaField = Yup.reach(validationSchema, name);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  } else {
    schemaField = validationSchema?.fields?.[name];
  }
  return {
    id: name,
    name,
    value,
    onChange: formik?.handleChange,
    error: touched && Boolean(error),
    helperText: touched && error,
    required: Boolean(schemaField?.exclusiveTests?.required),
  };
};

export type IFieldRequiredProps = {
  id?: string;
  name: string;
  value?: any;
  onChange: (e: any) => void;
  error?: boolean;
  helperText?: string;
  required?: boolean;
};

export default fieldRequiredProps;
