import { gql } from '@src/__generated__';

export const GET_MONITOR_OBJECT_WITHOUT_FRAGMENTS = gql(/* GraphQL */ `
  query getMonitorObjectWithoutFragments($objectId: UUID!) {
    object(id: $objectId) {
      id
      name
      schemaTags
      objectProperties(orderBy: [GROUP_NAME_ASC, PROPERTY_ASC]) {
        groupName
        property
        value
        key
        id
        linkedPropertyId
        updatedAt
        spec {
          id
          hidden
          units
          key
          valueSet
          valueRange
          description
          defaultValue
          type {
            id
            name
            __typename
          }
          __typename
        }
        userByBy {
          id
          login
          __typename
        }
        __typename
      }
      objectsToObjectsByObject1Id(
        filter: { object2: { schema: { parentSchemaExists: false }, schemaType: { in: ["device"] } } }
      ) {
        id
        object2 {
          id
          name
          muted
          description
          schemaTags
          schemaType
          enabled
          ...AccessGroupsFragment
          objectProperties(orderBy: [GROUP_NAME_DESC, PROPERTY_DESC]) {
            id
            groupName
            property
            linkedPropertyId
            value
            key
            updatedAt
            userByBy {
              id
              login
            }
          }
        }
      }
    }
  }
`);
