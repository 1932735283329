import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, validationSchema } from '../../constants/formik';

const Title = ({ form, field }: FieldProps<IFormValues>) => (
  <Grid item>
    <CustomInput
      {...fieldRequiredProps(form, validationSchema, field.name)}
      autoComplete="title"
      label="App title"
      clearFieldIcon={true}
    />
  </Grid>
);

export default Title;
