import ImageItem from '@components/common/ImageItem';
import { IFormValues, validationSchema } from '@modules/user/forms/EditProfile/constants/formik';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';

const Icon: FC<FieldProps<IFormValues>> = ({ form }) => (
  <>
    <Grid item>
      <Typography variant="subtitle2" color="#686868">
        Icon
      </Typography>
    </Grid>
    <Grid item width="100%">
      <ImageItem {...fieldRequiredProps(form, validationSchema, 'icon')} label={'Icon'} withUnlink={false} />
    </Grid>
  </>
);

export default Icon;
