import * as yup from 'yup';

export interface IFormValues {
  name: string;
  widgetId: string;
  format: string;
  size: string;
  style: string;
  description: string;
  text: string;
  switchTextOn: string;
  switchTextOff: string;
}

export const validationSchema = yup.object({});
