/* eslint-disable react/prop-types */
import { msg } from '@constants/messages';
import { Button, Divider, ListItem, ListItemText, Typography } from '@mui/material';
import { ControlExecution } from '@src/__generated__/graphql';
import { format } from 'date-fns';
import { create, InstanceComponent, InstanceProps } from 'react-modal-promise';
import CommonModal from '../CommonModal';
import useHandleCopy from '../side-card/menu/handlers/useHandleCopy';

type ControlsHistoryInfoModalProps = {
  property: ControlExecution;
  title: string;
  params: { argument: string; isJson: boolean; value: boolean }[];
  hasReports?: boolean;
};

const ControlsHistoryInfoModal: InstanceComponent<ControlsHistoryInfoModalProps & InstanceProps<any, any>> = (
  props
) => {
  const { property, title, params, hasReports } = props;
  const submit: () => void = () => props.onResolve();
  const handleCopy = useHandleCopy();
  const handleClose = () => submit();
  return (
    <CommonModal
      key="ControlsModal"
      modalOpen={props.isOpen}
      title={title}
      handleClose={handleClose}
      buttons={
        <>
          <Button color="primary" onClick={handleClose}>
            {msg.notificationsModal.buttonClose}
          </Button>
          <Button
            color="primary"
            onClick={() =>
              handleCopy({
                object: {},
                text: JSON.stringify(property),
                message: 'Copied successfully',
              })
            }
          >
            {msg.default.copy}
          </Button>
        </>
      }
    >
      {params?.length !== 0 ? (
        <>
          {params?.map(({ argument, value }, index) => (
            <ListItem key={index}>
              <ListItemText style={{ overflowWrap: 'break-word' }} primary={`${argument}: ${value}`} />
            </ListItem>
          ))}
          <Divider />
        </>
      ) : (
        <ListItem>
          <ListItemText secondary={params?.length === 0 && 'No arguments'} />
        </ListItem>
      )}
      <ListItem>
        <ListItemText
          primary={<Typography noWrap>{property?.name}</Typography>}
          secondary={`${format(
            new Date((property?.createdAt as string | number | Date) || '2023'),
            'MMM d, hh:mm:ss a'
          )}${hasReports ? `, error:${property?.error}, done:${property?.done} ` : ''}`}
        />
      </ListItem>
    </CommonModal>
  );
};

export default create(ControlsHistoryInfoModal);
