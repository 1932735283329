import { useQuery } from '@apollo/client';
import StatusListItemNull from '@components/side-card/command-button/components/StatusListItemNull';
import { GET_OBJECT } from '@modules/objects/api/getControls';
import AdvancedButtonEditControlModal from '@modules/widgets/forms/AdvancedButton/EditControlModal';
import EditObjectModal from '@modules/widgets/forms/CommandButton/EditObjectModal';
import EditIcon from '@mui/icons-material/Edit';
import HistoryIcon from '@mui/icons-material/History';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { setSettings } from '@store/settingsSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useMoreMenu from '../../useMoreMenu';
import ControlListItem from '../advanced-button/components/ControlListItem';
import ObjectListItem from '../advanced-button/components/ObjectListItem';
import StatusListItem from '../advanced-button/components/StatusListItem';
import AccessSection from '../basic/AccessSection';
import DescriptionSection from '../basic/DescriptionSection';
import ServiceSection from '../basic/ServiceSection';
import HistoryProperty from '../history/HistoryProperty';
import st from './style.module.css';

const AdvancedButtonGeneralTab = (props) => {
  const { item, propKey } = props;

  const getPropertyByKey = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };
  const buttonText = () => getPropertyByKey('valueText');
  const params = () => getPropertyByKey('valueParams')?.value;
  const valueActionRPC = getPropertyByKey('valueValue')?.value?.value;
  const objectId = () => getPropertyByKey(propKey)?.value?.objectId;

  const { MoreMenu: ColumnMenu, openMoreMenu: openColumnMenu } = useMoreMenu();
  const { MoreMenu: TextMenu, openMoreMenu: openTextMenu } = useMoreMenu();
  const dispatch = useDispatch();
  const [idProperty, setIdProperty] = useState(null);
  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);

  const { data: dataObject, loading } = useQuery(GET_OBJECT, {
    variables: {
      objectId: objectId(),
    },
  });

  const rpc = valueActionRPC;

  return (
    <>
      {isShowHistoryProperty && <HistoryProperty id={idProperty} object={item} />}
      <List>
        <ColumnMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit',
              id: 'edit_prop',
              handleAction: (obj) => {
                EditObjectModal({
                  widgetId: obj.id,
                  propKey: propKey,
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />
        <TextMenu
          items={[
            {
              icon: <EditIcon />,
              title: 'Edit action',
              id: 'edit_prop',
              handleAction: (obj) => {
                AdvancedButtonEditControlModal({
                  widgetId: obj.id,
                  propId: buttonText().id,
                  propKey: propKey,
                })
                  .then()
                  .catch(() => {});
              },
            },
            {
              icon: <HistoryIcon />,
              title: 'Show history',
              id: 'show_history',
              disabled: true,
              handleAction: (obj) => {
                setIdProperty(obj.propId);
                dispatch(setSettings({ isShowHistoryProperty: true }));
              },
            },
          ]}
        />
        <ListSubheader color="primary" className={st.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>
        {objectId() && rpc ? (
          <StatusListItem params={params} rpc={rpc} objectId={objectId()} />
        ) : (
          <StatusListItemNull />
        )}

        <ListSubheader color="primary" className={st.listSubheader}>
          <Typography variant="subtitle2">Target</Typography>
        </ListSubheader>
        <ObjectListItem dataObject={dataObject} openColumnMenu={openColumnMenu} item={item} loading={loading} />
        <ControlListItem openTextMenu={openTextMenu} buttonText={rpc} item={item} />
        <ServiceSection classes={st} item={item} />
        <AccessSection classes={st} item={item} />
        {item.description && <DescriptionSection classes={st} item={item} />}
      </List>
    </>
  );
};

export default AdvancedButtonGeneralTab;
