import ImageItem from '@components/common/ImageItem';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const CoverImage: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" color="#686868">
          Cover image
        </Typography>
      </Grid>
      <Grid item width="100%">
        <ImageItem
          {...fieldRequiredProps(form, validationSchema, 'coverImage')}
          label={'Cover image'}
          withUnlink={false}
        />
      </Grid>
    </>
  );
};

export default CoverImage;
