import * as yup from 'yup';

export interface IFormValues {
  control: string;
  widgetId: string;
  propKey: string;
  schemaId?: string;
}

export const validationSchema = yup.object({
  control: yup.string().trim().required('Control is required'),
});
