import { gql } from '@apollo/client';

export const CREATE_CONTROL_EXECUTION = gql`
  mutation createControlExecution($input: CreateControlExecutionInput!) {
    createControlExecution(input: $input) {
      controlExecution {
        id
        params
        name
        nodeId
        linkedControlId
        objectId
      }
      clientMutationId
    }
  }
`;
