import { gql } from '../../../__generated__';

const GET_COLLECTIONS = gql(/* GraphQL */ `
  query getCollections {
    collections: objects(
      filter: { schemaTags: { contains: ["application", "board", "collection"] } }
      orderBy: NAME_ASC
    ) {
      id
      name
      enabled
      schemaTags
    }
  }
`);

export { GET_COLLECTIONS };
