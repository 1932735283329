import { useApolloClient, useLazyQuery } from '@apollo/client';
import AddIcon from '@mui/icons-material/Add';
import BlockIcon from '@mui/icons-material/Block';
import DeleteIcon from '@mui/icons-material/Delete';
import { loader } from 'graphql.macro';
import orderBy from 'lodash.orderby';
import { useEffect, useState } from 'react';
import AddNewCollection from '../../../components/AddNewCollection';
import SideList from '../../../components/SideList';
import DeleteCollectionModal from './DeleteCollectionModal';

const COLLECTIONS_QUERY = loader('../api/CollectionsQuery.graphql');
const COLLECTIONS_SUBSCRIBE = loader('../api/CollectionsSubscribe.graphql');
const SideListCollection = () => {
  const client = useApolloClient();
  const [load, { data, loading, refetch, subscribeToMore }] = useLazyQuery(COLLECTIONS_QUERY);
  const [itemsChecked, setItemsChecked] = useState([]);

  useEffect(() => {
    load().then(() => {});
  }, []);

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: COLLECTIONS_SUBSCRIBE,
      variables: {
        filterA: { tags: ['application', 'board', 'collection'] },
      },
      updateQuery: async (previousResult, { subscriptionData }) => {
        const relatedNode = subscriptionData.data?.Objects?.relatedNode;
        const eventType = subscriptionData.data?.Objects?.event;

        const data = client.readQuery({
          query: COLLECTIONS_QUERY,
          variables: {},
        });

        if (eventType === 'insert') {
          client.writeQuery({
            query: COLLECTIONS_QUERY,
            data: {
              collections: [...data.collections, relatedNode],
            },
            variables: {},
          });
        }
      },
    });

    return () => unsubscribe();
  }, [data]);
  const actionTitle = () => {
    return 'Collections';
  };

  const getList = (sortType) => {
    if (!loading && data?.collections) {
      return orderBy(data.collections, [sortType], ['asc']).map((item) => ({
        ...item,
        link: `/collections/${item.id}`,
      }));
    }
    return [];
  };

  const headerMenu = [
    {
      icon: <AddIcon />,
      title: 'Add new',
      id: 'addNewCollection',
      handleAction: () => {
        AddNewCollection({
          refetch: refetch,
        })
          .then()
          .catch(() => {});
      },
    },
  ];
  const headerGroupMenu = [
    {
      icon: <BlockIcon />,
      title: 'Disable',
      id: 'disable',
      disabled: true,
    },
    {
      disabled: true,
      icon: <DeleteIcon />,
      title: 'Delete',
      id: 'delete',
      handleAction: () => {
        DeleteCollectionModal({
          ids: itemsChecked.map((item) => ({ id: item })),
          text: `Delete these collections?`,
          toast: {
            loading: 'Deleting collections...',
            success: 'Collections deleted',
          },
        })
          .then()
          .catch(() => {});

        // DeleteGroupsModal({
        //   ids: itemsChecked,
        //   dashboardId,
        //   setItemsChecked,
        // })
        //   .then()
        //   .catch(() => {});
      },
    },
  ];

  return (
    <>
      <SideList
        loading={loading}
        sideBarWidth={412}
        title={actionTitle()}
        getList={getList}
        itemsChecked={itemsChecked}
        setItemsChecked={setItemsChecked}
        headerMenu={headerMenu}
        headerGroupMenu={headerGroupMenu}
      />
    </>
  );
};

export default SideListCollection;
