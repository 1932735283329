import { Grid } from '@mui/material';
import { FC } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import CommonModal from '../../CommonModal';
import useNotificationModalData from './hooks/useNotificationModalData';
import Buttons from './modules/Buttons';
import ListItem from './modules/ListItem';
import arrayToObject from './utils/arrayToObject';

interface INotificationModalProps {
  onResolve: () => void;
  isOpen: boolean;
  property: any;
}

const NotificationModal: FC<INotificationModalProps & InstanceProps<{}>> = ({
  isOpen,
  onResolve: handleClose,
  property,
}) => {
  const { data } = useNotificationModalData(property);

  return (
    <CommonModal
      key="NotificationInfo"
      modalOpen={isOpen}
      title="Notification info"
      handleClose={handleClose}
      buttons={<Buttons handleClose={handleClose} text={JSON.stringify(arrayToObject(data))} />}
    >
      <Grid container direction="column" spacing={4}>
        {data?.map((props, index) => (
          <ListItem key={`listItem-${index}`} {...props} />
        ))}
      </Grid>
    </CommonModal>
  );
};

export default create(NotificationModal);
