import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import CustomInput from '../../../../../../components/CustomInput';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const Name: FC<FieldProps<IFormValues>> = ({ form }) => (
  <>
    <Grid item>
      <Typography variant="subtitle2" color="primary">
        Contact info
      </Typography>
    </Grid>
    <Grid item>
      <CustomInput
        {...fieldRequiredProps(form, validationSchema, 'name')}
        autoComplete="name"
        label={'Name'}
        clearFieldIcon={true}
      />
    </Grid>
  </>
);

export default Name;
