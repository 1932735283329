import { Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC, memo } from 'react';
import st from './style.module.css';

export interface IListItem {
  name?: string;
  value?: string | string[];
  valueOnNewLine?: boolean;
}

const ListItem: FC<IListItem> = ({ name, value: valueProps, valueOnNewLine }) => {
  const value = valueProps || 'n/a';

  return (
    <Grid item>
      {Array.isArray(value) ? (
        <>
          <Typography variant="body1" className={st.subTitle}>
            Tags:
          </Typography>
          <Box className={st.tags}>
            {value.map((tag, index) => (
              <Chip key={`tagItem-${index}`} label={tag} color="primary" />
            ))}
          </Box>
        </>
      ) : (
        <Typography variant="body1">
          <Box component="span" className={st.subTitle}>
            {name}:
          </Box>
          {valueOnNewLine ? (
            <>
              <br />
              {value}
            </>
          ) : (
            value
          )}
        </Typography>
      )}
    </Grid>
  );
};

export default memo(ListItem);
