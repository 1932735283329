import { gql } from '@src/__generated__';

export const GET_OBJECT_PROPERTIES = gql(/* GraphQL */ `
  query getObjectPropertiesByIds($ids: [UUID!]!) {
    objectProperties(filter: { id: { in: $ids } }) {
      id
      key
      value
      property
      groupName
      type
      objectId
      spec {
        id
        type {
          name
        }
        nodeId
        description
        valueSet
      }
    }
  }
`);
