import { CircularProgress, ListItem } from '@mui/material';
import st from './style.module.css';

const HistoryLoader = () => (
  <ListItem className={st.wrapper}>
    <CircularProgress size={24} />
  </ListItem>
);

export default HistoryLoader;
