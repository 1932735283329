import { useQuery } from '@apollo/client';
import st from '@components/side-card/advanced-button/style.module.css';
import { GET_CONTROLS } from '@modules/controls/api/getControls';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ControlExecutionsOrderBy, ControlTypes } from '@src/__generated__/graphql';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

type IStatusListItemProps = {
  objectId: string;
  rpc?: string;
  valueStatus: boolean;
};

const StatusListItem = ({ objectId, rpc, valueStatus }: IStatusListItemProps) => {
  const { data: controles, refetch } = useQuery(GET_CONTROLS, {
    variables: {
      filter: {
        type: {
          equalTo: ControlTypes.Rpc,
        },
        objectId: {
          equalTo: objectId,
        },
        name: {
          equalTo: rpc,
        },
        linkedControlId: {
          isNull: true,
        },
      },
      first: 1,
      offset: 0,
      orderBy: ControlExecutionsOrderBy.CreatedAtDesc,
    },
    fetchPolicy: 'cache-and-network',
  });

  const control = useMemo(() => controles?.controlExecutionsConnection?.edges?.[0]?.node, [controles]);

  useEffect(() => {
    refetch().then();
  }, [valueStatus]);

  return (
    <ListItem
      classes={{
        container: st.listItemWrapper,
      }}
      style={{ height: '48px', paddingRight: '48px' }}
    >
      <ListItemIcon>
        {!control && <HelpOutlineIcon />}
        {control?.ack === false && <AccessTimeIcon />}
        {control?.ack === true && control?.done === false && <RefreshIcon />}
        {control?.ack === true && control?.done === true && control?.error === 'false' && <CheckCircleOutlineIcon />}
        {control?.ack === true && control?.done === true && control?.error === 'true' && <ErrorOutlineIcon />}
        {control?.ack === undefined && control?.done === undefined && control?.error === undefined && (
          <AccessTimeIcon className={st.accessTimeIcon} />
        )}
      </ListItemIcon>
      <ListItemText
        secondary={
          control?.createdAt
            ? `${format(new Date(control?.createdAt as string), 'MMM d, hh:mm:ss a')}  by
                ${control?.caller?.mName || 'n/a'}`
            : null
        }
        primary={
          <Typography variant="body1">
            Status: {!control && 'n/a'}
            {!control?.done && control?.error === 'false' && 'In progress'}
            {control?.done && control?.error === 'false' && 'Done'}
            {control?.error === 'true' && control?.error}
          </Typography>
        }
      />
    </ListItem>
  );
};

export default StatusListItem;
