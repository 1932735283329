import LabelIcon from '@components/icons/labelIcon';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import EditIcon from '@mui/icons-material/Edit';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { GetSchemaQuery, ObjectProperty } from '@src/__generated__/graphql';
import { hideNull } from '@utils/hideNull';

import { containerClasses, ITabGeneralObjectStatisticProps } from '../../index';
import st from '../../style.module.css';

interface IProps {
  onChange: (id: string, value: string) => Promise<void>;
  properties: GetSchemaQuery['schema']['schemaProperties'];
  item: ITabGeneralObjectStatisticProps['item'];
  settingsProperty: ObjectProperty;
}

const PropertyItem = ({ onChange, properties, settingsProperty, item }: IProps) => {
  const { MoreMenu, openMoreMenu } = useMoreMenu();
  const handleCopy = useHandleCopy();

  const getSchemaPropertyName = (): string => {
    const currentProperty = properties?.find(
      (prop) => settingsProperty?.value === `${prop?.groupName}/${prop.property}`
    );
    return hideNull(currentProperty?.description ?? currentProperty?.property);
  };

  return (
    <>
      <MoreMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              EditDynamicProperty({
                handleSave: onChange,
                property: {
                  ...settingsProperty,
                  spec: {
                    ...settingsProperty?.spec,
                    valueSet: {
                      component: 'select',
                      list: properties?.map((prop) => ({
                        title: prop.description ?? prop.property,
                        key: `${prop?.groupName}/${prop.property}`,
                      })),
                    },
                  },
                },
                id: obj.propId,
                value: obj.propValue,
              }).catch(() => {});
            },
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: JSON.stringify(obj.propValue),
                message: 'Value copied',
              });
            },
          },
        ]}
      />
      <ListItem classes={containerClasses} className={st.listItem}>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary={<Typography variant="body1">{`Property: ${getSchemaPropertyName()}`}</Typography>} />
        <ListItemSecondaryAction className={st.itemToHideOrShow}>
          <IconButton
            edge="end"
            aria-label="more"
            onClick={(e) => {
              openMoreMenu(e, {
                ...item,
                propKey: settingsProperty?.key,
                propValue: settingsProperty?.value,
                propId: settingsProperty?.id,
              });
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default PropertyItem;
