import st from '@components/widgets/W_CommandButton/style.module.css';
import { Box } from '@mui/system';
import { SettingsStyleOptions } from '@utils/constants/selectOptions';

type IProps = {
  style: SettingsStyleOptions;
  switchStatus: boolean;
  colors: any;
  size: 'small' | 'medium';
};

const SetSwitchIcon = ({ style, switchStatus, size, colors }: IProps) => {
  return (
    <Box
      className={st.switcher}
      sx={{
        width: `${size === 'small' ? '66px' : '88px'}`,
        height: `${size === 'small' ? '36px' : '48px'}`,
        border: `${size === 'small' ? '6px' : '8px'} solid ${colors.fg}`,
        backgroundColor: switchStatus ? colors.fg : 'transparent',
        marginTop: `${size === 'small' ? '10px' : '28px'}`,
        '&:before': {
          content: "''",
          position: 'absolute',
          top: '50%',
          left: switchStatus ? 'auto' : `${size === 'small' ? '3px' : '4px'}`,
          right: switchStatus ? `${size === 'small' ? '3px' : '4px'}` : 'auto',
          width: `${size === 'small' ? '18px' : '24px'}`,
          height: `${size === 'small' ? '18px' : '24px'}`,
          backgroundColor: switchStatus
            ? style === SettingsStyleOptions.lightontransparent || style === SettingsStyleOptions.darkontransparent
              ? 'rgb(51, 51, 51)'
              : colors.bg
            : colors.fg,
          borderRadius: '50%',
          transform: 'translateY(-50%)',
        },
      }}
    />
  );
};

export default SetSwitchIcon;
