import { IFormValues } from '@modules/widgets/forms/CommandButton/EditControlModal/constants/formik';

type TProps = IFormValues['parameters'];

const arrayToObject = (arr: TProps): Record<string, string | null | boolean | number> =>
  arr.reduce((acc, item) => {
    acc[item.argument] = item.value;
    return acc;
  }, {} as Record<string, string | null | boolean | number>);

export default arrayToObject;
