import CommonModal from '@components/CommonModal';
import Buttons from '@modules/widgets/forms/LineWidgetModal/components/Buttons';
import Color from '@modules/widgets/forms/LineWidgetModal/components/Color';
import CoverImage from '@modules/widgets/forms/LineWidgetModal/components/CoverImage';
import Description from '@modules/widgets/forms/LineWidgetModal/components/Description';
import LeftEnd from '@modules/widgets/forms/LineWidgetModal/components/LeftEnd';
import Name from '@modules/widgets/forms/LineWidgetModal/components/Name';
import Orientation from '@modules/widgets/forms/LineWidgetModal/components/Orientation';
import RightEnd from '@modules/widgets/forms/LineWidgetModal/components/RightEnd';
import Width from '@modules/widgets/forms/LineWidgetModal/components/Width';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ObjectProperty } from '@src/__generated__/graphql';
import { WidgetTypesEnum } from '@utils/widgetTypes';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import useForm from './hooks/useForm';

export interface ILineWidgetModalProps {
  onResolve?: () => void;
  isOpen?: boolean;
  widget?: { id: string };
  isEdit?: boolean;
  widgetType?: WidgetTypesEnum;
  group?: {
    id: string;
    type: ObjectProperty;
    objectProperties: ObjectProperty[];
  };
  name?: string;
}

const LineWidgetModal = ({ onResolve, isOpen, widget, isEdit, group, name }: ILineWidgetModalProps) => {
  const { formik, isLoading } = useForm({ onResolve, widget, isEdit, group, name });

  return (
    <CommonModal
      key="EditObject"
      modalOpen={isOpen}
      title={isEdit ? 'Edit line' : 'Add line'}
      handleClose={onResolve}
      buttons={<Buttons isEdit={isEdit} handleClose={onResolve} />}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="name" placeholder="name" component={Name} />
        <FastField name="orientation" placeholder="orientation" component={Orientation} />
        <Grid container item direction="row" spacing={2}>
          <FastField name="leftEnd" placeholder="leftEnd" component={LeftEnd} />
          <FastField name="rightEnd" placeholder="rightEnd" component={RightEnd} />
        </Grid>
        <Grid item>
          <Typography variant="subtitle2" color="primary">
            Parameters
          </Typography>
        </Grid>
        <Grid container item direction="row" spacing={2}>
          <FastField name="color" placeholder="color" component={Color} />
          <FastField type={'number'} name="width" placeholder="width" component={Width} />
        </Grid>
        <FastField name="coverImage" placeholder="coverImage" component={CoverImage} />
        <FastField name="description" placeholder="description" component={Description} />
      </Grid>
    </CommonModal>
  );
};
export default create(LineWidgetModal);
