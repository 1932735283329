import { gql, useMutation } from '@apollo/client';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import cloneDeep from 'lodash.clonedeep';
import differenceBy from 'lodash.differenceby';
import { useEffect, useState } from 'react';
import { create } from 'react-modal-promise';
import { msg } from '../../../constants/messages';
import CommonModal from '../../CommonModal';
import CustomAutocomplete from '../../CustomAutocomplete';
import CustomSelect from '../../CustomSelect';

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const typeColumnList = [
  {
    title: 'Value',
    value: 'value',
  },
  {
    title: 'Last update',
    value: 'lastUpdate',
  },
];

const MonitorTableColumnModal = ({ isEdit, properties, condition, conditionProperty, onResolve, onReject, isOpen }) => {
  const [weight] = useState(condition?.order || 0);
  const [typeColumn, setTypeColumn] = useState('value');
  const [property, setProperty] = useState({
    value: null,
    title: 'n/a',
  });

  const columns = conditionProperty.value.columns;

  const [updateProperty, { loading }] = useMutation(UPDATE_PROPERTY);

  const submit = () => onResolve();

  const reject = () => onReject();

  useEffect(() => {
    if (condition) {
      setProperty({
        value: condition?.property?.id || null,
        title: condition?.property?.name || 'n/a',
      });

      if (condition?.property?.type) {
        setTypeColumn(condition.property.type);
      }
    }
  }, [condition]);

  const handleEditProperty = (value) => {
    updateProperty({
      variables: {
        input: {
          id: conditionProperty.id,
          patch: {
            value: value,
          },
        },
      },
    }).then(() => {
      submit();
    });
  };
  return (
    <CommonModal
      key="MonitorTableColumnModal"
      modalOpen={isOpen}
      title={isEdit ? 'Edit column' : 'Add column'}
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button
            disabled={!Boolean(property.value)}
            color="primary"
            onClick={async () => {
              const oldValues = cloneDeep(conditionProperty.value.columns);

              if (isEdit) {
                const index = oldValues.findIndex((item) => item.property.id === condition.property.id);

                oldValues.splice(index, 1, {
                  alerts: condition.alerts,
                  order: weight,
                  property: {
                    type: typeColumn,
                    name: property.title,
                    id: property.value,
                  },
                });
                let patch = {
                  ...conditionProperty.value,
                  columns: [...oldValues],
                };
                handleEditProperty(patch);
              } else {
                oldValues.push({
                  alerts: [],
                  order: oldValues.length,
                  property: {
                    type: typeColumn,
                    name: property.title,
                    id: property.value,
                  },
                });
                let patch = {
                  ...conditionProperty.value,
                  columns: [...oldValues],
                };

                handleEditProperty(patch);
              }
            }}
          >
            {loading ? <CircularProgress size={23} /> : isEdit ? msg.default.save : msg.default.add}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <CustomAutocomplete
            name="schemaProperties"
            label="Property"
            list={differenceBy(
              properties,
              columns.map((item) => item.property).filter((item) => item.id !== condition?.property?.id),
              'id'
            )}
            value={property.value}
            onChange={(e) => {
              if (!e.target.value) {
                setProperty({
                  value: null,
                  title: 'n/a',
                });
              } else {
                setProperty({
                  value: e.target.rawValue.id || '',
                  title: e.target.rawValue.title || e.target.rawValue.property,
                });
              }
            }}
            required={true}
          />
        </Grid>
        <Grid item>
          <CustomSelect
            label="Value"
            value={typeColumn}
            list={typeColumnList}
            onChange={(e) => {
              setTypeColumn(e.target.value);
            }}
          />
        </Grid>
      </Grid>
    </CommonModal>
  );
};

export default create(MonitorTableColumnModal);
