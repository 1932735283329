import { useMutation } from '@apollo/client';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import showMessages from '@hooks/apollo/showMessages';
import { RootState } from '@src/store';
import { useSelector } from 'react-redux';
import { UPDATE_USER } from '../../../api/UpdateUser';

import { IFormValues, validationSchema } from '../constants/formik';

interface IProps {
  onResolve: () => void;
}

const useForm = ({ onResolve }: IProps) => {
  const [editUserProfile, { loading }] = useMutation(UPDATE_USER, {
    ...showMessages,
  });
  const { uploadImageWithoutLink } = useUpdateBackgroundImage();

  const {
    defaultEditorgroup,
    defaultReadergroup,
    defaultUsergroup,
    description,
    mEmail,
    mName,
    mPhone,
    mPicture,
    mIcon,
    id,
    mExternalId,
  } = useSelector((state: RootState) => state?.user);

  const formik = {
    initialValues: {
      name: mName || '',
      email: mEmail || '',
      phone: mPhone || '',
      secondID: mExternalId || '',
      editorsGroup: defaultEditorgroup || '',
      usersGroup: defaultUsergroup || '',
      readersGroup: defaultReadergroup || '',
      photo: mPicture || null,
      icon: mIcon || null,
      description: description || '',
    },
    validationSchema,
    onSubmit: async (values: IFormValues) => {
      const [photoUid, iconUid] = await Promise.all([
        uploadImageWithoutLink(mPicture as string, values.photo),
        uploadImageWithoutLink(mIcon as string, values.icon),
      ]);

      void editUserProfile({
        variables: {
          input: {
            id,
            patch: {
              mName: values.name,
              mEmail: values.email,
              mPhone: values.phone,
              mPicture: photoUid,
              mIcon: iconUid,
              description: values.description,
              defaultEditorgroup: values.editorsGroup,
              defaultUsergroup: values.usersGroup,
              defaultReadergroup: values.readersGroup,
              mExternalId: values.secondID,
            },
          },
        },
      }).then(() => {
        onResolve();
      });
    },
  };

  return { formik, isLoading: loading };
};

export default useForm;
