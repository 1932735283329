import PropertyInfo from '@components/modals/PropertyInfo';
import { ProcessItemType } from '@components/side-card/history/HistoryLayout/constants';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItem, ListItemSecondaryAction, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import st from './style.module.css';

const listItemClasses = {
  container: st.itemToHover,
};

interface IProps {
  item: ProcessItemType;
}

export const ComponentItem = ({ item }: IProps) => {
  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();
  const handleCopy = useHandleCopy();

  return (
    <>
      <MoreMenu
        items={[
          {
            icon: <InfoIcon />,
            title: 'Info',
            id: 'info',
            disabled: false,
            handleAction: () => {
              PropertyInfo({
                property: item,
              }).catch(() => {});
            },
          },
          {
            icon: <FilterNoneIcon />,
            title: 'Copy value',
            id: 'copy',
            disabled: false,
            handleAction: () => {
              handleCopy({
                object: {
                  name: '',
                },
                message: 'Value copied successfully',
                text: item.value,
              });
            },
          },
        ]}
        handleMenuItemClick={() => {
          closeMoreMenu();
        }}
      />
      <ListItem classes={listItemClasses} data-testid={String(item.key)}>
        <ListItemText
          primary={<Typography className={st.title}>{item.primary}</Typography>}
          secondary={item.secondary}
        />
        <ListItemSecondaryAction className={st.itemToHideOrShow}>
          <IconButton edge="end" onClick={openMoreMenu} size="large">
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};
