import { useLazyQuery } from '@apollo/client';
import { ESchemaType, SETTINGS_SCHEMA_TYPES } from '@components/create-widget/databox/constants';
import CustomSelect from '@components/CustomSelect';
import SchemasAutocomplete from '@components/forms/SchemasAutocomplete';
import Grid from '@mui/material/Grid';
import { IFieldRequiredProps } from '@utils/formik/fieldRequiredProps';
import { isMonitoringObject, isStatistics } from '@utils/objectType';
import { ChangeEvent, useEffect, useState } from 'react';
import { SELECTED_SCHEMA_TYPE } from './api';

interface IProps {
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const SchemaSelectorField = ({ value, onChange, ...fieldProps }: IProps & IFieldRequiredProps) => {
  const [type, setType] = useState<ESchemaType>(ESchemaType.Device);

  const handleChangeType = (e: ChangeEvent<HTMLInputElement>) => {
    setType(e.target.value as ESchemaType);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e);
  };

  const [loadSchema] = useLazyQuery(SELECTED_SCHEMA_TYPE, {
    fetchPolicy: 'network-only',
    variables: {
      id: value,
    },
    onCompleted: (res) => {
      const typeLocal = res?.schema?.type?.toLowerCase() || ESchemaType.Device;
      const tags = res?.schema?.mTags || [];

      if (isStatistics(tags)) {
        setType(ESchemaType.Stat);
      } else if (isMonitoringObject(tags)) {
        setType(ESchemaType.Monitor);
      } else {
        setType(typeLocal as ESchemaType);
      }
    },
  });

  useEffect(() => {
    if (value) {
      void loadSchema();
    }
  }, [value]);

  return (
    <>
      <Grid item>
        <CustomSelect
          name="object-type"
          label="Schema type"
          value={type}
          list={SETTINGS_SCHEMA_TYPES}
          onChange={handleChangeType}
        />
      </Grid>
      <Grid item>
        <SchemasAutocomplete type={type} schemaId={value} handleChange={handleInputChange} {...fieldProps} />
      </Grid>
    </>
  );
};

export default SchemaSelectorField;
