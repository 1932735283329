import { useQuery } from '@apollo/client';
import CustomSelect from '@components/CustomSelect';
import { GroupType } from '@components/modals/ChangeAccessModal/type';
import { LOAD_GROUPS } from '@modules/groups/api/GetGroups';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';

import { IFormValues, validationSchema } from '../../constants/formik';

const defaultErrorText = {
  error: true,
  helperText: 'Failed to load groups',
};

const EditorsGroup: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { data, error } = useQuery(LOAD_GROUPS);

  const editorsList: GroupType[] =
    data?.userGroups
      ?.map((group: { groupName: string; id: string }) => ({
        title: group.groupName,
        value: group.id,
        id: group.id,
        disabled: group.groupName === 'Nobody',
      }))
      .sort((a, b) => a.title?.localeCompare(b.title)) || [];

  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" color="primary">
          Default groups
        </Typography>
      </Grid>
      <Grid item>
        <CustomSelect
          {...fieldRequiredProps(form, validationSchema, 'editorsGroup')}
          {...(error ? defaultErrorText : null)}
          label="Default editors group"
          list={editorsList}
        />
      </Grid>
    </>
  );
};

export default EditorsGroup;
