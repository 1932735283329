import { gql, useApolloClient } from '@apollo/client';
import orderBy from 'lodash.orderby';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useDebounce } from 'react-use';
import { isMonitoringObject } from '../../../../utils/objectType';
import OnWidgetAutocomplete from '../../base/OnWidgetAutocomplete';

// TODO: FSD, move to shared
const GET_PROPERTIES_BY_SCHEMA = gql`
  query getSchema($schemaId: UUID!) {
    schema(id: $schemaId) {
      mTags
      schemataByParentSchemaId(filter: { mTags: { equalTo: ["application", "monitor", "object monitoring item"] } }) {
        id
        name
        mTags
        schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
          id
          key
          groupName
          property
          description
          type {
            name
          }
          valueSet
          valueRange
        }
      }
      schemaProperties(orderBy: [GROUP_NAME_ASC, DESCRIPTION_ASC]) {
        id
        typeId
        description
        property
        groupName
      }
    }
  }
`;

const SelectProperty = (props) => {
  const client = useApolloClient();
  const [inputValue, setInputValue] = useState(null);
  const [, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [, setDebouncedValue] = useState(null);
  const [properties, setProperties] = useState([]);

  const getProperties = async () => {
    const schemaId = props.schemaId;
    if (schemaId) {
      setLoading(true);
      try {
        const result = await client.query({
          query: GET_PROPERTIES_BY_SCHEMA,
          variables: {
            schemaId,
          },
          fetchPolicy: 'network-only',
        });

        const fragmentProperties = result.data.schema.schemataByParentSchemaId.map((item) => {
          const infoNameProperty = item.schemaProperties.find((prop) => prop.key === 'infoName');
          const stateValueProperty = item.schemaProperties.find((prop) => prop.key === 'stateValue');

          return {
            title: infoNameProperty ? infoNameProperty.value || item.name : null,
            value: stateValueProperty ? stateValueProperty.id : null,
          };
        });

        const ownProperties = result.data?.schema?.schemaProperties.map((item) => {
          return {
            value: item.id,
            title: `${item.groupName}/${item?.description || item.property}`,
          };
        });

        if (isMonitoringObject(result.data.schema.mTags)) {
          setProperties(fragmentProperties);
        } else {
          setProperties(ownProperties);
        }
      } catch (err) {
        toast.error(`${err.toString()}`);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOnOpen = () => {
    if (props.schemaId) {
      getProperties();
    }
  };

  useDebounce(
    () => {
      if (inputValue !== null) {
        setDebouncedValue(inputValue);
      }
    },
    500,
    [inputValue]
  );

  // useEffect(() => {
  //   if (props.schemaId) {
  //     loadSchemaProperties({
  //       variables: {
  //         schemaId: props.schemaId,
  //       },
  //     });
  //   }
  // }, []);

  const handleInputChange = (e) => {
    if (e && typeof e.currentTarget.value === 'string') {
      setInputValue(e.currentTarget.value?.trim());
    }
  };

  const handleChange = (e) => {
    setValue(e);
    props.handleChange(e);
  };

  return (
    <OnWidgetAutocomplete
      options={orderBy(properties, ['title'], ['asc'])}
      loading={loading}
      handleOnOpen={handleOnOpen}
      value={props.value}
      fgColor={props.fgColor}
      bgColor={props.bgColor}
      label={props.label}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};

export default SelectProperty;
