import CustomSelect from '@components/CustomSelect';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import { ORIENTATION_OPTIONS } from '@modules/widgets/forms/LineWidgetModal/constants/constants';

const Orientation: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <>
      <Grid item>
        <Typography variant="subtitle2" color="primary">
          Content
        </Typography>
      </Grid>
      <Grid item>
        <CustomSelect
          {...fieldRequiredProps(form, validationSchema, 'orientation')}
          label="Orientation"
          list={ORIENTATION_OPTIONS}
        />
      </Grid>
    </>
  );
};

export default Orientation;
