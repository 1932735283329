import ImageBox from '@components/common/ImageItem/components/ImageBox';
import PicturePlusIcon from '@components/icons/picturePlusIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
import { IFieldRequiredProps } from '@utils/formik/fieldRequiredProps';
import { useState } from 'react';

import MediaAutocomplete, { SelectedOption } from './components/MediaAutocomplete';
import { ViewTypes } from './constants';
import st from './style.module.css';

type IProps = IFieldRequiredProps & {
  value: string | Blob | null;
  withUnlink?: boolean;
  label?: string;
  onDelete?: () => void;
  onChange?: (e: SelectedOption) => void;
};

const ImageItem = ({ value, onDelete, label = 'UUID or Name', withUnlink = false, ...fieldRequiredProps }: IProps) => {
  const [currentView, setCurrentView] = useState<ViewTypes>(ViewTypes.IMG);

  const { onChange, name, error, required } = fieldRequiredProps;

  return (
    <div>
      {currentView === ViewTypes.IMG && (
        <ImageBox
          setCurrentView={setCurrentView}
          value={value}
          onDelete={onDelete}
          onChange={onChange}
          withUnlink={withUnlink}
          name={name}
        />
      )}
      {currentView === ViewTypes.UUID && (
        <div className={st.inputWrapper}>
          <MediaAutocomplete
            value={value}
            label={label}
            onChange={onChange}
            name={name}
            error={error}
            required={required}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Tooltip title="Add image by selecting file" disableTouchListener>
                    <IconButton
                      data-test="turn-on-img-uploader"
                      onClick={() => {
                        setCurrentView(ViewTypes.IMG);
                      }}
                    >
                      <PicturePlusIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ImageItem;
