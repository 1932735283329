import { useApolloClient, useMutation } from '@apollo/client';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { loader } from 'graphql.macro';
import { useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { create } from 'react-modal-promise';
import CommonModal from '../../components/CommonModal';
import { msg } from '../../constants/messages';
import { WIDGET_QUERY } from '../../graphql/queries';
import CustomInput from '../CustomInput';
import CustomSelect from '../CustomSelect';
import CustomSelectColor from '../CustomSelectColor';

const UPdATE_OBJECT_PROPERTY = loader('../../graphql/UpdateObjectWithProperties.graphql');

const stateHeadline = {
  marginTop: '15px',
};

const EditTimerColorsModal = (props) => {
  const [updateProperty] = useMutation(UPdATE_OBJECT_PROPERTY);

  const client = useApolloClient();

  const submit = () => props.onResolve();
  const reject = () => props.onReject();

  let defaultValues = {};

  const [values, setValues] = useReducer((prev, updated) => ({ ...prev, ...updated }), defaultValues);

  useEffect(() => {
    const queryProps = async () => {
      try {
        const result = await client.query({
          query: WIDGET_QUERY,
          variables: { objId: props.widgetId },
          fetchPolicy: 'network-only',
        });

        const getPropValue = (prop) => result.data.object.objectProperties.find((obj) => obj.key === prop)?.value;

        defaultValues['colorsDefaultColor'] = getPropValue('colorsDefaultColor');

        defaultValues['colorsColor1Condition_color'] = getPropValue('colorsColor1Condition').color;
        defaultValues['colorsColor1Condition_operator'] = getPropValue('colorsColor1Condition').operator;
        defaultValues['colorsColor1Condition_value'] = getPropValue('colorsColor1Condition').value;

        defaultValues['colorsColor2Condition_color'] = getPropValue('colorsColor2Condition').color;
        defaultValues['colorsColor2Condition_operator'] = getPropValue('colorsColor2Condition').operator;
        defaultValues['colorsColor2Condition_value'] = getPropValue('colorsColor2Condition').value;

        defaultValues['colorsColor3Condition_color'] = getPropValue('colorsColor3Condition').color;
        defaultValues['colorsColor3Condition_operator'] = getPropValue('colorsColor3Condition').operator;
        defaultValues['colorsColor3Condition_value'] = getPropValue('colorsColor3Condition').value;

        setValues(defaultValues); // loaded values for each field
      } catch (err) {
        toast.error(err.toString());
      }
    };

    queryProps();
  }, []);

  const handleClose = () => reject();

  const handleInputChange = (e) => {
    let { name, value, checked } = e.target;

    if (checked) value = checked;

    setValues({ [name]: value });
  };

  if (Object.keys(values).length === 0) return false;

  return (
    <>
      <CommonModal
        modalOpen={props.isOpen}
        title={msg.editWidgetColorsModal.colorsSettings}
        handleClose={reject}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.editWidgetModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              onClick={() => {
                const valuesReady = [
                  {
                    propertyKey: 'colorsDefaultColor',
                    value: values.colorsDefaultColor,
                  },
                  {
                    propertyKey: 'colorsColor1Condition',
                    value: {
                      operator: values.colorsColor1Condition_operator,
                      value: values.colorsColor1Condition_value,
                      color: values.colorsColor1Condition_color,
                    },
                  },
                  {
                    propertyKey: 'colorsColor2Condition',
                    value: {
                      operator: values.colorsColor2Condition_operator,
                      value: values.colorsColor2Condition_value,
                      color: values.colorsColor2Condition_color,
                    },
                  },
                  {
                    propertyKey: 'colorsColor3Condition',
                    value: {
                      operator: values.colorsColor3Condition_operator,
                      value: values.colorsColor3Condition_value,
                      color: values.colorsColor3Condition_color,
                    },
                  },
                ];

                toast
                  .promise(
                    updateProperty({
                      variables: {
                        input: {
                          detailedObject: [
                            {
                              objectId: props.widgetId,
                              keyedProperties: valuesReady,
                            },
                          ],
                        },
                      },
                    }),
                    {
                      loading: 'Updating colors ...',
                      success: () => `Colors updated`,
                      error: (err) => `${err.toString()}`,
                    }
                  )
                  .then(() => submit());
              }}
            >
              {msg.editWidgetModal.buttonUpdate}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              {msg.editWidgetColorsModal.defaultColor}
            </Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              name="colorsDefaultColor"
              colors={['default', 'red', 'blue', 'green', 'yellow', 'orange']}
              label={msg.editWidgetColorsModal.color}
              value={values['colorsDefaultColor'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          {/* state 1 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              style={stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 1`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor1Condition_color"
              colors={['default', 'red', 'blue', 'green', 'yellow', 'orange']}
              label={msg.editWidgetColorsModal.objectColor}
              value={values['colorsColor1Condition_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor1Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={[
                  { value: '>', title: '>' },
                  { value: '<', title: '<' },
                  { value: '=', title: '=' },
                  { value: '!=', title: '!=' },
                  {
                    value: 'contains',
                    title: msg.editWidgetColorsModal.contains,
                  },
                ]}
                value={values['colorsColor1Condition_operator'] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={8} alignContent="flex-end">
              <CustomInput
                name="colorsColor1Condition_value"
                label="&nbsp;"
                clearFieldIcon={true}
                value={values['colorsColor1Condition_value'] ?? ''}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>

          {/* state 2 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              style={stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 2`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor2Condition_color"
              colors={['default', 'red', 'blue', 'green', 'yellow', 'orange']}
              label={msg.editWidgetColorsModal.objectColor}
              value={values['colorsColor2Condition_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor2Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={[
                  { value: '>', title: '>' },
                  { value: '<', title: '<' },
                  { value: '=', title: '=' },
                  { value: '!=', title: '!=' },
                  {
                    value: 'contains',
                    title: msg.editWidgetColorsModal.contains,
                  },
                ]}
                value={values['colorsColor2Condition_operator'] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={8} alignContent="flex-end">
              <CustomInput
                clearFieldIcon={true}
                name="colorsColor2Condition_value"
                label="&nbsp;"
                value={values['colorsColor2Condition_value'] ?? ''}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>

          {/* state 3 */}

          <Grid item>
            <Typography
              variant="subtitle2"
              color="primary"
              style={stateHeadline}
            >{`${msg.editWidgetColorsModal.state} 3`}</Typography>
          </Grid>

          <Grid item>
            <CustomSelectColor
              clearFieldIcon={true}
              name="colorsColor3Condition_color"
              colors={['default', 'red', 'blue', 'green', 'yellow', 'orange']}
              label={msg.editWidgetColorsModal.objectColor}
              value={values['colorsColor3Condition_color'] ?? ''}
              onChange={handleInputChange}
            />
          </Grid>

          <Grid item container>
            <Grid item xs={4} style={{ paddingRight: '10px' }}>
              <CustomSelect
                clearFieldIcon={true}
                name="colorsColor3Condition_operator"
                label={msg.editWidgetColorsModal.option}
                list={[
                  { value: '>', title: '>' },
                  { value: '<', title: '<' },
                  { value: '=', title: '=' },
                  { value: '!=', title: '!=' },
                  {
                    value: 'contains',
                    title: msg.editWidgetColorsModal.contains,
                  },
                ]}
                value={values['colorsColor3Condition_operator'] ?? ''}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item container xs={8} alignContent="flex-end">
              <CustomInput
                name="colorsColor3Condition_value"
                label="&nbsp;"
                clearFieldIcon={false}
                value={values['colorsColor3Condition_value'] ?? ''}
                onChange={handleInputChange}
                type="string"
              />
            </Grid>
          </Grid>
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(EditTimerColorsModal);
