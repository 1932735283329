import { Object as ObjectType } from '@src/__generated__/graphql';
import { format, isValid, parseISO } from 'date-fns';
import { ExportToCsv } from 'export-to-csv';

type HeaderType = {
  order: number;
  alerts: unknown[];
  property: {
    id: string;
    name: string;
    type: string;
  };
};

type ValueProperty = {
  id: string;
  name: string;
  value: string;
  isAlert: boolean;
  schemaId: string;
  lastUpdate: string;
};

type ValueType = {
  objectId: string;
  objectName: string;
  properties: ValueProperty[];
};

const isValidDate = (date: string) => {
  return isValid(parseISO(date));
};

export const downloadCSVMonitorTable = (object: ObjectType, title: string) => {
  const values: ValueType[] = object.objectProperties.find((item) => item.key === 'valueValue')?.value.slice() || [];

  const headers: HeaderType[] =
    object.objectProperties.find((item) => item.key === 'sourceColumns')?.value?.columns.slice() || [];

  if (values.length === 0) {
    return;
  }

  const prepareForExport = values.map((item) => {
    const data = headers
      .sort((a, b) => a.order - b.order)
      .reduce((acc, header) => {
        const valueObject = item.properties.find((prop) => prop?.schemaId === header?.property?.id);
        const value = valueObject?.[header?.property?.type];
        return {
          ...acc,
          [`"${header.property.name} (${header.property.type})"`]:
            typeof value === 'string' && isValidDate(value) ? format(parseISO(value), 'dd-MM-yyyy') : value,
        };
      }, {});

    return {
      Object: item?.objectName,
      ...data,
    };
  });

  const csvExporter = new ExportToCsv({
    title,
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    useTextFile: false,
    filename: title,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(prepareForExport.reverse());
};
