import { useLazyQuery } from '@apollo/client';
import AdvancedButtonGeneralTab from '@components/side-card/advanced-button/AdvancedButtonGeneralTab';
import TabGeneralCalculatorStatistic from '@components/side-card/calculator-statistic/TabGeneralCalculatorStatistic';
import CardImage from '@components/side-card/CardImage';
import CardToolbar from '@components/side-card/CardToolbar';
import ChartGeneralTab from '@components/side-card/chart/ChartGeneralTab';
import ColorChartGeneralTab from '@components/side-card/chart/ColorChartGeneralTab';
import CommandButtonGeneralTab from '@components/side-card/command-button/CommandButtonGeneralTab';
import TabGeneralCounterStatistic from '@components/side-card/counter-statistic/TabGeneralCounterStatistic';
import TabGeneralSource from '@components/side-card/counter-statistic/TabGeneralSource';
import DataboxGeneralTab from '@components/side-card/databox/DataboxGeneralTab';
import TabGeneralTrackingBox from '@components/side-card/geo-timer/TabGeneralTrackingBox';
import GeotagsTableGeneral from '@components/side-card/geotags-table/GeotagsTableGeneral';
import NormalHeader from '@components/side-card/header/NormalHeader';
import HistoryControlsProperties from '@components/side-card/history-controls/HistoryControlsProperties';
import HistoryTableGeneral from '@components/side-card/history-table/HistoryTableGeneral';
import HistoryProperties from '@components/side-card/history/HistoryProperties';
import useUniversalMenu from '@components/side-card/menu/useUniversalMenu';
import MonitorObjectGeneralTab from '@components/side-card/monitor-object/MonitorObjectGeneralTab';
import MonitorObjectTabObjects from '@components/side-card/monitor-object/MonitorObjectTabObjects';
import MonitorStatusTableGeneral from '@components/side-card/monitor-status-table/MonitorStatusTableGeneral';
import MonitorTableGeneral from '@components/side-card/monitor-table/MonitorTableGeneral';
import TabContentNotifications from '@components/side-card/notificationTab/TabContentNotifications';
import TabGeneralObjectStatistic from '@components/side-card/object-statistic/TabGeneralObjectStatistic';
import PropertyHistoryTableGeneral from '@components/side-card/property-history-table/PropertyHistoryTableGeneral';
import StaticTableGeneral from '@components/side-card/static-table/StaticTableGeneral';
import TabContentControls from '@components/side-card/TabContentControls';
import TabContentDashboards from '@components/side-card/TabContentDashboards';
import TabContentGeneral from '@components/side-card/TabContentGeneral';
import TabContentObjects from '@components/side-card/TabContentObjects';
import TabContentProperties from '@components/side-card/TabContentProperties';
import TabPanel from '@components/side-card/TabPanel';
import TimerGeneralTab from '@components/side-card/timer/TimerGeneralTab';
import TabGeneralTimeseriesStatistic from '@components/side-card/timeseries-statistic/TabGeneralTimeseriesStatistic';
import TabSourceTimeseries from '@components/side-card/timeseries-statistic/TabSourceTimeseries';
import TrackingTableGeneral from '@components/side-card/tracking-table/TrackingTableGeneral';
import TopButton from '@components/TopButton';
import { msg } from '@constants/messages';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import useRoute from '@hooks/useRoute';
import { Fade } from '@mui/material';
import Divider from '@mui/material/Divider';
import LinearProgress from '@mui/material/LinearProgress';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useTheme } from '@mui/system';
import isAnyPartOfElementInViewport from '@utils/isAnyPartOfElementInViewport';
import {
  isAdvancedButton,
  isCalculator,
  isChart,
  isColorChart,
  isCommandButton,
  isDatabox,
  isGeotagsTable,
  isHistoryTable,
  isMonitoringObject,
  isMonitorStatusTable,
  isMonitorTable,
  isObjectStatistic,
  isPropertyHistoryTable,
  isStaticTable,
  isTimer,
  isTrackingBox,
  isTrackingTable,
} from '@utils/objectType';
import { loader } from 'graphql.macro';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';

import st from './style.module.css';

const GET_OBJECT = loader('../../../graphql/GetObjectQuery.graphql');

const tabs = {
  collection: ['general', 'objects', 'notifications'],
  group: ['general', 'widgets', 'notifications'],
  object: ['general', 'properties', 'controls', 'notifications'],
  statistics: ['general', 'source', 'objects', 'notifications'],
  object_statistics: ['general', 'objects', 'notifications'],
  timeseries: ['general', 'source', 'objects', 'notifications'],
  widget: ['general', 'objects', 'notifications'],
  monitoring_object: ['general', 'objects', 'notifications'], // ['general', 'objects', 'media', 'geo', 'notifications'],
};

const SideCard = (props) => {
  const { objectId, widgetId, groupId, collectionId } = useParams();
  const { getTypeByRoute } = useRoute();
  const { menuBasedOnType } = useUniversalMenu();

  const isShowHistoryProperty = useSelector((state) => state.settings.isShowHistoryProperty);
  const id = objectId || widgetId || groupId || collectionId;
  const type = getTypeByRoute();

  const [loadObject, { data, loading, refetch, subscribeToMore }] = useLazyQuery(GET_OBJECT, {
    variables: { objectId: id },
    fetchPolicy: 'cache-and-network',
  });
  const isShowHistory = useSelector((state) => state.settings.isShowHistory);
  const isShowControlsHistory = useSelector((state) => state.settings.isShowControlsHistory);

  const theme = useTheme();
  const [tabValue, setTabValue] = useState('general');
  const topRef = useRef(null);
  const sideCard = useRef(null);
  const [sFixed, setSFixed] = useState(false); // if true (onscroll), fix header
  const [sFixedFade] = useState(true); // if false (onscroll), fade in fix header
  const [sShowBack] = useState(false); // if true (onscroll) , show go top button

  useEffect(() => {
    const unsubscribe = subscribeToMore({
      document: GET_DATA_SUBSCRIPTION,
      variables: {
        objId: id,
      },
    });

    return () => unsubscribe();
  }, [id]);

  useEffect(() => {
    loadObject().then(() => {});
  }, [objectId, groupId, widgetId]);

  useEffect(() => {
    if (type === 'group') {
      let node = document.querySelector(`[data-id=t-${id}]`);

      if (node && !isAnyPartOfElementInViewport(node)) {
        scrollIntoView(node, {
          behavior: 'smooth',
          scrollMode: 'if-needed',
          block: 'center',
          inline: 'center',
        });
      }
    } else {
      sideCard?.current?.scroll({ left: 0, top: 0 });
      setSFixed(false);
    }
  }, [objectId, groupId, widgetId]);

  if (!data || loading) return <LinearProgress style={{ width: '100%' }} />;
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const isCounterStatistic = (tags) => {
    return tags.includes('statistics') && tags.includes('counter');
  };

  const isTimeseriesStatistic = (tags) => {
    return tags.includes('statistics') && tags.includes('timeseries');
  };

  const getType = () => {
    if (data.object.schemaTags.includes('statistics') && data.object.schemaTags.includes('counter')) {
      return 'statistics';
    }

    if (data.object.schemaTags.includes('statistics') && data.object.schemaTags.includes('objects')) {
      return 'object_statistics';
    }

    if (data.object.schemaTags.includes('statistics') && data.object.schemaTags.includes('timeseries')) {
      return 'timeseries';
    }

    if (data.object.schemaTags.includes('collection')) {
      return 'collection';
    }

    if (isMonitoringObject(data.object.schemaTags)) {
      return 'monitoring_object';
    }

    return type;
  };

  const getTargetObjectId = () => {
    return data.object.id;
  };

  if (!data?.object?.schema) {
    return null;
  }

  return (
    <>
      {isShowHistory && <HistoryProperties object={data.object} />}
      {isShowControlsHistory && <HistoryControlsProperties id={data.object.id} object={data.object} />}
      <div
        style={{
          width: props.sideBarWidth,
          height: '100%',
          overflowY: 'auto',
        }}
        ref={sideCard}
        onScroll={(e) => {
          if (e.target.scrollTop > 200) {
            setSFixed(true);
          } else {
            setSFixed(false);
          }
        }}
      >
        <div ref={topRef} />
        {!isShowHistoryProperty && !isShowHistory && !isShowControlsHistory && (
          <CardToolbar item={data.object} isFixed={sFixed} menuItems={() => menuBasedOnType(data.object)} {...props} />
        )}
        <TopButton in={sShowBack} topRef={topRef} />
        <Fade in={sFixedFade}>
          <div>
            <CardImage type={type} object={data.object} />
          </div>
        </Fade>
        <NormalHeader item={data.object} {...props} />
        <Tabs
          onChange={handleChangeTab}
          value={tabs[getType(type)].find((tab) => tab === tabValue) ? tabValue : setTabValue('general')}
          aria-label="tabs"
          TabIndicatorProps={{ style: { backgroundColor: theme.palette.blue } }}
          className={st.tabs}
          style={{
            background: theme.palette.white,
          }}
        >
          {tabs[getType(type)].map((tab) => (
            <Tab
              data-test-tab={tab}
              value={tab}
              label={msg.sideCard[tab]}
              className={st.tabButton}
              aria-label={tab}
              style={{}}
              key={tab}
            />
          ))}
        </Tabs>
        <Divider style={{ position: 'relative', top: '-1px' }} />
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TabGeneralCounterStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCounterStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="source" className={st.tabPanel}>
            <TabGeneralSource item={data.object} {...props} />
          </TabPanel>
        )}
        {isObjectStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TabGeneralObjectStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isCalculator(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TabGeneralCalculatorStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TabGeneralTimeseriesStatistic item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimeseriesStatistic(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="source" className={st.tabPanel}>
            <TabSourceTimeseries item={data.object} {...props} />
          </TabPanel>
        )}
        {isTrackingBox(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TabGeneralTrackingBox item={data.object} {...props} />
          </TabPanel>
        )}
        {isHistoryTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <HistoryTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <MonitorTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitoringObject(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <MonitorObjectGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isMonitorStatusTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <MonitorStatusTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isStaticTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <StaticTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isChart(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <ChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isColorChart(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <ColorChartGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isTimer(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TimerGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {isCommandButton(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <CommandButtonGeneralTab propKey={'valueAction'} item={data.object} {...props} />
          </TabPanel>
        )}
        {isAdvancedButton(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <AdvancedButtonGeneralTab propKey={'valueValue'} item={data.object} {...props} />
          </TabPanel>
        )}
        {isTrackingTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <TrackingTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isGeotagsTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <GeotagsTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isPropertyHistoryTable(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <PropertyHistoryTableGeneral item={data.object} {...props} />
          </TabPanel>
        )}
        {isDatabox(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="general" className={st.tabPanel}>
            <DataboxGeneralTab item={data.object} {...props} />
          </TabPanel>
        )}
        {!isChart(data.object.schemaTags) &&
          !isMonitorStatusTable(data.object.schemaTags) &&
          !isStaticTable(data.object.schemaTags) &&
          !isTimer(data.object.schemaTags) &&
          !isHistoryTable(data.object.schemaTags) &&
          !isMonitorTable(data.object.schemaTags) &&
          !isAdvancedButton(data.object.schemaTags) &&
          !isCommandButton(data.object.schemaTags) &&
          !isPropertyHistoryTable(data.object.schemaTags) &&
          !isCounterStatistic(data.object.schemaTags) &&
          !isObjectStatistic(data.object.schemaTags) &&
          !isCalculator(data.object.schemaTags) &&
          !isDatabox(data.object.schemaTags) &&
          !isTrackingBox(data.object.schemaTags) &&
          !isTimeseriesStatistic(data.object.schemaTags) &&
          !isTrackingTable(data.object.schemaTags) &&
          !isGeotagsTable(data.object.schemaTags) &&
          !isMonitoringObject(data.object.schemaTags) &&
          !isColorChart(data.object.schemaTags) && (
            <TabPanel value={tabValue} index="general" className={st.tabPanel}>
              <TabContentGeneral item={data.object} {...props} />
            </TabPanel>
          )}
        <TabPanel value={tabValue} index="properties" className={st.tabPanel}>
          <TabContentProperties item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        <TabPanel value={tabValue} index="controls" className={st.tabPanel}>
          <TabContentControls item={data.object} topRef={topRef} {...props} />
        </TabPanel>
        {type === 'group' && (
          <TabPanel value={tabValue} index="widgets" className={st.tabPanel}>
            <TabContentObjects item={data.object} type={type} refetch={refetch} {...props} />
          </TabPanel>
        )}
        {isMonitoringObject(data.object.schemaTags) && (
          <TabPanel value={tabValue} index="objects" className={st.tabPanel}>
            <MonitorObjectTabObjects item={data.object} refetch={refetch} />
          </TabPanel>
        )}
        {!isMonitoringObject(data.object.schemaTags) && (type === 'widget' || type === 'object') && (
          <TabPanel value={tabValue} index="objects" className={st.tabPanel}>
            <TabContentObjects item={data.object} refetch={refetch} type={type} {...props} />
          </TabPanel>
        )}
        {type === 'collection' && (
          <TabPanel value={tabValue} index="objects" className={st.tabPanel}>
            <TabContentDashboards item={data.object} refetch={refetch} type={type} {...props} />
          </TabPanel>
        )}
        <TabPanel value={tabValue} index="notifications" className={st.tabPanel}>
          <TabContentNotifications id={getTargetObjectId()} item={data.object} {...props} type={type} />
        </TabPanel>
      </div>
    </>
  );
};

export default SideCard;
