import { gql } from '@src/__generated__';

export const LISTEN_NOTIFICATIONS = gql(/* GraphQL */ `
  subscription listenNotifications($deliveryUser: [UUID], $deliveryPath: [String]) {
    Notifications(filterA: { deliveryUser: $deliveryUser, deliveryPath: $deliveryPath }) {
      event
      relatedNode {
        ... on NotificationDelivery {
          id
          message
          createdAt
          user
          userLogin
          notification {
            id
            message
            createdAt
            userByBy {
              id
              login
              mName
            }
            byName
            tags
          }
        }
      }
    }
  }
`);
