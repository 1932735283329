import CommonModal from '@components/CommonModal';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { create } from 'react-modal-promise';
import Buttons from './components/Buttons';
import useForm from './hooks/useForm';

export interface IEditDisableProps {
  onResolve?: () => void;
  id?: string;
  enabled?: boolean;
  isOpen?: boolean;
}

const ChangeStatusModal = ({ id, enabled, isOpen, onResolve }: IEditDisableProps) => {
  const { formik, isLoading } = useForm({ onResolve, id, enabled });

  return (
    <CommonModal
      key="EditObject"
      modalOpen={isOpen}
      handleClose={onResolve}
      title={enabled ? 'Disable object' : 'Enable object'}
      buttons={<Buttons handleClose={onResolve} onSubmit={formik.onSubmit} />}
      loading={isLoading}
    >
      <Typography variant="body1" color="inherit">
        <Grid container direction="column">
          Are you sure you want to change status?
        </Grid>
      </Typography>
    </CommonModal>
  );
};

export default create(ChangeStatusModal);
