import { msg } from '@constants/messages';
import { Button } from '@mui/material';
import { FC } from 'react';

interface IProps {
  handleClose: () => void;
  isEdit?: boolean;
  onSubmit?: () => void;
}

const Buttons: FC<IProps> = ({ handleClose, onSubmit }) => (
  <>
    <Button color="inherit" onClick={handleClose}>
      {msg.default.cancel}
    </Button>
    <Button color="primary" type="submit" onClick={onSubmit}>
      {msg.default.confirm}
    </Button>
  </>
);
export default Buttons;
