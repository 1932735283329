import SchemaSelectorField from '@components/modals/SelectSchema/components/SchemaSelectorField';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps, useFormikContext } from 'formik';
import { ChangeEvent } from 'react';

import { IFormValues, validationSchema } from '../../constants/formik';

const Schema = ({ form }: FieldProps<IFormValues>) => {
  const { setFieldValue } = useFormikContext<IFormValues>();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedSchema = event.target.value;
    if (form.values.schemaId !== selectedSchema) {
      setFieldValue('schemaId', selectedSchema);
      setFieldValue('propertyId', '');
    }
  };

  return <SchemaSelectorField {...fieldRequiredProps(form, validationSchema, 'schemaId')} onChange={onChange} />;
};

export default Schema;
