import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ImageItem from '@components/common/ImageItem';
import { SelectedOption } from '@components/common/ImageItem/components/MediaAutocomplete';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import CommonModal from '@components/CommonModal';
import CustomAutocomplete from '@components/CustomAutocomplete';
import CustomInput from '@components/CustomInput';
import CustomSelect from '@components/CustomSelect';
import { BOARD_BG_OPTIONS, TITLE_STYLE } from '@components/dashboard/constants';
import DeleteReportModal from '@components/DeleteReportModal';
import SelectColor from '@components/forms/SelectColor';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import { msg } from '@constants/messages';
import { COLLECTIONS_QUERY_FULL } from '@graphql/CollectionsQuery';
import useFormikRequiredProps from '@hooks/formik/useFormikRequiredProps';
import { GET_COLLECTIONS } from '@modules/collections/api/GetCollections';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import { LOAD_GROUPS } from '@modules/groups/api/GetGroups';
import { ADD_REPORT } from '@modules/reports/api/AddReport';
import { GET_REPORT_FULL } from '@modules/reports/api/GetReport';
import { GET_REPORT_PROPS } from '@modules/reports/api/GetReportProps';
import { REPORTS_QUERY_WITHOUT_COLLECTIONS } from '@modules/reports/api/ReportsList';
import { UPDATE_PROPERTIES } from '@modules/reports/api/UpdateProperties';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import { setSettings } from '@store/settingsSlice';
import { useFormik } from 'formik';
import { ChangeEvent, ChangeEventHandler, useEffect, useReducer, useState } from 'react';
import { Renderable, toast, ValueOrFunction } from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import st from './style.module.css';

type AddReportModalProps = {
  reportId?: string;
};

type TValuesDefault = {
  generalBackgroundImageName?: string | null;
  generalBackgroundImageUid?: string | Blob | null;
  generalBgColor?: string;
  generalTitle?: string;
  generalTitleStyle?: string;
  [key: string]: unknown;
};

const AddReportModal = ({
  onResolve: submit,
  onReject: reject,
  reportId,
  isOpen,
}: AddReportModalProps & InstanceProps<{ reportId: string }>) => {
  const isEdit = Boolean(reportId);
  const history = useCustomNavigate();
  const dispatch = useDispatch();
  const handleCopy = useHandleCopy();

  const handleClose = () => reject();

  const [addDashboard] = useMutation(ADD_REPORT);
  const groupsQuery = useQuery(LOAD_GROUPS);
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const collectionsQuery = useQuery(GET_COLLECTIONS);
  const [collectionsLazyQuery] = useLazyQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: 'network-only',
  });
  const [reportQuery, { data: reportData }] = useLazyQuery(GET_REPORT_FULL);
  const [updateProperties] = useMutation(UPDATE_PROPERTIES);
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const [accessRights, setAccessRights] = useState({
    readerGroup: null,
    userGroup: null,
    editorGroup: null,
  });
  const [collection, setCollection] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [willRemoveImage, setWillRemoveImage] = useState(false);
  const { updateImageToObjectLink, uploadImageFile } = useUpdateBackgroundImage();

  const defaultValues = {
    generalBackgroundImageName: null,
    generalBackgroundImageUid: null,
    generalBgColor: '#ffffff',
    generalTitle: '',
    generalTitleStyle: 'dark',
  };

  const valuesReducer = (prev: TValuesDefault, updated: Partial<TValuesDefault>): TValuesDefault => ({
    ...prev,
    ...updated,
  });

  const [values, setValues] = useReducer(valuesReducer, defaultValues);

  const [reportsLazyQuery] = useLazyQuery(REPORTS_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    description: yup.string().trim(),
  });

  const afterCreate = (id: string) => {
    history(`/reports/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const handleUpdateReport = async (formikValues: yup.InferType<typeof validationSchema>) => {
    const previousBackgroundImageUid = reportData.object.objectProperties.find(
      (item) => item.key === 'generalBackgroundImageUid'
    ).value;
    const generalBackgroundImageUid = await uploadImageFile(values.generalBackgroundImageUid);
    await updateImageToObjectLink({
      newImageId: generalBackgroundImageUid,
      prevImageId: previousBackgroundImageUid,
      objectId: reportId,
      unlinkWithRemove: willRemoveImage,
      currentLinkId: reportData?.object?.objectsToObjectsByObject1Id?.find(
        (item) => item.object2?.id === previousBackgroundImageUid
      )?.id,
    });

    const payload = {
      objectId: reportId,
      name: formikValues.name,
      description: formikValues.description,
      keyedProperties: [
        {
          propertyKey: 'generalTitle',
          value: values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      editorgroup: accessRights.editorGroup,
      usergroup: accessRights.userGroup,
      readergroup: accessRights.readerGroup,
    };

    toast
      .promise(
        updateProperties({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }).then(() => reportsLazyQuery()),
        {
          loading: 'Updating...',
          success: () => 'Updated',
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === 'string') {
              return err.toString();
            } else {
              return 'Error appears during editing of report';
            }
          },
        }
      )
      .then(async () => {
        const currentCollectionLinkId = reportData?.object?.collections?.[0]?.id;
        if (collection) {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await linkObjects({
            variables: {
              widgetId: reportData.object.id,
              objectId: collection,
            },
          });
          await collectionsLazyQuery();
          await reportsLazyQuery();
        } else {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await collectionsLazyQuery();
          await reportsLazyQuery();
        }
      })
      .then(() => {
        submit();
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleAddDashboard = async (formikValues: yup.InferType<typeof validationSchema>) => {
    const generalBackgroundImageUid = await uploadImageFile(values.generalBackgroundImageUid);

    const payload = {
      name: formikValues.name,
      description: formikValues.description,
      values: [
        {
          propertyKey: 'generalTitle',
          value: values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      editorGroup: accessRights.editorGroup,
      userGroup: accessRights.userGroup,
      readerGroup: accessRights.readerGroup,
    };

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: 'Creating report...',
          success: () => 'Report created',
          error: (err: ValueOrFunction<Renderable, unknown>) => {
            if (typeof err === 'string') {
              return err.toString();
            } else {
              return 'Error appears during creation of report';
            }
          },
        }
      )
      .then(async ({ data }) => {
        if (collection) {
          const variables = {
            widgetId: data.createObjectWithProperties.uuid,
            objectId: collection,
          };
          await linkObjects({ variables });
          await collectionsLazyQuery();
          await reportsLazyQuery();
        } else {
          await reportsLazyQuery();
        }
        await updateImageToObjectLink({
          newImageId: generalBackgroundImageUid,
          prevImageId: null,
          objectId: data.createObjectWithProperties.uuid,
        });
        afterCreate(data.createObjectWithProperties.uuid as string);
      })
      .catch(() => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formik = useFormik({
    initialValues: { name: '', description: '' },
    validationSchema,
    onSubmit: async (formikValues) => {
      setIsLoading(true);
      if (isEdit) {
        await handleUpdateReport(formikValues);
      } else {
        await handleAddDashboard(formikValues);
      }
    },
  });

  const [dashboardsQueryProps, { loading }] = useLazyQuery(GET_REPORT_PROPS, {
    fetchPolicy: 'network-only',
    onCompleted: ({ schemata }) => {
      const localValues = { ...defaultValues };
      schemata[0].schemaProperties.forEach((prop) => {
        localValues[prop.key] = prop.defaultValue;
      });
      setValues(localValues);
      formik.setValues({ name: `Report #${schemata[0].objectsCount + 1}`, description: '' }).catch(() => {});
    },
  });

  const getGroups = () => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === 'Nobody',
      }));
    }
    return [];
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value, checked, name } = e.target;
    const val = checked || value;
    setValues({ [name]: val });
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'CopyReport',
            objectId: reportId,
            params: {
              UUID: reportId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };

  useEffect(() => {
    if (isEdit) {
      reportQuery({
        variables: {
          objId: reportId,
        },
        fetchPolicy: 'network-only',
      })
        .then(({ data }) => {
          if (data) {
            data.object.objectProperties.forEach((prop) => {
              defaultValues[prop.key] = prop.value;
            });

            setCollection((data?.object?.collections?.[0]?.target?.id as string) ?? '');
            setValues(defaultValues);
            setAccessRights({
              readerGroup: data.object.readergroup,
              userGroup: data.object.usergroup,
              editorGroup: data.object.editorgroup,
            });
            formik
              .setValues({
                name: data.object.name,
                description: data?.object?.description ?? '',
              })
              .catch(() => {});
          }
        })
        .catch(() => {});
    } else {
      dashboardsQueryProps().catch(() => {});
    }
  }, [reportId]);

  const getCollections = () => {
    if (!collectionsQuery.loading && collectionsQuery.data) {
      return collectionsQuery.data?.collections?.map((item) => ({
        value: item.id,
        title: item.name,
      })) as Array<{ value: string; title: string }>;
    }
    return [];
  };

  const formikRequiredProps = useFormikRequiredProps(formik, validationSchema);

  return (
    <>
      <CommonModal
        loading={loading || isLoading}
        modalOpen={isOpen}
        title={isEdit ? 'Edit report' : 'Add report'}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addDashboardModal.buttonCancel}
            </Button>
            <Button color="primary" data-test="createReport" onClick={() => formik.handleSubmit()}>
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              onBlur={formik.handleBlur}
              label="Name"
              clearFieldIcon={true}
              {...formikRequiredProps('name')}
            />
          </Grid>

          <Grid item>
            <CustomInput
              name="generalTitle"
              label="Title"
              clearFieldIcon={true}
              value={values.generalTitle}
              onChange={handleInputChange}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={collectionsQuery.loading}
              label={'Collection'}
              list={getCollections()}
              value={collection}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setCollection(e.target.value);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <CustomSelect
                name="generalTitleStyle"
                label="Title style"
                list={TITLE_STYLE}
                value={values.generalTitleStyle ?? 'dark'}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectColor
                name="generalBgColor"
                label={'Background color'}
                value={values.generalBgColor ?? '#333333'}
                list={BOARD_BG_OPTIONS}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item width="100%">
            <ImageItem
              name="uuid"
              withUnlink={isEdit}
              onChange={(e) => {
                setValues({ generalBackgroundImageUid: e?.target?.value });
              }}
              onDelete={() => {
                setWillRemoveImage(true);
              }}
              value={values.generalBackgroundImageUid}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={'Editors group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={'Users group'}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={'Readers group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              multiline={true}
              {...formikRequiredProps('description')}
            />
          </Grid>

          {isEdit && (
            <Grid item className={st.buttonsContainer}>
              <Button
                data-test="copyDashboardId"
                variant="outlined"
                color="primary"
                fullWidth
                disableElevation
                onClick={() => {
                  handleCopy({
                    object: {
                      name: '',
                    },
                    message: 'Report copied successfully',
                    text: reportId,
                  });
                }}
              >
                {msg.editDashboardModal.buttonCopy}
              </Button>

              <RpcSubscribeWrapper
                rpcName={'CopyReport'}
                objectId={reportId}
                object={null}
                handler={rpcHandler}
                title={'Copy Report'}
                successCb={(id: string) => {
                  window.location.href = `/reports/${id}`;
                }}
              >
                <LoadingButton
                  data-test-copy-report-rpc
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                ></LoadingButton>
              </RpcSubscribeWrapper>

              <Button
                data-test="deleteReport"
                variant="contained"
                color="error"
                fullWidth
                disableElevation
                onClick={() => {
                  DeleteReportModal({
                    dashboardId: reportId,
                    name: formik.values.name,
                  })
                    .then(() => {
                      submit();
                    })
                    .catch(() => {});
                }}
              >
                Delete report
              </Button>
            </Grid>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddReportModal);
