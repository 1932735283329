import { ObjectProperty } from '@src/__generated__/graphql';

export const getPropertyValueByKey = (properties: ObjectProperty[], key: string): ObjectProperty['value'] => {
  return properties.find((prop) => prop.key === key)?.value;
};

export const getPropertyByKey = (properties: ObjectProperty[], key: string): ObjectProperty => {
  return properties.find((prop) => prop.key === key);
};

export const getPropertiesMap = (properties: ObjectProperty[]): { [key: ObjectProperty['key']]: ObjectProperty } => {
  return properties?.reduce((acc, prop) => {
    if (!acc[prop.key]) {
      acc[prop.key] = prop;
    }
    return acc;
  }, {});
};
