import CommonModal from '@components/CommonModal';
import Grid from '@mui/material/Grid';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import Control from './components/Control';
import Parameters from './components/Parameters';
import useForm from './hooks/useForm';

interface IProps {
  onResolve: () => void;
  isOpen: boolean;
  widgetId?: string;
  propKey?: string;
}

const EditSwitchControlModal = ({ onResolve, isOpen, widgetId, propKey }: IProps) => {
  const { formik, isLoading } = useForm({ onResolve, widgetId, propKey });

  return (
    <CommonModal
      key="EditObject"
      modalOpen={isOpen}
      title="Edit control"
      handleClose={onResolve}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="control" placeholder="control" component={Control} />
      </Grid>
      <Grid container direction="column" spacing={2}>
        <FastField name="parameters" placeholder="parameters" component={Parameters} />
      </Grid>
    </CommonModal>
  );
};

export default create(EditSwitchControlModal);
