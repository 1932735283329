import InputField from '@components/create-widget/DataChartColor/components/InputField';
import SelectField from '@components/create-widget/DataChartColor/components/SelectField';
import SwitchField from '@components/create-widget/DataChartColor/components/SwitchField';
import { IFormValues } from '@components/create-widget/DataChartColor/constants';
import { ScaleOptionsEnum, SCALE_OPTIONS } from '@components/create-widget/regular-chart/constants';
import { msg } from '@constants/messages';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import {
  CHART_TYPE_OPTIONS,
  LINE_WIDTH_OPTIONS,
  SETTINGS_SIZE_OPTIONS,
  SETTINGS_STYLE_OPTIONS,
  SMOOTH_TYPE_OPTIONS,
  TypeOptionsEnum,
} from '@utils/constants/selectOptions';
import { FastField, useFormikContext } from 'formik';

const DataChartColorForm = ({ isEdit }: { isEdit: boolean }) => {
  const values = useFormikContext()?.values as IFormValues;
  const isLineChart = values?.properties?.settingsChartType === TypeOptionsEnum.line;

  const isFixedScaleYAxis = () => {
    return values.properties?.settingsYaxisScale === ScaleOptionsEnum.fixed;
  };

  return (
    <>
      <Grid container direction="column" spacing={2}>
        {isEdit && <FastField name="name" label="Name" clearFieldIcon={true} component={InputField} />}
        <Grid item>
          <Typography variant="subtitle2" color="primary">
            Content
          </Typography>
        </Grid>
        <FastField name="properties.settingsTitle" label="Title" component={SwitchField} />
        <FastField name="properties.settingsTitleSecondary" label="Subtitle" component={SwitchField} />
        <FastField name="properties.settingsSimulation" label="Simulation" component={SwitchField} />
        <FastField
          name="properties.settingsYaxisScale"
          label="Y-axis scale"
          list={SCALE_OPTIONS}
          component={SelectField}
        />
        {isFixedScaleYAxis() && (
          <>
            <Grid item>
              <Typography variant="subtitle2" color="#686868">
                Axis boundaries
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <FastField
                  type="number"
                  name="properties.settingsMinimum"
                  clearFieldIcon={true}
                  label="Min"
                  component={InputField}
                />
              </Grid>
              <Grid item xs={6}>
                <FastField
                  type="number"
                  name="properties.settingsMaximum"
                  clearFieldIcon={true}
                  label="Max"
                  component={InputField}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Grid item>
          <Typography variant="subtitle2" color="primary">
            Appearance
          </Typography>
        </Grid>
        <FastField
          name="properties.settingsStyle"
          label="Style"
          list={SETTINGS_STYLE_OPTIONS}
          component={SelectField}
        />
        <FastField name="properties.settingsSize" label="Size" list={SETTINGS_SIZE_OPTIONS} component={SelectField} />
        <FastField
          name="properties.settingsChartType"
          label="Chart type"
          list={CHART_TYPE_OPTIONS}
          component={SelectField}
        />
        {isLineChart && (
          <>
            <FastField
              name="properties.settingsSmoothType"
              label="Smooth Type"
              list={SMOOTH_TYPE_OPTIONS}
              component={SelectField}
            />
            <FastField
              name="properties.settingsLineWidth"
              label="Line width"
              list={LINE_WIDTH_OPTIONS}
              component={SelectField}
            />
            <FastField name="properties.settingsFill" label="Fill" component={SwitchField} />
          </>
        )}
        <FastField
          name="description"
          clearFieldIcon={true}
          multiline={true}
          label={msg.addWidgetModal.description}
          component={InputField}
        />
      </Grid>
    </>
  );
};

export default DataChartColorForm;
