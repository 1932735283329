import { gql } from '@src/__generated__';

export const SELECTED_SCHEMA_TYPE = gql(/* GraphQL */ `
  query selectedSchemaType($id: UUID!) {
    schema(id: $id) {
      id
      type
      mTags
    }
  }
`);
