import { useQuery } from '@apollo/client';
import MainToolbar from '@components/MainToolbar';
import useMoreMenu from '@components/useMoreMenu';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SortIcon from '@mui/icons-material/Sort';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { loader } from 'graphql.macro';
import { useState } from 'react';

import CustomListItem from './components/CustomListItem';
import st from './style.module.css';

const DASHBOARDS_QUERY = loader('../../graphql/DashboardsQuery.graphql');

const FixedHeader = (props) => {
  const { dashboardId, sortType, setSortType, itemsChecked, setItemsChecked } = props;
  const theme = useTheme();

  const { MoreMenu: HeaderMenu, openMoreMenu: openHeaderMenu, closeMoreMenu: closeHeaderMenu } = useMoreMenu();

  const { MoreMenu: HeaderGroupMenu, openMoreMenu: openHeaderGroupMenu } = useMoreMenu();
  const { MoreMenu: FilterMenu, closeMoreMenu: closeFilterMenu } = useMoreMenu();
  const { MoreMenu: SortMenu, openMoreMenu: openSortMenu, closeMoreMenu: closeSortMenu } = useMoreMenu();

  const handleHeaderMenuItemClick = (item) => {
    props.handleMenuActionClick({
      action: item.action,
      dashboardId: dashboardId,
      itemsChecked: itemsChecked,
    });
    closeHeaderMenu();
  };

  // header filter menu

  const handleFilterMenuItemClick = () => {
    closeFilterMenu();
  };

  // header sort menu

  const handleSortMenuItemClick = (item) => {
    setSortType(item);
    closeSortMenu();
  };

  const { data } = useQuery(DASHBOARDS_QUERY);

  const dashboards = data?.dashboards || [];

  return (
    <>
      {dashboards.length === 0 && (
        <div
          className={st.fixedHeader}
          style={{
            zIndex: theme.zIndex.drawer - 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <Button
            color="primary"
            className={st.button}
            endIcon={<ArrowDropDownIcon color="primary" />}
            onClick={openHeaderMenu}
          >
            <Typography variant="h6">No dashboards</Typography>
          </Button>
        </div>
      )}

      {itemsChecked.length === 0 && (
        <div
          className={st.fixedHeader}
          style={{
            zIndex: theme.zIndex.drawer - 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <MainToolbar />
          <div className={st.toolsContainer}>
            <Button
              data-dashboards-list-menu={props.dashboardName}
              color="primary"
              className={st.button}
              endIcon={<ArrowDropDownIcon color="primary" />}
              onClick={openHeaderMenu}
            >
              <Typography noWrap variant="h6" title={props.dashboardName}>
                {props.dashboardName}
              </Typography>
            </Button>
            <div className={st.toolsSpacer} />
            <IconButton onClick={openSortMenu} size="large" title="Sort">
              <SortIcon color="primary" />
            </IconButton>
          </div>
        </div>
      )}

      {itemsChecked.length > 0 && (
        <div
          className={st.fixedHeader}
          style={{
            zIndex: theme.zIndex.drawer - 1,
            backgroundColor: theme.palette.background.default,
          }}
        >
          <MainToolbar />
          <div className={st.toolsContainer}>
            <Button
              color="primary"
              className={st.button}
              startIcon={<ArrowBackIosIcon color="primary" />}
              onClick={() => {
                setItemsChecked([]);
              }}
            >
              <Typography variant="h6">Selected: {itemsChecked.length}</Typography>
            </Button>
            <div className={st.toolsSpacer} />
            <IconButton className={st.hidden} size="large">
              <StarBorderIcon color="primary" />
            </IconButton>
            <IconButton onClick={openHeaderGroupMenu} size="large">
              <MoreVertIcon color="primary" />
            </IconButton>
            <IconButton onClick={openSortMenu} size="large">
              <SortIcon color="primary" />
            </IconButton>
          </div>
        </div>
      )}

      <HeaderMenu
        items={props.headerMenu}
        handleMenuItemClick={() => handleHeaderMenuItemClick({ action: 'add_new_group' })}
      />

      <HeaderGroupMenu
        items={props.headerGroupMenu}
        handleMenuItemClick={() =>
          handleHeaderMenuItemClick({
            action: 'delete_groups',
            items: [1, 2, 3],
          })
        }
      />

      <FilterMenu
        checkable={true}
        items={[
          { title: 'No filter', id: 'no_filter', disabled: true },
          { title: 'Disable', id: 'disable', disabled: true },
        ]}
        handleMenuItemClick={handleFilterMenuItemClick}
      />

      <SortMenu
        checkable={true}
        items={[
          {
            title: 'Name',
            id: 'name',
            checked: sortType === 'name',
            disabled: false,
          },
          {
            title: 'Status',
            id: 'enabled',
            checked: sortType === 'enabled',
            disabled: false,
          },
        ]}
        handleMenuItemClick={handleSortMenuItemClick}
      />
    </>
  );
};

const SideList = (props) => {
  const { onlyHeader, getList } = props;
  const [sortType, setSortType] = useState('name');

  return (
    <>
      <div className={st.item}>
        {props.loading && <LinearProgress />}
        <FixedHeader
          {...props}
          dashboardName={props.title}
          sortType={sortType}
          setSortType={setSortType}
          itemsChecked={props.itemsChecked}
          setItemsChecked={props.setItemsChecked}
        />

        {!onlyHeader && (
          <List className={st.listWrapper}>
            {getList(sortType).map((item, index) => (
              <CustomListItem
                key={index}
                index={index}
                item={item}
                itemsChecked={props.itemsChecked}
                setItemsChecked={props.setItemsChecked}
                {...props}
              />
            ))}
          </List>
        )}
      </div>
    </>
  );
};
export default SideList;
