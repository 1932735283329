import toast from 'react-hot-toast';
import { useCopyToClipboard } from 'react-use';

const useHandleCopy = () => {
  const [state, copyToClipboard] = useCopyToClipboard();

  return ({ object = {}, text, message }) => {
    copyToClipboard(text || object?.id);

    if (state?.error?.message) {
      toast.error(state.error.message, { position: 'bottom-center' });
    } else {
      toast.success(message || `UUID copied successfully (${object?.name})`);
    }
  };
};

export default useHandleCopy;
