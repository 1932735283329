import { TYPES } from '@constants/constants';
import { WIDGETS_ENUM } from './widgetTypes';

export function isCounter(tags) {
  if (tags.includes('statistics') && tags.includes('counter')) {
    return TYPES.COUNTER;
  }
}

export const isObjectStatistic = (tags) => {
  if (tags.includes('statistics') && tags.includes('objects')) {
    return TYPES.OBJECT_STATISTIC;
  }
};

export function isCalculator(tags) {
  if (tags.includes('statistics') && tags.includes('calculator')) {
    return TYPES.COUNTER;
  }
}

export function isTimeseries(tags) {
  if (tags.includes('statistics') && tags.includes('timeseries')) {
    return TYPES.TIMESERIES;
  }
}

export function isStatistics(tags) {
  if (tags.includes('statistics')) {
    return TYPES.STATISTICS;
  }
}

export function isGroup(tags) {
  if (tags.includes('group')) {
    return TYPES.GROUP;
  }
}

export function isTitle(tags) {
  if (tags.includes(WIDGETS_ENUM.TITLE)) {
    return WIDGETS_ENUM.TITLE;
  }
}

export function isWidget(tags) {
  if (tags.includes('widget')) {
    return TYPES.WIDGET;
  }
}

export function isMonitoringItem(tags) {
  if (tags.includes('object monitoring item')) {
    return TYPES.MONITORING_ITEM;
  }
}

export function isMonitoringObject(tags) {
  if (tags.includes('monitor')) {
    return TYPES.MONITORING_ITEM;
  }
}

export function isMonitoringTitle(title = '') {
  if (title.toLowerCase().includes('pixelmonitor object item')) {
    return TYPES.MONITORING_ITEM;
  }
}

export function isHistoryTable(tags) {
  if (tags.includes(WIDGETS_ENUM.HISTORY_TABLE)) {
    return WIDGETS_ENUM.HISTORY_TABLE;
  }
}

export function isAdvancedButton(tags) {
  if (tags.includes(WIDGETS_ENUM.ADVANCED_BUTTON)) {
    return WIDGETS_ENUM.ADVANCED_BUTTON;
  }
}

export function isLine(tags) {
  if (tags.includes(WIDGETS_ENUM.LINE)) {
    return WIDGETS_ENUM.LINE;
  }
}

export function isCommandButton(tags) {
  if (tags.includes(WIDGETS_ENUM.COMMAND_BUTTON)) {
    return WIDGETS_ENUM.COMMAND_BUTTON;
  }
}

export function isTrackingTable(tags) {
  if (tags.includes(WIDGETS_ENUM.TRACKING_TABLE)) {
    return WIDGETS_ENUM.TRACKING_TABLE;
  }
}

export function isGeotagsTable(tags) {
  if (tags.includes(WIDGETS_ENUM.GEOTAGS_TABLE)) {
    return WIDGETS_ENUM.GEOTAGS_TABLE;
  }
}

export function isPropertyHistoryTable(tags) {
  if (tags.includes(WIDGETS_ENUM.PROPERTY_HISTORY_TABLE)) {
    return WIDGETS_ENUM.PROPERTY_HISTORY_TABLE;
  }
}

export function isMonitorTable(tags) {
  if (tags.includes(WIDGETS_ENUM.MONITOR_TABLE)) {
    return WIDGETS_ENUM.MONITOR_TABLE;
  }
}

export function isMonitorStatusTable(tags) {
  if (tags.includes(WIDGETS_ENUM.MONITOR_STATUS)) {
    return WIDGETS_ENUM.MONITOR_STATUS;
  }
}

export function isStaticTable(tags) {
  if (tags.includes(WIDGETS_ENUM.STATIC_TABLE)) {
    return WIDGETS_ENUM.STATIC_TABLE;
  }
}

export function isChart(tags) {
  if (tags.includes(WIDGETS_ENUM.DATACHART)) {
    return WIDGETS_ENUM.DATACHART;
  }
}

export function isColorChart(tags) {
  if (tags.includes(WIDGETS_ENUM.DATACHART_COLOR)) {
    return WIDGETS_ENUM.DATACHART_COLOR;
  }
}
export function isDatabox(tags) {
  if (tags.includes(WIDGETS_ENUM.DATABOX)) {
    return WIDGETS_ENUM.DATABOX;
  }
}

export function isTrackingBox(tags) {
  if (tags.includes(WIDGETS_ENUM.GEO_TIMER)) {
    return WIDGETS_ENUM.GEO_TIMER;
  }
}

export function isDashboard(tags) {
  if (tags.includes(TYPES.DASHBOARD)) {
    return TYPES.DASHBOARD;
  }
}

export function isReport(tags) {
  if (tags.includes(TYPES.REPORT)) {
    return TYPES.REPORT;
  }
}

export function isCollection(tags) {
  if (tags.includes(TYPES.COLLECTION)) {
    return TYPES.COLLECTION;
  }
}

export function isTimer(tags) {
  if (tags.includes(WIDGETS_ENUM.TIMER)) {
    return WIDGETS_ENUM.TIMER;
  }
}

export function getObjectType(tags) {
  if (isCounter(tags)) {
    return TYPES.COUNTER;
  } else if (isObjectStatistic(tags)) {
    return TYPES.OBJECT_STATISTIC;
  } else if (isCalculator(tags)) {
    return TYPES.CALCULATOR;
  } else if (isTimeseries(tags)) {
    return TYPES.TIMESERIES;
  } else if (isDashboard(tags)) {
    return TYPES.DASHBOARD;
  } else if (isCollection(tags)) {
    return TYPES.COLLECTION;
  } else if (isWidget(tags)) {
    return TYPES.WIDGET;
  } else if (isGroup(tags)) {
    return TYPES.GROUP;
  } else if (isReport(tags)) {
    return TYPES.REPORT;
  } else {
    return TYPES.OBJECT;
  }
}
