import { useLazyQuery, useQuery } from '@apollo/client';
import { RPCS_QUERY } from '@modules/rpcs/api/GetRpc';
import { GET_WIDGET } from '@modules/widgets/api/GetWidget';
import { FieldProps } from 'formik';
import { useEffect } from 'react';
import { IFormValues } from '../../../constants/formik';
import getParamsRpc from '../../Control/utils/getParamsRpc';

const useControl = (form: FieldProps<IFormValues>['form']) => {
  const { data } = useQuery(GET_WIDGET, {
    variables: { objId: form?.values?.widgetId },
  });

  const [getRpcs, { data: rpcsList, error }] = useLazyQuery(RPCS_QUERY);

  const action = data?.object?.objectProperties?.find((obj) => obj.key === form.values.propKey)?.value;
  const valueAction = data?.object?.objectProperties?.find((obj) => obj.key === 'valueAction')?.value;

  const rpcsListFormated = rpcsList?.object?.schema?.schemaControls
    ?.filter((item) => item?.isRpc)
    ?.map((item) => {
      return { value: item.rpc, title: item.description };
    });

  useEffect(() => {
    if (form?.values?.control && rpcsList) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      form.setFieldValue('parameters', getParamsRpc(form?.values?.control as string, rpcsList, action?.params));
    }
  }, [form?.values?.control, rpcsList, action?.params]);

  useEffect(() => {
    if (!action) return;
    getRpcs({
      variables: { deviceId: valueAction?.objectId },
      fetchPolicy: 'network-only',
    });
  }, [action]);

  return { error, rpcsListFormated, valueAction };
};

export default useControl;
