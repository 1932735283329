import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import CustomInput from '../../../../../../components/CustomInput';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const SecondID: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item>
    <CustomInput
      {...fieldRequiredProps(form, validationSchema, 'secondID')}
      autoComplete="secondID"
      label={'Second ID'}
      clearFieldIcon={true}
    />
  </Grid>
);

export default SecondID;
