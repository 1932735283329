import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import GpsNotFixedIcon from '@mui/icons-material/GpsNotFixed';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';

import { EGpsStatuses } from '../../constants';
import st from './style.module.css';

const gpsIcons = {
  [EGpsStatuses.FIXED]: <GpsFixedIcon fontSize="inherit" />,
  [EGpsStatuses.NOT_FIXED]: <GpsNotFixedIcon fontSize="inherit" />,
  [EGpsStatuses.OFF]: null,
};

interface IProps {
  statusesGps?: ObjectProperty;
}

const GpsStatus = ({ statusesGps }: IProps) => {
  const theme = useTheme();
  const status = statusesGps.value || statusesGps;

  if (!gpsIcons[status]) return null;

  return (
    <span
      className={st.icon}
      style={{ color: status === EGpsStatuses.NOT_FIXED ? theme.palette.red : theme.palette.gray1 }}
    >
      {gpsIcons[status]}
    </span>
  );
};

export default GpsStatus;
