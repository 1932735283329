import * as yup from 'yup';

export interface IFormValues {
  name: string;
  color: string;
  leftEnd: string;
  rightEnd: string;
  orientation: string;
  width: string;
  coverImage: string;
  description: string;
}

export const validationSchema = yup.object({});
