import { gql } from '@src/__generated__';

export const SEARCH_MEDIA = gql(/* GraphQL */ `
  query getObjectsByIdOrName($query: String!) {
    objects(
      first: 5
      filter: {
        schemaType: { equalTo: "media" }
        or: [{ name: { includesInsensitive: $query } }, { stringId: { includesInsensitive: $query } }]
      }
    ) {
      value: id
      title: name
    }
  }
`);
