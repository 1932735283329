import CustomPeriodModal from '@components/modals/CustomPeriodModal/CustomPeriodModal';
import DateModal from '@components/modals/DateModal';
import useMoreMenu from '@components/useMoreMenu';
import useRoute from '@hooks/useRoute';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import GetAppIcon from '@mui/icons-material/GetApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TodayIcon from '@mui/icons-material/Today';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { formatISO, sub } from 'date-fns';
import { useState } from 'react';
import st from './style.module.css';

interface IProps {
  name: string;
  setRecordedFilter: (value: [string, string]) => void;
  setAfter: (value: string) => void;
  downloadHistory: (range: [Date, Date], ids: string[], shape?: 'wide' | 'long') => Promise<void>;
  downloadIds: string[];
  recordedFilter: [string, string];
}

const HistoryToolbar = ({
  setRecordedFilter,
  setAfter,
  downloadHistory,
  downloadIds,
  name,
  recordedFilter,
}: IProps) => {
  const { MoreMenu: HistoryMenu, openMoreMenu: openHistoryMenu } = useMoreMenu();

  const { handleGoBack } = useRoute();

  const [date, setDate] = useState('day');

  const changeCustomDate = (start: Date, end: Date) => {
    setRecordedFilter([formatISO(start), formatISO(end)]);
  };

  const menu = [
    {
      icon: <TodayIcon />,
      title: 'Day',
      id: 'day',
      disabled: false,
      checked: date === 'day',
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { days: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('day');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Week',
      id: 'week',
      checked: date === 'week',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { weeks: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('week');
      },
    },
    {
      icon: <TodayIcon />,
      title: 'Month',
      id: 'month',
      checked: date === 'month',
      disabled: false,
      handleAction: () => {
        setRecordedFilter([formatISO(sub(Date.now(), { months: 1 })), formatISO(Date.now())]);
        setAfter(null);
        setDate('month');
      },
    },
    {
      id: 'divider',
    },
    {
      icon: <TodayIcon />,
      title: 'Custom period',
      id: 'custom',
      checked: date === 'custom',
      disabled: false,
      handleAction: () => {
        CustomPeriodModal({
          defaultStart: recordedFilter?.[0],
          defaultEnd: recordedFilter?.[1],
          selectRange: changeCustomDate,
        })
          .then()
          .catch(() => {});
        setAfter(null);
        setDate('custom');
      },
    },
    {
      id: 'divider',
    },
    {
      icon: <GetAppIcon />,
      title: 'Download',
      id: 'download history',
      disabled: false,
      handleAction: () => {
        DateModal({
          enableWide: true,
          downloadHistory,
          downloadIds,
        })
          .then()
          .catch(() => {});
      },
    },
  ];

  return (
    <>
      <HistoryMenu checkable items={menu} />
      <Toolbar variant="dense" className={st.header}>
        <IconButton className={st.backButton} onClick={handleGoBack} size="medium">
          <ArrowBackIcon />
        </IconButton>
        <Typography className={st.name} variant="h6">
          {name}
        </Typography>
        <IconButton aria-label="settings" onClick={openHistoryMenu} size="large" data-test-settings="settings">
          <MoreVertIcon />
        </IconButton>
      </Toolbar>

      <Divider />
    </>
  );
};

export default HistoryToolbar;
