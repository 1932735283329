import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import ImageItem from '@components/common/ImageItem';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import CommonModal from '@components/CommonModal';
import CustomInput from '@components/CustomInput';
import CustomSelect from '@components/CustomSelect';
import DeleteDashboardModal from '@components/DeleteDashboardModal';
import SelectColor from '@components/forms/SelectColor';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import { msg } from '@constants/messages';
import { DASHBOARDS_PROPS_QUERY } from '@graphql/queries';
import useIsFieldRequired from '@hooks/formik/useIsFieldRequired';
import { GET_COLLECTIONS } from '@modules/collections/api/GetCollections';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { setSettings } from '@store/settingsSlice';
import { useFormik } from 'formik';
import { SignJWT } from 'jose';
import { ChangeEventHandler, useEffect, useReducer, useState } from 'react';
import { toast } from 'react-hot-toast';
import { create, InstanceProps } from 'react-modal-promise';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { BOARD_BG_OPTIONS, DASHBOARD_DEFAULT_FORM, TDashboardForm, TITLE_STYLE } from '../constants';

import { OptionValue, SelectedOption } from '@components/common/ImageItem/components/MediaAutocomplete';
import CustomAutocomplete from '@components/CustomAutocomplete';
import useCustomNavigate from '@components/hooks/useCustomNavigate';
import { ADD_DASHBOARD } from '@graphql/AddDashboard';
import { COLLECTIONS_QUERY_FULL } from '@graphql/CollectionsQuery';
import { DASHBOARDS_QUERY } from '@graphql/DashboardsQuery';
import { UPDATE_OBJECT_PROPERTY } from '@graphql/UpdateObjectWithProperties';
import { LINK_OBJECTS, UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import { DASHBOARD_QUERY } from '@modules/dashboards/api/GetDashboard';
import { DASHBOARD_NAMES_QUERY_WITHOUT_COLLECTIONS } from '@modules/dashboards/api/GetDashboardNamesWithoutCollections';
import { LOAD_ALL_GROUPS } from '@modules/dashboards/api/LoadAllGroups';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import st from './style.module.css';

type AddDashboardModalProps = {
  isEdit: boolean;
  dashboardId: string;
};

const AddDashboardModal = ({
  isEdit,
  dashboardId,
  onResolve,
  onReject,
  isOpen,
}: AddDashboardModalProps & InstanceProps<{}>) => {
  const history = useCustomNavigate();
  const [linkObjects] = useMutation(LINK_OBJECTS);
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const [addDashboard] = useMutation(ADD_DASHBOARD);
  const [updateProperty] = useMutation(UPDATE_OBJECT_PROPERTY);
  const collectionsQuery = useQuery(GET_COLLECTIONS);
  const groupsQuery = useQuery(LOAD_ALL_GROUPS);
  const { updateImageToObjectLink, uploadImageFile } = useUpdateBackgroundImage();

  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const [dashboardsLazyQuery] = useLazyQuery(DASHBOARDS_QUERY, {
    fetchPolicy: 'network-only',
  });

  const [dashboardsWithoutCollectionsLazyQuery] = useLazyQuery(DASHBOARD_NAMES_QUERY_WITHOUT_COLLECTIONS, {
    fetchPolicy: 'network-only',
  });

  const [collectionsLazyQuery] = useLazyQuery(COLLECTIONS_QUERY_FULL, {
    fetchPolicy: 'network-only',
  });

  const [willRemoveImage, setWillRemoveImage] = useState(false);

  const defaultValues = {
    ...DASHBOARD_DEFAULT_FORM,
  };

  const [values, setValues] = useReducer(
    (prev: TDashboardForm, updated: Partial<TDashboardForm>) => ({ ...prev, ...updated }),
    defaultValues
  );
  const [accessRights, setAccessRights] = useState({
    readerGroup: '',
    userGroup: '',
    editorGroup: '',
  });
  const [collection, setCollection] = useState<string>('');

  const validationSchema = yup.object({
    name: yup.string().trim().required('Name is required'),
    description: yup.string().trim(),
    generalTitle: yup.string().nullable().trim(),
  });
  const dispatch = useDispatch();
  const submit = () => onResolve();
  const reject = () => onReject();

  const formik = useFormik({
    initialValues: {
      name: '',
      generalTitle: null,
      description: '',
    },
    validationSchema,
    onSubmit: async () => {
      if (isEdit) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleUpdateDashboard();
      } else {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        await handleAddDashboard();
      }
    },
  });

  const [dashboardsQueryProps, { loading: dashboardsQueryPropsLoading }] = useLazyQuery(DASHBOARDS_PROPS_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const dashBoardsAmount = data.schemata[0].objectsCount;
      const addingZeroCondition = dashBoardsAmount <= 8 ? '0' : '';
      formik
        .setValues({
          name: `Dashboard #${addingZeroCondition}${dashBoardsAmount + 1}`,
          description: '',
          generalTitle: null,
        })
        .catch(() => {});
    },
  });

  const [dashboardLazyQuery, { data: dashboard }] = useLazyQuery(DASHBOARD_QUERY, {
    variables: {
      objId: dashboardId,
    },
    onCompleted: (data) => {
      const title = data.object.objectProperties.find((item) => item.key === 'generalTitle').value;
      const backgroundImageId = data.object.objectProperties.find(
        (item) => item.key === 'generalBackgroundImageUid'
      ).value;
      const generalTitleStyle = data.object.objectProperties.find((item) => item.key === 'generalTitleStyle').value;
      const generalBgColor = data.object.objectProperties.find((item) => item.key === 'generalBgColor').value;
      setAccessRights({
        readerGroup: data.object.readergroup,
        userGroup: data.object.usergroup,
        editorGroup: data.object.editorgroup,
      });
      formik
        .setValues({
          name: data.object.name ?? '',
          generalTitle: title ?? null,
          description: data.object.description ?? '',
        })
        .catch(() => {});
      setCollection((data.object?.collections?.[0]?.target?.id as string) || '');
      setValues({
        generalBgColor,
        generalTitleStyle,
        generalBackgroundImageUid: backgroundImageId ?? null,
      });
    },
    fetchPolicy: 'network-only',
  });

  const getCollections = () => {
    if (!collectionsQuery.loading && collectionsQuery.data) {
      return collectionsQuery.data.collections.map((item) => ({
        value: item.id,
        title: item.name,
      }));
    }
    return [];
  };

  const getGroups = (): OptionValue[] => {
    if (!groupsQuery.loading && groupsQuery.data) {
      return groupsQuery.data.userGroups?.map((item) => ({
        value: item.id,
        title: item.groupName,
        disabled: item.groupName === 'Nobody',
      })) as OptionValue[];
    }
    return [] as OptionValue[];
  };

  const afterCreate = (id: string) => {
    history(`/boards/${id}`);
    dispatch(setSettings({ isEditMode: true }));
    submit();
  };

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'CopyDashboard',
            objectId: dashboardId,
            params: {
              UUID: dashboardId,
              NAME: `${formik.values.name} copy`,
            },
          },
        },
      },
    });
  };

  const handleAddDashboard = async () => {
    const generalBackgroundImageUid = await uploadImageFile(values.generalBackgroundImageUid);

    const payload = {
      editorGroup: accessRights?.editorGroup || undefined,
      readerGroup: accessRights?.readerGroup || undefined,
      userGroup: accessRights?.userGroup || undefined,
      values: [
        {
          propertyKey: 'generalTitle',
          value: formik.values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      name: formik.values.name,
      description: formik.values.description,
    };

    toast
      .promise(
        addDashboard({
          variables: payload,
        }),
        {
          loading: 'Creating dashboard...',
          success: () => 'Dashboard created',
          error: (err) => `${err.toString()}`,
        }
      )
      .then(async ({ data }) => {
        if (collection) {
          const variables = {
            widgetId: data.createObjectWithProperties.uuid,
            objectId: collection,
          };

          await linkObjects({ variables });
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
        } else {
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
        }
        await updateImageToObjectLink({
          newImageId: generalBackgroundImageUid,
          prevImageId: null,
          objectId: data.createObjectWithProperties.uuid,
        });
        afterCreate(data.createObjectWithProperties.uuid as string);
      })
      .catch(() => {});
  };

  const handleUpdateDashboard = async () => {
    const previousBackgroundImageUid = dashboard.object.objectProperties.find(
      (item) => item.key === 'generalBackgroundImageUid'
    ).value;
    const generalBackgroundImageUid = await uploadImageFile(values.generalBackgroundImageUid);

    await updateImageToObjectLink({
      newImageId: generalBackgroundImageUid,
      prevImageId: previousBackgroundImageUid,
      objectId: dashboardId,
      unlinkWithRemove: willRemoveImage,
      currentLinkId: dashboard?.object?.objectsToObjectsByObject1Id?.find(
        (item) => item.object2?.id === previousBackgroundImageUid
      )?.id,
    });
    const payload = {
      editorgroup: accessRights?.editorGroup || undefined,
      readergroup: accessRights?.readerGroup || undefined,
      usergroup: accessRights?.userGroup || undefined,
      objectId: dashboardId,
      keyedProperties: [
        {
          propertyKey: 'generalTitle',
          value: formik.values.generalTitle,
        },
        {
          propertyKey: 'generalTitleStyle',
          value: values.generalTitleStyle,
        },
        {
          propertyKey: 'generalBgColor',
          value: values.generalBgColor,
        },
        {
          propertyKey: 'generalBackgroundImageUid',
          value: generalBackgroundImageUid,
        },
        {
          propertyKey: 'generalBackgroundImageName',
          value: values.generalBackgroundImageName,
        },
      ],
      name: formik.values.name,
      description: formik.values.description,
    };

    toast
      .promise(
        updateProperty({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        }),
        {
          loading: 'Saving dashboard...',
          success: () => 'Dashboard saved',
          error: (err) => `${err.toString()}`,
        }
      )
      .then(async () => {
        const currentCollectionLinkId = dashboard?.object?.collections?.[0]?.id;
        if (collection) {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await linkObjects({
            variables: {
              widgetId: dashboard.object.id,
              objectId: collection,
            },
          });
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
        } else {
          if (currentCollectionLinkId) {
            await unlinkObjects({
              variables: {
                linkId: currentCollectionLinkId,
              },
            });
          }
          await collectionsLazyQuery();
          await dashboardsLazyQuery();
          await dashboardsWithoutCollectionsLazyQuery();
        }

        submit();
      })
      .catch(() => {});
  };

  const handleCopy = useHandleCopy();

  const handleClose = () => reject();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { name, value, checked } = e.target;
    const val: string | boolean = checked || value;
    setValues({ [name]: val } as Pick<TDashboardForm, keyof TDashboardForm>);
  };

  const getLinkToView = async () => {
    const secret = new TextEncoder().encode('cc7e0d44fd473002f1c42167459001140ec6389b7353f8088f4d9a95f2f596f2');
    const alg = 'HS256';

    const jwt = await new SignJWT({
      dashboardId,
      type: 'board',
      token: localStorage.getItem('refreshToken'),
    })
      .setProtectedHeader({ alg })
      .setIssuedAt()
      .setExpirationTime('1y')
      .sign(secret);

    handleCopy({
      message: 'Link to view copied',
      text: `${window.location.origin}/view/?token=${jwt}/?mode=0`,
    });
  };

  useEffect(() => {
    if (isEdit) {
      dashboardLazyQuery().catch(() => {});
    } else {
      dashboardsQueryProps().catch(() => {});
    }
  }, [isEdit]);

  const isFieldRequired = useIsFieldRequired(validationSchema);

  return (
    <>
      <CommonModal
        loading={dashboardsQueryPropsLoading}
        modalOpen={isOpen}
        title={isEdit ? 'Edit dashboard' : 'Add dashboard'}
        forceTitle={true}
        contentStyles={{
          padding: '14px 16px 16px 14px',
        }}
        handleClose={handleClose}
        buttons={
          <>
            <Button color="inherit" onClick={handleClose}>
              {msg.addDashboardModal.buttonCancel}
            </Button>
            <Button
              color="primary"
              data-test="createBoard"
              onClick={() => {
                formik.handleSubmit();
              }}
            >
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomInput
              required={isFieldRequired('name')}
              shrink={Boolean(formik.values?.name)}
              name="name"
              label={msg.addDashboardModal.name}
              clearFieldIcon={true}
              value={formik.values?.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
          </Grid>
          <Grid item>
            <CustomInput
              name="generalTitle"
              label="Title"
              clearFieldIcon={true}
              value={formik.values?.generalTitle}
              onChange={formik.handleChange}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={collectionsQuery.loading}
              label={'Collection'}
              list={getCollections()}
              value={collection}
              onChange={(e: SelectedOption) => {
                setCollection(e.target.value as string);
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid container item spacing={2}>
            <Grid item xs={6}>
              <CustomSelect
                required={isFieldRequired('generalTitleStyle')}
                name="generalTitleStyle"
                label="Title style"
                list={TITLE_STYLE}
                value={values.generalTitleStyle ?? 'dark'}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item xs={6}>
              <SelectColor
                name="generalBgColor"
                label={'Background color'}
                value={values.generalBgColor ?? '#333333'}
                list={BOARD_BG_OPTIONS}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>

          <Grid item width="100%">
            <ImageItem
              name="uuid"
              withUnlink={isEdit}
              onChange={(e) => {
                setValues({ generalBackgroundImageUid: e?.target?.value });
              }}
              onDelete={() => {
                setWillRemoveImage(true);
              }}
              value={values.generalBackgroundImageUid}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Access rights
            </Typography>
          </Grid>

          <Grid item>
            <CustomAutocomplete
              label={'Editors group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.editorGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  editorGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              disabled={groupsQuery.loading}
              label={'Users group'}
              list={getGroups()}
              value={accessRights.userGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  userGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>
          <Grid item>
            <CustomAutocomplete
              label={'Readers group'}
              disabled={groupsQuery.loading}
              list={getGroups()}
              value={accessRights.readerGroup}
              onChange={(e: SelectedOption) => {
                setAccessRights({
                  ...accessRights,
                  readerGroup: e.target.value,
                });
              }}
              clearFieldIcon={true}
            />
          </Grid>

          <Grid item>
            <Typography variant="subtitle2" color="primary">
              Description
            </Typography>
          </Grid>

          <Grid item>
            <CustomInput
              required={isFieldRequired('description')}
              name="description"
              label={msg.addDashboardModal.description}
              clearFieldIcon={true}
              value={formik.values?.description}
              multiline={true}
              onChange={formik.handleChange}
            />
          </Grid>

          {isEdit && (
            <>
              <Grid item container className={st.buttonsContainer}>
                <Button
                  data-test="copyDashboardId"
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    handleCopy({
                      message: msg.editDashboardModal.copied,
                      text: dashboardId,
                    });
                  }}
                >
                  {msg.editDashboardModal.buttonCopy}
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    getLinkToView().catch(() => {});
                  }}
                >
                  Get link to view
                </Button>

                <RpcSubscribeWrapper
                  rpcName={'CopyBoard'}
                  objectId={dashboardId}
                  object={null}
                  handler={rpcHandler}
                  title={'Copy Board'}
                  successCb={(dashboardIdLocal: string) => {
                    if (dashboardIdLocal) {
                      window.location.href = `/boards/${dashboardIdLocal}`;
                    }
                  }}
                >
                  <LoadingButton
                    data-test-copy-board-rpc
                    variant="outlined"
                    color="primary"
                    fullWidth
                    disableElevation
                  ></LoadingButton>
                </RpcSubscribeWrapper>

                <Button
                  data-test="deleteDashboard"
                  variant="contained"
                  color="error"
                  fullWidth
                  disableElevation
                  onClick={() => {
                    DeleteDashboardModal({
                      dashboardId,
                      name: formik.values.name,
                    })
                      .then(() => {
                        submit();
                      })
                      .catch(() => {});
                  }}
                >
                  {msg.editDashboardModal.buttonDelete}
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </CommonModal>
    </>
  );
};

export default create(AddDashboardModal);
