import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import useControl from './hooks/useControl';

const defaultErrorText = {
  error: true,
  helperText: 'First create an object',
};

const Control: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { error, rpcsListFormated, valueAction } = useControl(form);

  const isLogicError = !valueAction?.objectId
    ? { helperText: 'First select an object', disabled: true, error: true }
    : {};

  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'control')}
        {...(error ? defaultErrorText : null)}
        label="Control"
        list={rpcsListFormated}
        {...isLogicError}
      />
    </Grid>
  );
};

export default Control;
