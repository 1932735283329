import { msg } from '@constants/messages';
import useSmoothScrollToElement from '@hooks/useScrollToId';
import { Backdrop, Button, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';
import { Form, Formik } from 'formik';

import st from './style.module.css';

const DefaultButtons = ({ handleClose }) => (
  <>
    <Button color="inherit" onClick={handleClose}>
      {msg.default.cancel}
    </Button>
    <Button color="primary" type="submit" disabled={false}>
      {msg.default.save}
    </Button>
  </>
);

const CommonModal = (props) => {
  const { modal = true, forceTitle = true } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const scrollToElement = useSmoothScrollToElement();

  const content = (
    <>
      {props.loading && (
        <Backdrop
          sx={{
            color: '#fff',
            background: '#ffffff80',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={true}
          style={{ position: 'absolute' }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
      )}
      {(fullScreen || forceTitle) && (
        <Typography className={st.modalTitle} variant="h6" color="primary">
          {props.title}
        </Typography>
      )}
      <DialogContent className={st.modalContent} dividers={true}>
        {props.isForm ? props.formik && props.children : props.children}
      </DialogContent>

      <DialogActions className={st.modalActions}>
        {props.buttons || (props.isForm && <DefaultButtons handleClose={props?.handleClose} />)}
      </DialogActions>
    </>
  );

  return (
    <>
      <Dialog
        data-test-modal={props?.title?.toLowerCase()}
        open={props.modalOpen}
        fullScreen={fullScreen}
        onClose={props.handleClose}
        PaperProps={{
          classes: { root: st.modalPaper },
          style: {
            ...(props.width ? { width: props.width } : null),
            ...(!modal ? { pointerEvents: 'auto' } : null),
            ...props.paperStyles,
          },
        }}
        {...(!modal ? { hideBackdrop: true } : {})}
        {...(!modal ? { disableEnforceFocus: true } : {})}
        {...(!modal ? { style: { pointerEvents: 'none' } } : {})}
      >
        {props.isForm && props.formik ? (
          <Formik {...props.formik}>
            {({ handleSubmit, ...arg }) => (
              <Form
                {...arg}
                className={st.formContainer}
                onSubmit={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleSubmit(e);
                  props?.formik?.validationSchema?.validate?.(arg?.values, { abortEarly: false })?.catch((err) => {
                    scrollToElement(err?.inner[0]?.path);
                  });
                }}
              >
                {content}
              </Form>
            )}
          </Formik>
        ) : (
          content
        )}
      </Dialog>
    </>
  );
};

export default CommonModal;
