import { useMutation } from '@apollo/client';
import DeleteDashboardModal from '@components/DeleteReportModal';
import ConfirmRpcExecution from '@components/modals/ConfirmRpcExecution';
import { msg } from '@constants/messages';
import { UNLINK_OBJECTS } from '@modules/collections/api/LinkObjects';
import DeleteIcon from '@mui/icons-material/Delete';
import ExtensionIcon from '@mui/icons-material/Extension';
import MemoryIcon from '@mui/icons-material/Memory';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import ReplyIcon from '@mui/icons-material/Reply';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/system';
import { useParams } from 'react-router-dom';
import { ReactComponent as ImgBoard } from '../../assets/board.svg';
import useRoute from '../../hooks/useRoute';
import useCustomNavigate from '../hooks/useCustomNavigate';
import ApplicationCog from '../icons/applicationCog';
import useMoreMenu from '../useMoreMenu';
import st from './tab.module.css';

const ObjectListItem = (props) => {
  const [unlinkObjects] = useMutation(UNLINK_OBJECTS);
  const { item, objectsToObjectsByObject2Id } = props;
  const theme = useTheme();
  const { getTypeByRoute, isReport, entityId } = useRoute();
  const type = getTypeByRoute();
  const { groupId, widgetId } = useParams();
  const history = useCustomNavigate();

  // to highlight current object
  const handleMouseOver = () => {
    // dispatch(setContext({ objectHoveredId: e.currentTarget.id }));
  };
  // to highlight current object
  const handleMouseLeave = () => {
    // dispatch(setContext({ objectHoveredId: null }));
  };

  const { MoreMenu, openMoreMenu, closeMoreMenu } = useMoreMenu();

  const handleMenuItemClick = () => {
    closeMoreMenu();
  };

  const handleClickItem = (e) => {
    const section = isReport() ? 'reports' : 'boards';

    if (type === 'widget') {
      history(`/${section}/${entityId()}/${groupId}/${widgetId}/${e.currentTarget.id}`);
    } else if (type === 'group') {
      history(`/${section}/${entityId()}/${groupId}/${e.currentTarget.id}`);
    }
  };

  if (!item) {
    return null;
  }
  const objectToRemoveId = objectsToObjectsByObject2Id?.find((e) => e.object1.id === item.id)?.id;

  return (
    <>
      <MoreMenu
        handleMenuItemClick={handleMenuItemClick}
        items={[
          {
            icon: <ReplyIcon />,
            title: 'Remove',
            id: 'remove_collection',
            disabled: true,
            handleAction: () => {
              ConfirmRpcExecution({
                handleSave: () => {
                  unlinkObjects({
                    variables: {
                      linkId: objectToRemoveId,
                    },
                  }).then();
                },
                text: 'Remove object?',
              })
                .then()
                .catch(() => {});
            },
          },
          {
            icon: <DeleteIcon />,
            title: 'Delete',
            id: 'delete_collection',
            handleAction: () => {
              DeleteDashboardModal({ open: true, dashboardId: object.id, name: object.name })
                .then(() => {})
                .catch(() => {});
            },
          },
        ]}
      />
      <ListItem
        data-test-obj-list-item={item.name}
        key={item.id}
        id={item.id}
        button
        alignItems="center"
        classes={{
          container: st.itemToHover,
        }}
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
      >
        {type === 'group' && (
          <ListItemAvatar>
            <Avatar>
              <ImgBoard />
            </Avatar>
          </ListItemAvatar>
        )}

        {type !== 'group' && (
          <ListItemIcon>
            {item.schemaType === 'dataset' && <ExtensionIcon />}
            {item.schemaType === 'device' && <MemoryIcon />}
            {item.schemaType === 'application' && <ApplicationCog />}
          </ListItemIcon>
        )}

        <ListItemText
          id={item.id}
          onClick={handleClickItem}
          primary={<Typography variant="body1">{item.name}</Typography>}
          secondary={
            <Box component="span" sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                component="span"
                variant="body2"
                noWrap={true}
                style={{ color: theme.palette.gray1, paddingRight: '6px' }}
              >
                {item.enabled ? <span>{msg.sideCard.enabled}</span> : <span>{msg.sideCard.disabled}</span>}
              </Typography>
              {item.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'on' && (
                <NotificationsIcon fontSize={'small'} style={{ color: theme.palette.gray1, fontSize: '16px' }} />
              )}
              {item.objectProperties.find((obj) => obj.key === 'statusAlarm')?.value === 'triggered' && (
                <NotificationsActiveIcon fontSize={'small'} style={{ color: theme.palette.red, fontSize: '16px' }} />
              )}
            </Box>
          }
        />

        <ListItemSecondaryAction className={st.itemToHideOrShow}>
          <IconButton
            edge="end"
            data-test-obj-list-item={`contextMenu_${item.name}`}
            aria-label="more"
            onClick={(e) => {
              e.preventDefault();
              openMoreMenu(e);
            }}
            size="large"
          >
            <MoreVertIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </>
  );
};

export default ObjectListItem;
