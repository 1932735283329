import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/system';

import st from './style.module.css';

const ConfirmationModal = (props) => {
  const { modal = true } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    props.handleClose();
  };

  return (
    <>
      <Dialog
        open={props.modalOpen}
        fullScreen={fullScreen}
        onClose={handleClose}
        PaperProps={{
          classes: { root: st.modalPaper },
          ...(!modal ? { style: { pointerEvents: 'auto' } } : {}),
        }}
        {...(!modal ? { hideBackdrop: true } : {})}
        {...(!modal ? { disableEnforceFocus: true } : {})}
        {...(!modal ? { style: { pointerEvents: 'none' } } : {})}
      >
        <DialogContent className={st.modalContent}>
          <Typography variant="subtitle2" style={{ color: theme.palette.gray1 }}>
            {props.children}
          </Typography>
        </DialogContent>

        <Divider />
        {props.buttons && <DialogActions className={st.modalActions}>{props.buttons}</DialogActions>}
      </Dialog>
    </>
  );
};

export default ConfirmationModal;
