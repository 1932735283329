import { FormatCommandButtonEnum } from '@components/create-widget/databox/DataboxTypes';
import { FieldProps } from 'formik';
import { IFormValues } from '../../../constants/formik';

type IProps = FieldProps<IFormValues>['form'];

const setFieldsByVariant = (form: IProps, variant: FormatCommandButtonEnum) => {
  switch (variant) {
    case FormatCommandButtonEnum.button_text:
      form.setFieldValue('text', '');
      form.setFieldValue('switchTextOff', null);
      form.setFieldValue('switchTextOn', null);
      break;

    case FormatCommandButtonEnum.switcher_text:
      form.setFieldValue('text', null);
      form.setFieldValue('switchTextOff', '');
      form.setFieldValue('switchTextOn', '');
      break;

    case FormatCommandButtonEnum.button:
    case FormatCommandButtonEnum.switcher:
      form.setFieldValue('text', null);
      form.setFieldValue('switchTextOff', null);
      form.setFieldValue('switchTextOn', null);
      break;
  }
};

export default setFieldsByVariant;
