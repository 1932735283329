import ArrowDown from '@components/icons/arrowDown';
import ArrowUp from '@components/icons/arrowUp';
import ArrowUpDown from '@components/icons/arrowUpDown';
import FunctionIcon from '@components/icons/functionIcon';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { setSettings } from '@store/settingsSlice';
import { hideNull } from '@utils/hideNull';
import { useDispatch } from 'react-redux';

import tabStyle from '@components/side-card/tab.module.css';

const TabGeneralTimeseriesStatistic = (props) => {
  const { item } = props;

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const dispatch = useDispatch();

  const handleCopy = useHandleCopy();

  const propValue = () => {
    return item.objectProperties.find((item) => item.key === 'valueValue');
  };

  const propValueMinimum = () => {
    return item.objectProperties.find((item) => item.key === 'valueMinimum');
  };

  const propValueMaximum = () => {
    return item.objectProperties.find((item) => item.key === 'valueMaximum');
  };

  const propValueAverage = () => {
    return item.objectProperties.find((item) => item.key === 'valueAverage');
  };

  return (
    <>
      <ValueMenu
        items={[
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              dispatch(setSettings({ isShowHistory: true, historyPropertyId: obj.propId }));
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy_uuid',
            handleAction: (obj) => {
              handleCopy({
                text: obj.propValue,
                message: 'Value copied',
              });
            },
            disabled: false,
          },
        ]}
      />
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Value</Typography>
        </ListSubheader>

        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValue()?.value.y)}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValue().key,
                  propValue: JSON.stringify(propValue().value),
                  propId: propValue().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowDown />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              handleCopy({
                text: item.id,
                message: 'Copied',
              });
            }}
            primary={<Typography variant="body1">{hideNull(propValueMinimum().value)}</Typography>}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueMinimum().key,
                  propValue: JSON.stringify(propValueMinimum().value),
                  propId: propValueMinimum().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowUp />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValueMaximum().value)}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueMaximum().key,
                  propValue: JSON.stringify(propValueMaximum().value),
                  propId: propValueMaximum().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ArrowUpDown />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{hideNull(propValueAverage().value)}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: propValueAverage().key,
                  propValue: JSON.stringify(propValueAverage().value),
                  propId: propValueAverage().id,
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralTimeseriesStatistic;
