import CommonModal from '@components/CommonModal';
import Grid from '@mui/material/Grid';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import Name from './components/Name';
import useForm from './hooks/useForm';

interface IProps {
  onResolve: () => void;
  isOpen: boolean;
  widgetId?: string;
  propKey?: string;
}

const EditObjectModal = ({ onResolve, isOpen, propKey, widgetId }: IProps) => {
  const { formik, isLoading } = useForm({ onResolve, widgetId, propKey });

  return (
    <CommonModal
      key="EditObject"
      modalOpen={isOpen}
      title="Edit object"
      handleClose={onResolve}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="name" placeholder="name" component={Name} />
      </Grid>
    </CommonModal>
  );
};

export default create(EditObjectModal);
