import { useLazyQuery } from '@apollo/client';
import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { IFormValues, validationSchema } from '../../constants/formik';
import { SELECTED_SCHEMA_PROPERTIES } from './api';

const Property = ({ form }: FieldProps<IFormValues>) => {
  const [propertyList, setPropertyList] = useState([]);
  const { values } = useFormikContext<IFormValues>();

  const [loadSchemaProperties] = useLazyQuery(SELECTED_SCHEMA_PROPERTIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: values.schemaId,
    },
    onCompleted: (res) => {
      setPropertyList(
        res?.schema?.schemaProperties?.map((prop) => ({
          value: `${prop?.groupName}/${prop.property}`,
          title: prop.description ?? prop.property,
        }))
      );
    },
  });

  useEffect(() => {
    if (values.schemaId) {
      void loadSchemaProperties();
    }
  }, [values.schemaId]);

  return (
    <>
      <Grid item>
        <CustomSelect
          {...fieldRequiredProps(form, validationSchema, 'propertyId')}
          label="Property"
          list={propertyList}
        />
      </Grid>
    </>
  );
};

export default Property;
