import { gql, useMutation } from '@apollo/client';
import ClockOutline from '@components/icons/clockOutline';
import FunctionIcon from '@components/icons/functionIcon';
import MapMarkerRadius from '@components/icons/mapMarkerRadius';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import SelectSchema from '@components/modals/SelectSchema';
import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import FilterGeotagsModal from '@components/side-card/monitor-status-table/FilterGeotagsModal';
import FilterGroupModal from '@components/side-card/monitor-status-table/FilterGroupModal';
import ParameterDateRange from '@components/side-card/static-table/modals/ParameterDateRange';
import RpcSubscribeWrapper from '@components/side-card/static-table/RpcSubscribeWrapper';
import { Label } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import EventIcon from '@mui/icons-material/Event';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import TuneIcon from '@mui/icons-material/Tune';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { CREATE_CONTROL_EXECUTION } from '@shared/api/CreateControlExecution';
import { format, parseISO } from 'date-fns';
import { loader } from 'graphql.macro';
import { Suspense, useEffect, useState } from 'react';

import EditFunction from './EditFunction';
import HumanReadableProperty from './HumanReadableProperty';
import ParameterTimeRange from './ParameterTimeRange';

import tabStyle from '@components/side-card/tab.module.css';

const UPDATE_OBJECT_PROPERTIES = loader('../../../graphql/UpdateObjectWithProperties.graphql');

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const GET_OBJECT_NAME_BY_ID = gql`
  query getProperty($id: UUID!) {
    object(id: $id) {
      id
      name
    }
  }
`;

const GET_SCHEMA_BY_ID = gql`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
    }
  }
`;

const TabGeneralTrackingBox = (props) => {
  const { item } = props;
  const [createExecution] = useMutation(CREATE_CONTROL_EXECUTION);

  const getProperty = (key) => {
    return item.objectProperties.find((item) => item.key === key);
  };

  const dateRange = () => {
    const formatDate = (date) => format(parseISO(date), 'dd-MM-yyyy');
    const value = getProperty('parametersDateRange').value;

    if (!value?.length) {
      return 'n/a';
    }

    return `${formatDate(value[0])} — ${formatDate(value[1])}`;
  };

  const parametersTimePeriod = () => {
    const value = getProperty('parametersTimePeriod').value;

    if (!value?.length) {
      return 'n/a';
    }

    return `${value[0]} — ${value[1]}`;
  };

  const parametersGeotag = () => {
    const geoTagValue = getProperty('parametersGeotag').value;

    return {
      query: GET_OBJECT_NAME_BY_ID,
      config: {
        variables: {
          id: geoTagValue,
        },
      },
    };
  };

  const functionInfo = () => {
    const funcValue = getProperty('parametersFunction').value;
    const funcName = getProperty('parametersFunction')?.spec?.valueSet?.list?.find(
      (item) => item.key === funcValue
    )?.title;

    const units = getProperty('parametersFunctionUnits').value;
    let resultName = '';

    if (!funcName) {
      return 'n/a';
    } else {
      resultName += funcName;

      if (units) {
        resultName += ` (${units})`;
      }

      return resultName;
    }
  };

  const parametersSchemaSelect = () => {
    const geoTagValue = getProperty('filtersSchema').value;

    return {
      query: GET_SCHEMA_BY_ID,
      config: {
        variables: {
          id: geoTagValue,
        },
      },
    };
  };

  const parametersGroup = () => {
    const filtersGroup = getProperty('filtersGroup').value;

    return {
      query: GET_OBJECT_NAME_BY_ID,
      config: {
        variables: {
          id: filtersGroup,
        },
      },
    };
  };

  const [updateProperty] = useMutation(UPDATE_PROPERTY);
  const [updateProperties] = useMutation(UPDATE_OBJECT_PROPERTIES);

  const [isGetDataDisabled, setIsGetDataDisabled] = useState(true);

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const handleUpdateProperties = (values) => {
    const valuesReady = [...values];

    return updateProperties({
      variables: {
        input: {
          detailedObject: [{ objectId: item.id, keyedProperties: valuesReady }],
        },
      },
    });
  };

  useEffect(() => {
    if (item) {
      const conditions = [
        getProperty('parametersGeotag').value,
        getProperty('parametersTimePeriod').value?.length,
        getProperty('parametersDateRange').value?.length,
      ];

      setIsGetDataDisabled(!conditions.every((item) => item));
    }
  }, [item]);

  const rpcHandler = () => {
    return createExecution({
      variables: {
        input: {
          controlExecution: {
            name: 'UpdateReport',
            objectId: item.id,
            params: {},
          },
        },
      },
    });
  };

  return (
    <>
      {/*{isShowHistoryProperty && (*/}
      {/*  <HistoryProperty id={idProperty} object={props.item} />*/}
      {/*)}*/}
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Parameters</Typography>
        </ListSubheader>

        {/* Geotag */}
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} onClick={() => {}}>
          <ListItemIcon>
            <MapMarkerRadius />
          </ListItemIcon>
          <ListItemText onClick={() => {}}>
            {getProperty('parametersGeotag').value && (
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <HumanReadableProperty
                    payload={parametersGeotag()}
                    getValue={(data) => data?.object?.name || 'n/a'}
                  ></HumanReadableProperty>
                </Suspense>
              </>
            )}
            {!getProperty('parametersGeotag').value && <span>n/a</span>}
          </ListItemText>
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getProperty('parametersGeotag');
                FilterGeotagsModal({
                  value: { value: target.value },
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object.value,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {/*Date range */}
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <EventIcon></EventIcon>
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{dateRange()}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              onClick={() => {
                const target = getProperty('parametersDateRange');

                ParameterDateRange({
                  formatStart: 'yyyy-MM-dd',
                  formatEnd: 'yyyy-MM-dd',
                  selected: { value: target },
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: date.value,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                }).then(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        {/* Time */}
        <ListItem
          classes={{
            container: tabStyle.itemToHover,
          }}
          style={{ height: '48px', paddingRight: '96px' }}
          button
          onClick={() => {}}
        >
          <ListItemIcon>
            <ClockOutline></ClockOutline>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{parametersTimePeriod()}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              onClick={() => {
                const target = getProperty('parametersTimePeriod');

                ParameterTimeRange({
                  selected: { value: target.value },
                  save: (date) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: date.value,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                }).then(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem style={{ height: '48px' }} classes={{ container: tabStyle.itemToHover }} button onClick={() => {}}>
          <ListItemIcon>
            <FunctionIcon />
          </ListItemIcon>
          <ListItemText onClick={() => {}} primary={<Typography variant="body1">{functionInfo()}</Typography>} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const parametersFunction = getProperty('parametersFunction');
                const parametersFunctionUnits = getProperty('parametersFunctionUnits');

                EditFunction({
                  handleSave: handleUpdateProperties,
                  properties: {
                    parametersFunction,
                    parametersFunctionUnits,
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Filters</Typography>
        </ListSubheader>

        <ListItem
          button
          classes={{
            container: tabStyle.itemToHover,
          }}
          onClick={() => {}}
        >
          <ListItemIcon>
            <SubtitlesIcon />
          </ListItemIcon>
          <ListItemText
            onClick={() => {
              // handleCopy({ object: item });
            }}
            primary={
              <Typography variant="body1">
                {getProperty('filtersSchema').value && (
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <HumanReadableProperty
                        payload={parametersSchemaSelect()}
                        getValue={(data) => data?.schema?.name || 'n/a'}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!getProperty('filtersSchema').value && <span>All types</span>}
              </Typography>
            }
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              onClick={() => {
                const target = getProperty('filtersSchema');
                SelectSchema({
                  handleSave: handleUpdateProperty,
                  id: target.id,
                  value: target.value,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        {/*Group name */}
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <Label />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">
                {getProperty('filtersGroup').value && (
                  <>
                    <Suspense fallback={<div>Loading...</div>}>
                      <HumanReadableProperty
                        payload={parametersGroup()}
                        getValue={(data) => data?.object?.name || 'n/a'}
                      ></HumanReadableProperty>
                    </Suspense>
                  </>
                )}
                {!getProperty('filtersGroup').value && <span>All groups</span>}
              </Typography>
            }
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={() => {
                const target = getProperty('filtersGroup');
                FilterGroupModal({
                  value: { value: target.value },
                  save: (object) => {
                    updateProperty({
                      variables: {
                        input: {
                          id: target.id,
                          patch: {
                            value: object?.value || null,
                          },
                        },
                      },
                    }).then(() => {});
                  },
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TuneIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography variant="body1">{getProperty('filtersLinkedOnly').value ? 'Linked only' : 'All'}</Typography>
            }
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              onClick={() => {
                const target = getProperty('filtersLinkedOnly');

                EditDynamicProperty({
                  handleSave: handleUpdateProperty,
                  property: target,
                  id: target.id,
                  value: target.value,
                })
                  .then()
                  .catch(() => {});
              }}
            >
              <EditIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>

        <Box m={1} mb={4}>
          <RpcSubscribeWrapper
            rpcName={'UpdateReport'}
            objectId={item.id}
            disabled={isGetDataDisabled}
            object={item}
            handler={rpcHandler}
            title={'Get data'}
          >
            <LoadingButton fullWidth={true} variant="outlined"></LoadingButton>
          </RpcSubscribeWrapper>
        </Box>

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TabGeneralTrackingBox;
