import { gql } from '@src/__generated__';

export const DASHBOARD_QUERY = gql(/* GraphQL */ `
  query getDashboard($objId: UUID!) {
    object(id: $objId) {
      id
      name
      description
      schemaName
      enabled
      muted
      readergroup
      usergroup
      editorgroup
      collections: objectsToObjectsByObject1Id(filter: { object2: { schemaTags: { contains: "collection" } } }) {
        id
        target: object2 {
          name
          id
        }
      }
      objectsToObjectsByObject1Id(filter: { object1Id: { equalTo: $objId } }) {
        id
        object2 {
          id
          name
          description
          schemaType
        }
      }
      objectProperties(sort: { sortBy: { field: PROPERTY } }) {
        groupName
        property
        spec {
          units
          description
          type {
            name
          }
          valueSet
        }
        key
        value
      }
    }
  }
`);
