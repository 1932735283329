import { toast } from 'react-hot-toast';

const showMessages = {
  onError: (error: { message?: string }) => {
    toast.error(error?.message);
    throw new Error(error?.message);
  },
  onCompleted: () => {
    toast.success('Data updated.');
  },
};

export default showMessages;
