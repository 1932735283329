import * as yup from 'yup';

export interface IFormValues {
  name: string;
  enabled: boolean;
  schemaId: string;
  propertyId: string;
  functionId: string;
  linkedOnly: boolean;
  description: string;
}

export type CommonFieldProps = {
  label: string;
  list?: { title: string; value: string }[];
  clearFieldIcon?: boolean;
  multiline?: boolean;
};

export const validationSchema = yup.object({
  name: yup.string().trim().required('Name is required'),
});
