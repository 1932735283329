import { useMutation } from '@apollo/client';
import { useUpdateBackgroundImage } from '@components/common/ImageItem/hooks/useUpdateBackgroundImage';
import { UPDATE_OBJECT_PROPERTY } from '@graphql/UpdateObjectWithProperties';
import { RootState } from '@src/store';
import { updateProfile } from '@store/profileSlice';
import { setSettings } from '@store/settingsSlice';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { IFormValues, validationSchema } from '../constants/formik';

interface IProps {
  onResolve: () => void;
}

const useForm = ({ onResolve }: IProps) => {
  const [updateProperty, { loading }] = useMutation(UPDATE_OBJECT_PROPERTY);
  const dispatch = useDispatch();

  const { uploadImageWithoutLink } = useUpdateBackgroundImage();

  const lang = useSelector((state: RootState) => state.settings.lang);
  const profile = useSelector((state: RootState) => state.profile);

  const formik = {
    initialValues: {
      programAppTitle: profile.programAppTitle || '',
      lang: lang || '',
      logo: profile?.programCompanylogoimageuid || null,
    },
    validationSchema,
    onSubmit: async (values: IFormValues) => {
      const logoUid = await uploadImageWithoutLink(profile?.programCompanylogoimageuid as string, values.logo);

      const valuesReady = Object.keys(values).map((key) => {
        if (key === 'logo') {
          return { propertyKey: 'programCompanylogoimageuid', value: logoUid };
        }
        return { propertyKey: key, value: values[key] };
      });

      toast
        .promise(
          updateProperty({
            variables: {
              input: {
                detailedObject: [{ objectId: profile.id, keyedProperties: valuesReady }],
              },
            },
          }),
          {
            loading: 'Updating...',
            success: () => 'Updated',
            error: (err) => `${err.toString()}`,
          }
        )
        .then(() => {
          dispatch(
            updateProfile({
              programCompanylogoimageuid: logoUid,
              programAppTitle: values.programAppTitle,
            })
          );
          dispatch(
            setSettings({
              lang: values.lang,
            })
          );
          onResolve();
        });
    },
  };

  return { formik, isLoading: loading };
};

export default useForm;
