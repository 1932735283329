import Grid from '@mui/material/Grid';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import CommonModal from '../../../../components/CommonModal';
import Buttons from './components/Buttons';
import Description from './components/Description';
import EditorsGroup from './components/EditorsGroup';
import Email from './components/Email';
import Icon from './components/Icon';
import Name from './components/Name';
import Phone from './components/Phone';
import Photo from './components/Photo';
import ReadersGroup from './components/ReadersGroup';
import SecondID from './components/SecondID';
import UsersGroup from './components/UsersGroup';
import useForm from './hooks/useForm';

interface IProps {
  onResolve: () => void;
  isOpen: boolean;
}

const EditProfile = ({ onResolve, isOpen }: IProps) => {
  const { formik, isLoading } = useForm({ onResolve });

  return (
    <CommonModal
      key="EditUser"
      modalOpen={isOpen}
      title="Edit user"
      handleClose={onResolve}
      buttons={<Buttons handleClose={onResolve} />}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      <Grid container direction="column" spacing={2}>
        <FastField name="name" placeholder="name" component={Name} />
        <Grid container item direction="row" spacing={2}>
          <FastField name="phone" placeholder="phone" component={Phone} />
          <FastField name="email" placeholder="email" component={Email} />
        </Grid>
        <FastField name="secondID" placeholder="secondID" component={SecondID} />
        <FastField name="editorsGroup" placeholder="editorsGroup" component={EditorsGroup} />
        <FastField name="usersGroup" placeholder="usersGroup" component={UsersGroup} />
        <FastField name="readersGroup" placeholder="readersGroup" component={ReadersGroup} />
        <FastField name="photo" placeholder="photo" component={Photo} />
        <FastField name="icon" placeholder="icon" component={Icon} />
        <FastField name="description" placeholder="description" component={Description} />
      </Grid>
    </CommonModal>
  );
};

export default create(EditProfile);
