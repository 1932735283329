import { Typography } from '@mui/material';
import { useTheme } from '@mui/system';

const Subtitle = (props) => {
  const theme = useTheme();
  return (
    <Typography variant="subtitle2" style={{ color: theme.palette.blue }}>
      {props.children}
    </Typography>
  );
};

export default Subtitle;
