import { useSelectWidget } from '@components/create-widget/useSelectWidget';
import DeleteWidgetModal from '@components/DeleteWidgetModal';
import { msg } from '@constants/messages';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Fade } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { useTheme } from '@mui/system';
import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import st from './style.module.css';

const WidgetEditControls = (props) => {
  const { groupId, widgetId } = useParams();

  const { id, name, group, widgetType } = props;

  const { selectByType } = useSelectWidget({ group });

  const theme = useTheme();
  const isEditMode = useSelector((state) => state.settings.isEditMode);

  const checkIsMayShow = () => {
    if (!isEditMode || !groupId) return false;

    if (groupId !== props.groupId) return false;

    return !widgetId || props.object.id === widgetId;
  };

  return (
    <Fade in={checkIsMayShow()}>
      <div className={st.wrapper} style={{ zIndex: theme.zIndex.drawer + 1 }}>
        <Grid container justifyContent="center">
          <Grid item style={{ whiteSpace: 'nowrap' }}>
            <Tooltip title={props.object.name}>
              <IconButton
                data-test-widget-control-edit={props.object.name}
                className={st.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={() => {
                  selectByType({
                    widgetType,
                    name: props.object.name,
                    widget: props.object,
                    group,
                  });
                }}
                size="large"
              >
                <EditIcon fontSize="small" style={{ color: theme.palette.white }} />
              </IconButton>
            </Tooltip>

            <Tooltip title={msg.widgetEdit.delete}>
              <IconButton
                data-test-widget-control-delete={props.object.name}
                className={st.iconButton}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                onClick={() => {
                  DeleteWidgetModal({
                    widgetId: id,
                    groupId: groupId,
                    name,
                  })
                    .then()
                    .catch(() => {});
                }}
                size="large"
              >
                <DeleteIcon fontSize="small" style={{ color: theme.palette.white }} />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </div>
    </Fade>
  );
};

export default React.memo(WidgetEditControls);
