import { gql } from '@src/__generated__';

const UPDATE_OBJECT = gql(/* GraphQL */ `
  mutation update($input: UpdateObjectInput!) {
    updateObject(input: $input) {
      clientMutationId
    }
  }
`);

export { UPDATE_OBJECT };
