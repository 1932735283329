import { gql, useLazyQuery, useMutation } from '@apollo/client';
import LabelIcon from '@components/icons/labelIcon';
import EditDynamicProperty from '@components/modals/EditDynamicProperty';
import SelectDevice from '@components/modals/SelectDevice';
import SelectDeviceProperty from '@components/modals/SelectDeviceProperty';
import useHandleCopy from '@components/side-card/menu/handlers/useHandleCopy';
import useMoreMenu from '@components/useMoreMenu';
import { msg } from '@constants/messages';
import EditIcon from '@mui/icons-material/Edit';
import ExtensionIcon from '@mui/icons-material/Extension';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TodayIcon from '@mui/icons-material/Today';
import { ListItemSecondaryAction } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';
import { setSettings } from '@store/settingsSlice';
import { hideNull } from '@utils/hideNull';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import tabStyle from '@components/side-card/tab.module.css';

const GET_OBJECT = gql`
  query getObject($id: UUID!) {
    object(id: $id) {
      id
      name
      objectProperties {
        id
        key
        spec {
          units
          key
          type {
            name
          }
          description
          property
        }
      }
    }
  }
`;

const UPDATE_PROPERTY = gql`
  mutation updateProperty($input: UpdateObjectPropertyInput!) {
    updateObjectProperty(input: $input) {
      clientMutationId
    }
  }
`;

const TabSourceTimeseries = (props) => {
  const { item } = props;
  const dispatch = useDispatch();

  const [updateProperty] = useMutation(UPDATE_PROPERTY);

  const [humanReadableObject, setHumanReadableObject] = useState('n/a');
  const [humanReadableProperty, setHumanReadableProperty] = useState('n/a');

  const handleCopy = useHandleCopy();

  const { MoreMenu: ValueMenu, openMoreMenu: openValueMenu } = useMoreMenu();

  const handleUpdateProperty = (id, value) => {
    return updateProperty({
      variables: {
        input: {
          id,
          patch: {
            value,
          },
        },
      },
    });
  };

  const settingsPeriod = () => {
    return item.objectProperties.find((item) => item.key === 'settingsPeriod');
  };

  const settingsProperty = () => {
    return item.objectProperties.find((item) => item.key === 'settingsProperty');
  };

  const settingsObject = () => {
    return item.objectProperties.find((item) => item.key === 'settingsObject');
  };

  const [loadObject] = useLazyQuery(GET_OBJECT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: settingsObject().value,
    },
    onCompleted: (data) => {
      setHumanReadableObject(data.object.name);

      data.object.objectProperties.forEach((property) => {
        if (property.id === settingsProperty().value) {
          setHumanReadableProperty(`${property.spec.description || property.spec.property}`);
        }
      });
    },
  });

  useEffect(() => {
    if (settingsObject().value) {
      loadObject().then(() => {});
    } else {
      setHumanReadableObject('n/a');
      setHumanReadableProperty('n/a');
    }
  }, [item]);

  return (
    <>
      <ValueMenu
        items={[
          {
            icon: <EditIcon />,
            title: 'Edit',
            id: 'edit_prop',
            handleAction: (obj) => {
              switch (obj.type) {
                case 'property':
                  SelectDeviceProperty({
                    handleSave: handleUpdateProperty,
                    objectId: obj.objectId,
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
                case 'object':
                  SelectDevice({
                    handleSave: handleUpdateProperty,
                    id: obj.propId,
                    value: obj.propValue,
                  })
                    .then()
                    .catch(() => {});
                  break;
                case 'period':
                  EditDynamicProperty({
                    handleSave: handleUpdateProperty,
                    id: obj.propId,
                    value: obj.propValue,
                    property: settingsPeriod(),
                  })
                    .then()
                    .catch(() => {});
                  break;
              }
            },
            disabled: false,
          },
          {
            icon: <HistoryIcon />,
            title: 'Show history',
            id: 'show_history',
            handleAction: (obj) => {
              dispatch(setSettings({ isShowHistory: true, historyPropertyId: obj.propId }));
            },
            disabled: false,
          },
          {
            icon: <FilterNoneIcon />,
            title: msg.default.copy,
            id: 'copy',
            handleAction: (obj) => {
              handleCopy({
                text: obj.propValue,
                message: 'Copied',
              });
            },
            disabled: false,
          },
        ]}
      />
      <List>
        <ListSubheader color="primary" className={tabStyle.listSubheader}>
          <Typography variant="subtitle2">Settings</Typography>
        </ListSubheader>

        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{humanReadableObject}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsObject().key,
                  propValue: settingsObject().value,
                  propId: settingsObject().id,
                  type: 'object',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{humanReadableProperty}</Typography>} onClick={() => {}} />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  objectId: settingsObject().value,
                  propKey: settingsProperty().key,
                  propValue: settingsProperty().value,
                  propId: settingsProperty().id,
                  type: 'property',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
        <ListItem classes={{ container: tabStyle.itemToHover }} style={{ height: '48px' }} button onClick={() => {}}>
          <ListItemIcon>
            <TodayIcon />
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body1">{hideNull(settingsPeriod().value)}</Typography>}
            onClick={() => {}}
          />
          <ListItemSecondaryAction className={tabStyle.itemToHideOrShow}>
            <IconButton
              edge="end"
              aria-label="more"
              onClick={(e) => {
                openValueMenu(e, {
                  ...props.item,
                  propKey: settingsPeriod().key,
                  propValue: settingsPeriod().value,
                  propId: settingsPeriod().id,
                  type: 'period',
                });
              }}
              size="large"
            >
              <MoreVertIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </>
  );
};

export default TabSourceTimeseries;
