import st from '@components/side-card/advanced-button/style.module.css';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StatusListItemNull = () => {
  return (
    <ListItem
      classes={{
        container: st.listItemWrapper,
      }}
      style={{ height: '48px', paddingRight: '48px' }}
    >
      <ListItemIcon>
        <HelpOutlineIcon />
      </ListItemIcon>
      <ListItemText primary={<Typography variant="body1">Status: n/a</Typography>} />
    </ListItem>
  );
};

export default StatusListItemNull;
