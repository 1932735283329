import { gql } from '@src/__generated__';

const GET_OBJECT = gql(/* GraphQL */ `
  query getObjectNameById($objectId: UUID!) {
    object(id: $objectId) {
      name
    }
  }
`);

export { GET_OBJECT };
