import { gql } from '@src/__generated__';

export const COLLECTIONS_QUERY_FULL = gql(/* GraphQL */ `
  query getFullCollections {
    dashboards: objects(
      filter: { schemaTags: { contains: ["application", "board", "collection"] } }
      orderBy: NAME_ASC
    ) {
      id
      enabled
      name
      objectsToObjectsByObject2Id {
        id
        object1 {
          id
          name
          enabled
          schemaTags
          ...AccessGroupsFragment
        }
      }
    }
  }
`);
