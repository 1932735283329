import AccessSection from '@components/side-card/basic/AccessSection';
import DescriptionSection from '@components/side-card/basic/DescriptionSection';
import ServiceSection from '@components/side-card/basic/ServiceSection';
import PropListItem from '@components/side-card/PropListItem';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import Typography from '@mui/material/Typography';

import tabStyle from '@components/side-card/tab.module.css';

const TabContentGeneral = (props) => {
  const { item, type, dashboardId, groupId, widgetId } = props;

  // group properties by groupName (array of properties -> array of groupNames)
  const grouping = (arr, key) =>
    (arr || []).reduce((acc, x = {}) => ({ ...acc, [x[key]]: [...(acc[x[key]] || []), x] }), {});

  const groups = grouping(item.objectProperties, 'groupName');
  const groupNames = Object.keys(groups);

  const linkedObject = (key) => (key === 'valueValue' ? item.objectsToObjectsByObject1Id : '');

  return (
    <>
      <List>
        {groupNames.map((groupName) => {
          if (groupName !== 'Value') return false;

          const headline = (
            <ListSubheader color="primary" key={groupName} className={tabStyle.listSubheader}>
              <Typography variant="subtitle2">{groupName}</Typography>
            </ListSubheader>
          );

          return [
            headline,
            groups[groupName].map((item, index) => (
              <PropListItem
                linkedObjects={linkedObject(item.key)}
                data-test-value={groupName}
                tab="general"
                key={index}
                index={index}
                obj={props.item}
                item={item}
                dashboardId={dashboardId}
                widgetId={widgetId}
                groupId={groupId}
                type={type}
              />
            )),
          ];
        })}

        <ServiceSection classes={tabStyle} item={item} />

        <AccessSection classes={tabStyle} item={item} />

        {item.description && <DescriptionSection classes={tabStyle} item={item} />}
      </List>
    </>
  );
};

export default TabContentGeneral;
