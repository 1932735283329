import { CommonFieldProps, IFormValues, validationSchema } from '@components/create-widget/DataChartColor/constants';
import CustomSwitch from '@components/CustomSwitch';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

const SwitchField = ({ form, field, label }: FieldProps<IFormValues> & CommonFieldProps) => (
  <Grid item container justifyContent="space-between" alignItems="center">
    <CustomSwitch {...fieldRequiredProps(form, validationSchema, field.name)} label={label} />
  </Grid>
);

export default SwitchField;
