import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { Zoom } from '@mui/material';
import Fab from '@mui/material/Fab';
import { useTheme } from '@mui/system';

import st from './style.module.css';

function TopButton(props) {
  const theme = useTheme();
  //const trigger = useScrollTrigger({    disableHysteresis: true,    threshold: 100  });
  //const trigger = true;

  const handleClick = () => {
    //    const anchor = (event.target.ownerDocument || document).querySelector('#top');
    //  if (anchor) {
    //anchor.scrollIntoView({ behavior: 'auto', block: 'center' });
    // props.topRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    //}
  };

  return (
    <Zoom in={props.in}>
      <div className={st.root}>
        <Fab
          onClick={handleClick}
          style={{ backgroundColor: theme.palette.white }}
          size="small"
          aria-label="scroll back to top"
        >
          <KeyboardArrowUp />
        </Fab>
      </div>
    </Zoom>
  );
}

export default TopButton;
