import CustomInput from '@components/CustomInput';
import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { ChangeEvent, FC } from 'react';
import { IFormValues } from '../../constants/formik';
import st from './style.module.css';

const Parameters: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item className={st.parametersWrapper}>
    {form?.values?.parameters?.map((item, index: number) => (
      <CustomInput
        value={item?.value}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          form.setFieldValue(`parameters.${index}.value`, e.target.value);
        }}
        key={`item-${index}-${item?.widgetId}`}
        label={`Parameter name: ${item?.title}`}
        clearFieldIcon={true}
      />
    ))}
  </Grid>
);

export default Parameters;
