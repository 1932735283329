import { gql } from '@src/__generated__';

const GET_CONTROLS = gql(/* GraphQL */ `
  query getControls($first: Int, $offset: Int, $filter: ControlExecutionFilter, $orderBy: [ControlExecutionsOrderBy!]) {
    controlExecutionsConnection(first: $first, offset: $offset, filter: $filter, orderBy: $orderBy) {
      totalCount
      edges {
        node {
          params
          type
          id
          linkedControlId
          name
          createdAt
          objectId
          caller {
            mName
          }
          ack
          done
          error
          callerId
        }
      }
    }
  }
`);

export { GET_CONTROLS };
