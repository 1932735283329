import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';

import { IFormValues, LANGUAGE_LIST, validationSchema } from '../../constants/formik';

const Language = ({ form, field }: FieldProps<IFormValues>) => {
  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, field.name)}
        label="Default language"
        list={LANGUAGE_LIST}
      />
    </Grid>
  );
};

export default Language;
