import CommonModal from '@components/CommonModal';
import Grid from '@mui/material/Grid';
import { ObjectProperty } from '@src/__generated__/graphql';
import { FastField } from 'formik';
import { create } from 'react-modal-promise';
import Buttons from './components/Buttons';
import Description from './components/Description';
import Name from './components/Name';
import Size from './components/Size';
import Style from './components/Style';
import Text from './components/Text';
import WidgetType from './components/WidgetType';
import useForm from './hooks/useForm';

export interface IAdvancedButtonWidgetModalProps {
  onResolve?: () => void;
  isOpen?: boolean;
  widget?: { id: string };
  isEdit?: boolean;
  group?: {
    id: string;
    type: ObjectProperty;
    objectProperties: ObjectProperty[];
  };
  name?: string;
}

const AdvancedButtonWidgetModal = ({
  onResolve,
  isOpen,
  widget,
  isEdit,
  group,
  name,
}: IAdvancedButtonWidgetModalProps) => {
  const { formik, isLoading } = useForm({ onResolve, widget, isEdit, group, name });

  return (
    <CommonModal
      key="EditObject"
      modalOpen={isOpen}
      title={isEdit ? 'Edit object' : 'Add object'}
      handleClose={onResolve}
      buttons={<Buttons isEdit={isEdit} handleClose={onResolve} />}
      isForm={true}
      loading={isLoading}
      formik={formik}
    >
      {isEdit ? (
        <Grid container direction="column" spacing={2}>
          <FastField name="widgetType" placeholder="widgetType" component={WidgetType} />
          <FastField name="name" placeholder="name" component={Name} />
          <FastField name="text" placeholder="text" component={Text} />
          <FastField name="size" placeholder="size" component={Size} />
          <FastField name="style" placeholder="style" component={Style} />
          <FastField name="description" placeholder="description" component={Description} />
        </Grid>
      ) : (
        <Grid container direction="column" spacing={2}>
          <FastField name="text" placeholder="text" component={Text} />
          <FastField name="style" placeholder="style" component={Style} />
          <FastField name="size" placeholder="size" component={Size} />
          <FastField name="description" placeholder="description" component={Description} />
        </Grid>
      )}
    </CommonModal>
  );
};
export default create(AdvancedButtonWidgetModal);
