import BatteryAlertIcon from '@mui/icons-material/BatteryAlert';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import BatteryUnknownIcon from '@mui/icons-material/BatteryUnknown';
import PowerIcon from '@mui/icons-material/Power';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';

import { EBatteryStatuses } from '../../constants';
import st from './style.module.css';

const batteryIcons = {
  [EBatteryStatuses.FULL]: <BatteryFullIcon fontSize="inherit" />,
  [EBatteryStatuses.LOW]: <BatteryAlertIcon fontSize="inherit" />,
  [EBatteryStatuses.DC]: <PowerIcon fontSize="inherit" />,
  [EBatteryStatuses.UNKNOWN]: <BatteryUnknownIcon fontSize="inherit" />,
};

interface IProps {
  statusesBatteryType?: ObjectProperty;
  statusesBatteryLevel?: ObjectProperty;
}

const BatteryStatus = ({ statusesBatteryType, statusesBatteryLevel }: IProps) => {
  const theme = useTheme();
  const batteryType = statusesBatteryType.value || statusesBatteryType;

  if (!batteryIcons[batteryType]) return null;

  return (
    <span
      className={st.statusContainer}
      style={{ color: batteryType === EBatteryStatuses.LOW ? theme.palette.red : theme.palette.gray1 }}
    >
      <span className={st.icon}>{batteryIcons[batteryType]}</span>
      <span className={st.statusLabel}>{batteryType !== EBatteryStatuses.DC && `${statusesBatteryLevel.value}%`}</span>
    </span>
  );
};

export default BatteryStatus;
