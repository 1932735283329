import { useMutation } from '@apollo/client';
import showMessages from '@hooks/apollo/showMessages';
import { UPDATE_PROPERTIES } from '@modules/reports/api/UpdateProperties';
import { PropertyByKeyInput } from '@src/__generated__/graphql';
import { getPropertiesMap } from '@utils/getPropertyByKey';
import { useMemo } from 'react';

import { IFormValues, validationSchema } from '../constants/formik';
import { IObjectStatisticProps } from '../index';

const useForm = ({ onResolve, object }: IObjectStatisticProps) => {
  const [updateProperties, { loading }] = useMutation(UPDATE_PROPERTIES, {
    ...showMessages,
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const objectPropertiesMap = useMemo(() => getPropertiesMap(object.objectProperties), [object.id]);

  const formik = {
    initialValues: {
      name: object?.name ?? '',
      enabled: object?.enabled ?? false,
      description: object?.description ?? '',
      schemaId: objectPropertiesMap.settingsSchemaid?.value,
      propertyId: objectPropertiesMap.settingsProperty?.value,
      functionId: objectPropertiesMap.settingsFunction?.value,
      linkedOnly: objectPropertiesMap.settingsLinkedOnly?.value,
    },
    validationSchema,
    onSubmit: async ({ enabled, description, name, schemaId, propertyId, functionId, linkedOnly }: IFormValues) => {
      {
        const changedProperties: PropertyByKeyInput[] = [
          {
            propertyKey: 'settingsSchemaid',
            value: schemaId,
          },
          {
            propertyKey: 'settingsProperty',
            value: propertyId,
          },
          {
            propertyKey: 'settingsFunction',
            value: functionId,
          },
          {
            propertyKey: 'settingsLinkedOnly',
            value: linkedOnly,
          },
        ];

        const payload = {
          objectId: object?.id,
          name,
          description,
          enabled,
          keyedProperties: changedProperties,
        };
        await updateProperties({
          variables: {
            input: {
              detailedObject: [payload],
            },
          },
        })
          .then(() => {
            onResolve();
          })
          .catch(() => {});
      }
    },
  };

  return { formik, isLoading: loading, objectPropertiesMap };
};

export default useForm;
