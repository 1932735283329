import { useApolloClient } from '@apollo/client';
import WidgetInitInfo from '@components/common/WidgetInitInfo';
import BellRingIcon from '@components/icons/bellRing';
import CloudClockOutline from '@components/icons/cloudClockOutline';
import CrosshairsIcon from '@components/icons/crosshairsIcon';
import WidgetEditControls from '@components/WidgetEditControls';
import { COLOR_SCHEMAS } from '@constants/constants';
import { GET_DATA_SUBSCRIPTION } from '@graphql/queries';
import { BatteryAlert, CloudOffOutlined } from '@mui/icons-material';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useColors from '@utils/useColors';
import useMedia from '@utils/useMedia';
import { BASE_HEIGHT } from '@utils/widgetSizes';
import { Fragment, useEffect, useState } from 'react';

import st from './style.module.css';

const fallback =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHRpdGxlPmZpbGUtaW1hZ2UtcmVtb3ZlLW91dGxpbmU8L3RpdGxlPjxwYXRoIGQ9Ik03IDE5TDEyIDE0TDEzLjg4IDE1Ljg4QzEzLjMzIDE2Ljc5IDEzIDE3Ljg2IDEzIDE5SDdNMTAgMTAuNUMxMCA5LjY3IDkuMzMgOSA4LjUgOVM3IDkuNjcgNyAxMC41IDcuNjcgMTIgOC41IDEyIDEwIDExLjMzIDEwIDEwLjVNMTMuMDkgMjBINlY0SDEzVjlIMThWMTMuMDlDMTguMzMgMTMuMDQgMTguNjYgMTMgMTkgMTNDMTkuMzQgMTMgMTkuNjcgMTMuMDQgMjAgMTMuMDlWOEwxNCAySDZDNC44OSAyIDQgMi45IDQgNFYyMEM0IDIxLjExIDQuODkgMjIgNiAyMkgxMy44MUMxMy40NiAyMS4zOSAxMy4yMSAyMC43MiAxMy4wOSAyME0yMS4xMiAxNS40NkwxOSAxNy41OUwxNi44OCAxNS40N0wxNS40NyAxNi44OEwxNy41OSAxOUwxNS40NyAyMS4xMkwxNi44OCAyMi41NEwxOSAyMC40MUwyMS4xMiAyMi41NEwyMi41NCAyMS4xMkwyMC40MSAxOUwyMi41NCAxNi44OEwyMS4xMiAxNS40NloiIC8+PC9zdmc+';

const ColorNames = {
  default: 'default',
  purple: 'purple',
  blue: 'blue',
  green: 'green',
  yellow: 'yellow',
  orange: 'orange',
  grey: 'grey',
  red: 'red',
};

const colorsMap = {
  [ColorNames.default]: '#333333',
  [ColorNames.purple]: '#AA00FF',
  [ColorNames.blue]: '#2780E3',
  [ColorNames.green]: '#2E7D32',
  [ColorNames.yellow]: '#FFD600',
  [ColorNames.orange]: '#FF7518',
  [ColorNames.grey]: '#616161',
  [ColorNames.red]: '#D50000',
};

const W_MonitorStatusTable = (props) => {
  const client = useApolloClient();
  const { getImageById } = useMedia();

  const getPropValue = (prop) => props.object.objectProperties.find((obj) => obj.key === prop)?.value;

  const columns = getPropValue('sourceColumns');
  const rows = getPropValue('sourceRows');

  const colors = [getPropValue('settingsStyle'), null];

  const { getColorBasedOnStyle } = useColors();
  const { fg: fgColor, bg: bgColor } = getColorBasedOnStyle(getPropValue('settingsStyle'));
  const [value, setValue] = useState(getPropValue('valueValue'));
  const [settingsShowIcons, setSettingsShowIcons] = useState(getPropValue('settingsShowIcons'));
  const showTitle = getPropValue('settingsShowTitle');

  useEffect(() => {
    const observer = client.subscribe({
      query: GET_DATA_SUBSCRIPTION,
      variables: { objId: props.object.id },
    });

    const subscription = observer.subscribe(({ data }) => {
      if (data.Objects.relatedNode?.key === 'valueValue') {
        setValue(data.Objects.relatedNode?.value);
      } else if (data.Objects.relatedNode?.key === 'settingsShowIcons') {
        setSettingsShowIcons(data.Objects.relatedNode?.value);
      }
    });

    return () => subscription.unsubscribe();
  }, [props.object.id]);

  const getColorOfRow = (index) => {
    const isOdd = () => index % 2;

    if (isOdd()) {
      return '';
    }

    const theme = colors[0];

    switch (theme) {
      case COLOR_SCHEMAS.DARK_ON_LIGHT:
        return '#F1F1F1';
      default:
        return 'rgba(255, 255, 255, 0.1)';
    }
  };

  const HeadCell = ({ item }) => {
    const computeValue = (item) => {
      switch (item.value) {
        case 'all':
          return 'All';
        case 'alarm_triggered':
          return <BellRingIcon />;
        case 'not_response':
          return <CloudOffOutlined />;
        case 'gps_fix':
          return <CrosshairsIcon />;
        case 'low_battery':
          return <BatteryAlert />;
      }
    };
    return (
      <TableCell className={st.tableCellHead} style={{ color: fgColor, textAlign: 'center' }}>
        {computeValue(item)}
      </TableCell>
    );
  };

  return (
    <div
      className={'force-scroll'}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        backgroundColor: bgColor,
        borderRadius: '2px',
      }}
    >
      {value.length > 0 && (
        <div style={{ width: '100%' }}>
          <Table size="small" className={st.table} aria-label="simple table" style={{ backgroundColor: 'transparent' }}>
            <TableHead
              style={{
                position: 'sticky',
                top: 0,
                zIndex: 1,
                backgroundColor: bgColor,
              }}
            >
              {showTitle && (
                <TableRow
                  style={{
                    height: `${BASE_HEIGHT}px`,
                  }}
                >
                  <TableCell
                    className={st.tableCellHead}
                    align="center"
                    variant="head"
                    colSpan={columns?.columns?.length + 1}
                  >
                    <Typography style={{ color: fgColor }} variant="h6">
                      {props.name}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow
                style={{
                  height: `${BASE_HEIGHT}px`,
                }}
              >
                <TableCell className={st.tableCellHead} style={{ color: fgColor, textAlign: 'left' }}></TableCell>
                {columns.columns.map((item, index) => (
                  <HeadCell item={item} key={`column-${index}`} />
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {value.map((item, index) => (
                <TableRow
                  key={index}
                  style={{
                    height: `${BASE_HEIGHT}px`,
                    backgroundColor: getColorOfRow(index),
                  }}
                >
                  <TableCell
                    className={st.tableCell}
                    style={{
                      color: fgColor,
                      position: 'relative',
                      textAlign: 'left',
                    }}
                  >
                    {settingsShowIcons && (
                      <Fragment>
                        <div
                          style={{
                            position: 'absolute',
                            width: '96px',
                            top: 0,
                            left: 0,
                            height: '100%',
                            backgroundColor: colorsMap[item.color],
                            marginRight: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <div
                            style={{
                              position: 'relative',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'contain',
                              WebkitMaskRepeat: `no-repeat`,
                              WebkitMaskSize: `contain`,
                              WebkitMaskImage: `url(${getImageById(item.icon) || fallback})`,
                              maskImage: `url(${getImageById(item.icon) || fallback})`,
                              maskRepeat: `no-repeat`,
                              maskSize: `contain`,
                              backgroundColor: fgColor,
                              height: `30px`,
                              width: `30px`,
                            }}
                          ></div>
                        </div>
                        <span style={{ paddingLeft: '96px' }}>{item.name}</span>
                      </Fragment>
                    )}
                    {!settingsShowIcons && item.name}
                  </TableCell>
                  {columns.columns.map((column, index) => (
                    <TableCell
                      key={`tableCell-${index}`}
                      className={st.tableCell}
                      style={{ color: fgColor, textAlign: 'center' }}
                    >
                      {item[column.value]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
      {(columns.columns.length === 0 || rows.rows.length === 0) && value.length === 0 && (
        <WidgetInitInfo fgColor={fgColor} infoText={'Make sure you added at least one column and one row'} />
      )}
      {columns.columns.length > 0 && rows.rows.length > 0 && value.length === 0 && (
        <WidgetInitInfo
          icon={<CloudClockOutline fontSize={'large'} />}
          fgColor={fgColor}
          infoText={'Aggregation of data can take some time'}
        />
      )}
      <WidgetEditControls class={'WidgetEditControls'} {...props} />
    </div>
  );
};

export default W_MonitorStatusTable;
