import CustomSelect from '@components/CustomSelect';
import useControl from '@modules/widgets/forms/CommandButton/EditControlModal/components/Control/hooks/useControl';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';

const defaultErrorText = {
  error: true,
  helperText: 'Failed to load ...',
};

const Control: FC<FieldProps<IFormValues>> = ({ form }) => {
  const { error, rpcsListFormated, action } = useControl(form);

  const isLogicError = !action?.objectId ? { helperText: 'First select an object', disabled: true, error: true } : {};

  return (
    <Grid item>
      <CustomSelect
        {...fieldRequiredProps(form, validationSchema, 'control')}
        {...(error ? defaultErrorText : null)}
        label="Control"
        list={rpcsListFormated}
        {...isLogicError}
      />
    </Grid>
  );
};

export default Control;
