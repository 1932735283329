const getParamsRpc = (rpcName: string, rpcsList = {} as any, paramsValues = {}) =>
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  rpcsList?.object?.schema?.schemaControls
    ?.filter((item) => !item?.isRpc && item?.rpc === rpcName)
    ?.map((item) => {
      return {
        value: paramsValues[item.argument] || '',
        title: item.description,
        argument: item?.argument,
        type: item?.typeSpec,
      };
    });

export default getParamsRpc;
