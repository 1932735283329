import { gql } from '@src/__generated__';

const OBJECT_HISTORY_LIST = gql(/* GraphQL */ `
  query fetchHistoryListConnection(
    $filter: ObjectPropertiesHistoryFilter
    $first: Int = 10
    $orderBy: [ObjectPropertiesHistoriesOrderBy!] = RECORDED_AT_DESC
    $after: Cursor
  ) {
    objectPropertiesHistoriesConnection(first: $first, after: $after, orderBy: $orderBy, filter: $filter) {
      edges {
        node {
          property
          propertyId
          id
          value
          recordedAt
          userByBy {
            id
            mName
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      totalCount
    }
  }
`);

export default OBJECT_HISTORY_LIST;
