import { gql } from '@src/__generated__';

export const GET_SCHEMA = gql(/* GraphQL */`
  query getSchema($id: UUID!) {
    schema(id: $id) {
      id
      name
      schemaProperties(sort: { sortBy: { field: GROUP_NAME } }) {
        id
        key
        groupName
        property
        description
        type {
          name
        }
        valueSet
        valueRange
      }
    }
  }
`);
