import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { IFormValues, validationSchema } from '../../constants/formik';
import SelectColor from '@components/forms/SelectColor';
import { LINE_COLOR_OPTIONS } from '@modules/widgets/forms/LineWidgetModal/constants/constants';

const Color: FC<FieldProps<IFormValues>> = ({ form }) => {
  return (
    <Grid item md={6} xs={12}>
      <SelectColor {...fieldRequiredProps(form, validationSchema, 'color')} label="Color" list={LINE_COLOR_OPTIONS} />
    </Grid>
  );
};

export default Color;
