import CommonModal from '@components/CommonModal';
import { TDefaultWidgetProps } from '@components/create-widget/types';
import { msg } from '@constants/messages';
import { Button } from '@mui/material';
import { useEffect } from 'react';
import { create, InstanceProps } from 'react-modal-promise';
import { dispatch as dispatchBus } from 'use-bus';
import DataChartColorForm from './components/Form';
import useForm from './hooks/useForm';

const DataChartColor = (props: TDefaultWidgetProps & InstanceProps<{}>) => {
  const { widget, group, name, id, ...modalProps } = props;
  const { onResolve, onReject, isOpen } = modalProps;
  const isEdit = widget?.id !== undefined;

  const { formik, isLoading } = useForm({ submit: onResolve, name, group, isEdit, widget });

  useEffect(() => {
    if (isEdit) {
      dispatchBus('@@board/SAVE_BOARD');
    }
  }, []);

  return (
    <>
      <CommonModal
        key="EditDataChartColor"
        modalOpen={isOpen}
        title={isEdit ? 'Edit widget' : 'Add widget'}
        isForm={true}
        formik={formik}
        handleClose={onReject}
        loading={isLoading}
        buttons={
          <>
            <Button data-test="close-data-chart-color" color="inherit" onClick={onReject}>
              {msg.addWidgetModal.buttonCancel}
            </Button>
            <Button disabled={isLoading} data-test="save-data-chart-color" type="submit">
              {isEdit ? 'Save' : 'Add'}
            </Button>
          </>
        }
      >
        <DataChartColorForm isEdit={isEdit} />
      </CommonModal>
    </>
  );
};

export default create(DataChartColor);
