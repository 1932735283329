import CommonModal from '@components/CommonModal';
import { msg } from '@constants/messages';
import { Button, CircularProgress } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { create } from 'react-modal-promise';

import SchemaSelectorField from './components/SchemaSelectorField';

const SelectSchema = (props) => {
  const [loading, setLoading] = useState(false);
  const [schemaId, setSchemaId] = useState(props.value || null);

  const handleInputChange = (e) => {
    let { value, checked } = e.target;

    if (checked) value = checked;

    setSchemaId(value);
  };
  const submit = () => props.onResolve();

  const reject = () => props.onReject();

  const handleSave = async () => {
    setLoading(true);
    props.handleSave(props.id, schemaId).finally(() => {
      setLoading(false);
      submit();
    });
  };

  return (
    <CommonModal
      key="SelectSchema"
      modalOpen={props.isOpen}
      title="Select schema"
      handleClose={reject}
      buttons={
        <>
          <Button color="inherit" onClick={reject}>
            {msg.default.cancel}
          </Button>
          <Button color="primary" disabled={loading} onClick={handleSave}>
            {loading ? <CircularProgress size={23} /> : msg.default.save}
          </Button>
        </>
      }
    >
      <Grid container direction="column" spacing={2}>
        <SchemaSelectorField value={schemaId} onChange={handleInputChange} name="schemaId" />
      </Grid>
    </CommonModal>
  );
};

export default create(SelectSchema);
