import Grid from '@mui/material/Grid';
import { FieldProps } from 'formik';
import { FC } from 'react';
import CustomInput from '../../../../../../components/CustomInput';
import fieldRequiredProps from '../../../../../../utils/formik/fieldRequiredProps';
import { IFormValues, validationSchema } from '../../constants/formik';

const Email: FC<FieldProps<IFormValues>> = ({ form }) => (
  <Grid item md={6} xs={12}>
    <CustomInput
      {...fieldRequiredProps(form, validationSchema, 'email')}
      autoComplete="email"
      label={'Email'}
      clearFieldIcon={true}
    />
  </Grid>
);

export default Email;
