import { useSelector } from 'react-redux';
import HistoryLayout from './HistoryLayout';

// TODO: leave HistoryLayout only and remove this #794
const HistoryProperties = ({ object }) => {
  const historyPropertyId = useSelector((state) => state.settings.historyPropertyId);

  return (
    <HistoryLayout
      id={historyPropertyId}
      object={object}
    />
  );
};

export default HistoryProperties;
