import CustomSelect from '@components/CustomSelect';
import Grid from '@mui/material/Grid';
import fieldRequiredProps from '@utils/formik/fieldRequiredProps';
import { FieldProps } from 'formik';
import { FC } from 'react';
import { CommonFieldProps, IFormValues, validationSchema } from '../../constants/formik';

const Function: FC<FieldProps<IFormValues> & CommonFieldProps> = ({ form, label, list }) => {
  return (
    <>
      <Grid item>
        <CustomSelect {...fieldRequiredProps(form, validationSchema, 'functionId')} label={label} list={list} />
      </Grid>
    </>
  );
};

export default Function;
