import { Button, Grid } from '@mui/material';
import { FC } from 'react';
import { msg } from '../../../../../constants/messages';
import useHandleCopy from '../../../../side-card/menu/handlers/useHandleCopy';
import st from '../ListItem/style.module.css';

export interface IButtons {
  text?: string;
  handleClose?: () => void;
}

const Buttons: FC<IButtons> = ({ text, handleClose }) => {
  const handleCopy = useHandleCopy();

  return (
    <Grid item>
      <Button
        color="inherit"
        onClick={() =>
          handleCopy({
            object: { name: '' },
            message: 'Value copied successfully',
            text,
          })
        }
        className={st.button}
      >
        {msg.default.copy}
      </Button>
      <Button color="primary" onClick={handleClose}>
        {msg.default.ok}
      </Button>
    </Grid>
  );
};

export default Buttons;
