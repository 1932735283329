import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SyncIcon from '@mui/icons-material/Sync';
import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { useTheme } from '@mui/system';
import { ObjectProperty } from '@src/__generated__/graphql';

import { EDataStatuses } from '../../constants';
import st from './style.module.css';

const dataIcons = {
  [EDataStatuses.SET]: null,
  [EDataStatuses.PENDING]: <SyncIcon fontSize="inherit" />,
  [EDataStatuses.PROCESSING]: <AccessTimeIcon fontSize="inherit" />,
  [EDataStatuses.ERROR]: <SyncProblemIcon fontSize="inherit" />,
};

interface IProps {
  statusesData?: ObjectProperty;
}

const DataStatus = ({ statusesData }: IProps) => {
  const theme = useTheme();
  const status = statusesData.value || statusesData;

  if (!dataIcons[status]) return null;

  return (
    <span
      className={st.icon}
      style={{ color: status === EDataStatuses.ERROR ? theme.palette.red : theme.palette.gray1 }}
    >
      {dataIcons[status]}
    </span>
  );
};

export default DataStatus;
